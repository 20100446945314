import {
    ActiveServiceInput,
    ActiveServiceOutput,
    AnyRistServerInputMetric,
    AnyRistServerOutputMetric,
    AnyRistServerPortMode,
    Appliance,
    ApplianceConnectionState,
    ApplianceStatus,
    ApplianceType,
    ArrayKeys,
    ChannelMetrics,
    CoaxPortMode,
    ComprimatoMetricType,
    ComprimatoPortMode,
    EntityReference,
    EntityType,
    GeneralEncoderSettings,
    Input,
    InputOperStatus,
    InputPort,
    InputStatus,
    IpInputPort,
    IpPortMode,
    MatroxMetricType,
    MatroxPortMode,
    MetricWindow,
    MptsDemuxMetrics,
    MptsMetricType,
    Option,
    Output,
    OutputOperStatus,
    OutputPort,
    OutputRedundancyMode,
    OutputStatus,
    Pid,
    PortMode,
    Restrictions,
    RistInputMetrics,
    RistInputMultipathState,
    RistMetricType,
    RistOutputMetrics,
    RistServerEgressMetric,
    RistSimpleProfileInputMetrics,
    RistSimpleProfileMetricType,
    RistSimpleProfileOutputMetrics,
    Role,
    RtmpInputMetrics,
    RtmpMetricType,
    RtmpOutputMetrics,
    RtpInputMetrics,
    RtpOutputMetrics,
    SrtInputMetrics,
    SrtMetricType,
    SrtOutputMetrics,
    StreamMeasurement,
    StreamMetrics,
    SupportedVideoCodec,
    UdpInputMetrics,
    UdpOutput1mMetrics,
    UdpOutputMetrics,
    UdpOutputStatusCode,
    VideonInputPort,
    VideonPortMode,
    ZixiInputMetrics,
    ZixiMetricType,
    ZixiOutputMetrics,
} from './types'
import { ErrorCode, InvalidParametersError } from '../../errors'
import { RsInput as ConfigInput, RsOutput as ConfigOutput, UdpOutput } from '../../messages'
import { DEFAULT_DELAY_MODE_TR_101, DEFAULT_DELAY_TR101 } from '../../constants'
import { TransportStream } from '../../tr101Types'
import { RtmpOutputState } from '../../rtmp'
import { Importance, UdpInputStatusCode } from '../../rist'

export const METRICS_FRESHNESS_LIMIT_SECONDS = 30
export const MIN_AUDIO_BITRATE_BPS = 1_000 * 8 // 8 Kbps
export const MIN_VIDEO_BITRATE_BPS = 100_000 // 0.1 Mbps

export function getMinimumAcceptableInputBitrateBps(tsInfo: TransportStream | undefined): number {
    const pids = tsInfo?.pids
    if (!pids) {
        // Not a TS-stream?
        return MIN_VIDEO_BITRATE_BPS
    }
    const { hasMpegTsAudio, hasMpegTsVideo } = getTransportStreamContentInfo(pids)
    if (hasMpegTsAudio && hasMpegTsVideo) {
        return MIN_VIDEO_BITRATE_BPS
    }
    if (hasMpegTsVideo) {
        return MIN_VIDEO_BITRATE_BPS
    }
    if (hasMpegTsAudio) {
        return MIN_AUDIO_BITRATE_BPS
    }
    // No audio or video found in the TS-stream - only private data? We have no idea what is acceptable. Return MIN_VIDEO_BITRATE_BPS for backwards compatibility.
    return MIN_VIDEO_BITRATE_BPS
}

export const outputStatusNoInput: OutputStatus = Object.freeze({
    state: OutputOperStatus.notConfigured,
    title: 'This output is not connected to an input',
})

export const isUdpOutput1m = (ristMetrics: StreamMetrics): ristMetrics is UdpOutput1mMetrics =>
    ristMetrics.type == 'udpOutput' && ristMetrics.window == MetricWindow.m1

export function outputStatus(state: OutputOperStatus, title: string): OutputStatus {
    return {
        state,
        title,
    }
}

export function inputStatus(state: InputOperStatus, title: string): InputStatus {
    return {
        state,
        title,
    }
}

export function getOutputOperState(o: Output | ActiveServiceOutput): OutputStatus {
    return o.health || { state: OutputOperStatus.metricsMissing, title: 'Health status missing' }
}

export function getOutputHealth(o: Output | ActiveServiceOutput, isTr101290Enabled: boolean = true): OutputStatus {
    if (!o.ports || !o.ports.length) {
        return outputStatus(OutputOperStatus.notConfigured, 'This output is not properly configured')
    }

    const output = o as Output
    const activeServiceOutput = o as ActiveServiceOutput

    if (!activeServiceOutput.activeServiceInput && !output.input) {
        return outputStatusNoInput
    }

    if (output.upstreamAppliances.length == 0 && output.appliances[0]!.type != ApplianceType.core) {
        return outputStatus(OutputOperStatus.notConfigured, 'There is not configured egress for this output')
    }

    if (
        !output.upstreamAppliances.length &&
        output.appliances[0]!.type != ApplianceType.core &&
        (!activeServiceOutput.activeServiceInput || !activeServiceOutput.activeServiceInput.ports)
    ) {
        return outputStatus(OutputOperStatus.notConfigured, 'The input for this output is not configured')
    }

    if (output.alarms) {
        const majorOrCriticalAlarms = output.alarms.filter(
            (alarm) => alarm.alarmSeverity === 'critical' || alarm.alarmSeverity === 'major'
        )
        if (majorOrCriticalAlarms.length > 0) {
            return outputStatus(
                OutputOperStatus.alarm,
                majorOrCriticalAlarms.map((alarm) => alarm.text || alarm.alarmCause).join(', ')
            )
        }
    }

    const playbackStatus = getOutputPlaybackStatus(output)
    if (playbackStatus.state != OutputOperStatus.allOk) {
        return playbackStatus
    }

    const sendBitrateStatus = getOutputSendbitrateStatus(output, isTr101290Enabled)
    if (sendBitrateStatus.state != OutputOperStatus.allOk) {
        return sendBitrateStatus
    }

    const packetLossStatus = getOutputPacketLossStatus(output)
    if (packetLossStatus.state != OutputOperStatus.allOk) {
        return packetLossStatus
    }

    const redundancyStatus = getOutputRedundancyStatus(output)
    if (redundancyStatus.state != OutputOperStatus.allOk) {
        return redundancyStatus
    }

    return outputStatus(OutputOperStatus.allOk, 'Everything is ok')
}

function getOutputRedundancyStatus(output: Output): OutputStatus {
    const expectedNumberOfRistInputs = output.ports[0].copies || 1
    const expectedNumberOfActiveRistInputs =
        output.redundancyMode == OutputRedundancyMode.failover ? 1 : expectedNumberOfRistInputs
    const isRedundantOutput = expectedNumberOfRistInputs > 1
    const isOutputOnSameApplianceAsInput =
        output.upstreamAppliances.length == 1 && output.upstreamAppliances[0].id == output.appliances[0]!.id
    if (isRedundantOutput && !isOutputOnSameApplianceAsInput) {
        const ristInputMetricsOnOutputAppliance =
            output.metrics?.ristMetrics
                .filter((m) => m.applianceId == output.appliances[0]!.id)
                .filter(isRistInputMetrics) || []
        if (ristInputMetricsOnOutputAppliance.length == 0) {
            return outputStatus(OutputOperStatus.metricsMissing, `No input metrics on output appliance`)
        }
        const minAcceptableBitrateBps = getMinimumAcceptableInputBitrateBps((output.tsInfo || [])[0])
        const acceptableReceiveBitrates = ristInputMetricsOnOutputAppliance.filter(
            (m) => m.receiveBitrate >= minAcceptableBitrateBps
        )
        if (acceptableReceiveBitrates.length == 0) {
            return outputStatus(OutputOperStatus.outputError, 'Output transport error, bitrate too low')
        }
        if (acceptableReceiveBitrates.length < expectedNumberOfActiveRistInputs) {
            if (ristInputMetricsOnOutputAppliance.length < expectedNumberOfRistInputs) {
                return outputStatus(
                    OutputOperStatus.reducedRedundancy,
                    `Output may have reduced redundancy: missing metrics`
                )
            }
            return outputStatus(OutputOperStatus.reducedRedundancy, `Output has reduced redundancy`)
        }
    }
    return outputStatus(OutputOperStatus.allOk, '')
}

const getOutputPlaybackStatus = (output: Output): OutputStatus => {
    const isEgressUdpOutput = (s: StreamMetrics): s is UdpOutputMetrics =>
        s.type == RistMetricType.udpOutput && s.window == MetricWindow.s10 && !!s.isEgress
    const ristMetrics = output.metrics?.ristMetrics || []
    const egressUdpOutputs = ristMetrics.filter(isEgressUdpOutput)
    const udpOutputsNotPlaying = egressUdpOutputs.filter((s) => s.status != UdpOutputStatusCode.playing)
    if (udpOutputsNotPlaying.length > 0) {
        const message = udpOutputsNotPlaying
            .map((s) => {
                if (!s.status) {
                    return `Stream ${s.streamId}: Status is missing`
                }
                switch (s.status) {
                    case UdpOutputStatusCode.noCommonTimeBase:
                        return `Stream ${s.streamId}: ${s.applianceName} is out of sync - enable NTP`
                    default:
                        return `Stream ${s.streamId} has status '${s.status}'`
                }
            })
            .join(', ')
        const areAllOutputsBroken = output.ports.length === 1 || udpOutputsNotPlaying.length === output.ports.length
        if (areAllOutputsBroken) {
            return outputStatus(OutputOperStatus.outputError, message)
        }
        return outputStatus(OutputOperStatus.reducedRedundancy, `Output has reduced redundancy: ${message}`)
    }
    return outputStatus(OutputOperStatus.allOk, '')
}

function isRistServerEgressMetricType(
    m: StreamMetrics
): m is (UdpOutputMetrics | RtpOutputMetrics | RistSimpleProfileOutputMetrics) & { isEgress: true } {
    return (
        m.window == MetricWindow.s10 &&
        (m.type == RistMetricType.udpOutput ||
            m.type == RistMetricType.rtpOutput ||
            m.type == RistSimpleProfileMetricType.ristSimpleOutput) &&
        !!m.isEgress
    )
}

const getOutputSendbitrateStatus = (output: Output, isTr101290Enabled: boolean): OutputStatus => {
    const outputAppliances = output.appliances
    const outputApplianceIds = output.appliances.map((a) => a.id)
    const ristMetrics = output.metrics?.ristMetrics || []
    const outputApplianceRistMetrics = ristMetrics.filter((m) => outputApplianceIds.includes(m.applianceId))
    const bitrateMetrics = outputApplianceRistMetrics.filter((m) => m.window == MetricWindow.s10)
    const egressMetrics: RistServerEgressMetric[] = []
    const tr101BitrateMetrics: UdpOutputMetrics[] = []
    for (const bitrateMetric of bitrateMetrics) {
        if (isRistServerEgressMetricType(bitrateMetric)) {
            egressMetrics.push(bitrateMetric)
        } else if (
            isTr101290Enabled &&
            isUdpOutputMetrics(bitrateMetric) &&
            bitrateMetric.streamId &&
            bitrateMetric.streamId % 2 == 1
        ) {
            // An output with an odd streamId can only be tr101
            tr101BitrateMetrics.push(bitrateMetric)
        }
    }

    // One stats-entry for each socket/interface and one extra for the tr101290-udpOutput spawned by edge-data.
    const expectedNumberOfEgressEntries = output.ports.length
    if (egressMetrics.length < expectedNumberOfEgressEntries) {
        return outputStatus(
            OutputOperStatus.metricsMissing,
            `Missing measurements: received ${egressMetrics.length}/${expectedNumberOfEgressEntries} entries`
        )
    }

    if (isTr101290Enabled && tr101BitrateMetrics.length == 0) {
        return outputStatus(
            OutputOperStatus.metricsMissing,
            `Missing measurements: no output tr101290 bitrate measurement`
        )
    }

    const minAcceptableBitrateBps = getMinimumAcceptableInputBitrateBps((output.tsInfo || [])[0])
    const failingEgressMetrics = egressMetrics.filter((s) => s.sendBitrate < minAcceptableBitrateBps)
    const failingTr101Metrics = tr101BitrateMetrics.filter((s) => s.sendBitrate < minAcceptableBitrateBps)

    // Low bitrate per ports (rist server metrics)
    if (failingEgressMetrics.length > 0) {
        const failingEgressStreamIds = failingEgressMetrics.map((s) => s.streamId)

        // Output error: bitrate too low on all ports
        if (failingEgressMetrics.length === output.ports.length) {
            return outputStatus(
                OutputOperStatus.outputError,
                `Output bitrate too low for stream IDs: [${failingEgressStreamIds.join(',')}]`
            )
        }

        // Reduced redundancy: bitrate too low on at least one of the ports
        return outputStatus(
            OutputOperStatus.reducedRedundancy,
            `Output has reduced redundancy: ${expectedNumberOfEgressEntries -
                failingEgressMetrics.length}/${expectedNumberOfEgressEntries} healthy ports. ` +
                `Stream IDs with too low bitrate: [${failingEgressStreamIds.join(',')}]`
        )
    }

    // Low bitrate per appliance (tr101 metrics)
    if (failingTr101Metrics.length > 0) {
        const failingTr101MetricsIds = failingTr101Metrics.map((s) => s.streamId)
        const failingAppliances = output.appliances.length - failingTr101Metrics.length

        // Output error: bitrate too low on all appliances
        if (failingTr101Metrics.length === output.appliances.length) {
            return outputStatus(
                OutputOperStatus.outputError,
                `Output bitrate too low for stream IDs: [${failingTr101MetricsIds.join(',')}]`
            )
        }

        // Reduced redundancy: bitrate too low on at least one of the appliances
        return outputStatus(
            OutputOperStatus.reducedRedundancy,
            `Output has reduced redundancy: ${failingAppliances}/${output.appliances.length}) healthy output appliances. ` +
                `Output bitrate too low for stream IDs: [${failingTr101MetricsIds.join(',')}]`
        )
    }

    if (output.ports[0]?.mode == IpPortMode.zixi && outputAppliances[0].type == ApplianceType.core) {
        const zixiOutputMetrics = output.metrics?.ristMetrics.filter(isZixiOutputMetric) ?? []
        if (zixiOutputMetrics.length < expectedNumberOfEgressEntries) {
            return outputStatus(
                OutputOperStatus.metricsMissing,
                `Missing measurements: received ${zixiOutputMetrics.length}/${expectedNumberOfEgressEntries} zixi output bitrate metrics`
            )
        }
        for (const zixiOutputMetric of zixiOutputMetrics) {
            if (zixiOutputMetric.connectionStatus != 'Connected') {
                return outputStatus(
                    OutputOperStatus.outputError,
                    `Zixi output connection status: ${zixiOutputMetric.connectionStatus}`
                )
            }
            if (zixiOutputMetric.error) {
                return outputStatus(OutputOperStatus.outputError, zixiOutputMetric.error)
            }
        }
        /**
         * Note 25/2/2021:
         *  Commented out for now (FEEDER_VERSION=13.1.40511) since the zixi feeder bitrate metrics appears to be unreliable (it falsely reports a very low value)
         *  if (zixiOutputMetric.bitrate < minAcceptableBitrateBps) {
         *      return outputStatus(OutputOperStatus.outputError, `Zixi output bitrate too low`)
         *  }
         */
    }

    const isUsingSrtLiveTransmitProcess =
        output.ports[0]?.mode == IpPortMode.srt &&
        [ApplianceType.edgeConnect, ApplianceType.core].includes(outputAppliances[0].type)
    if (isUsingSrtLiveTransmitProcess) {
        const srtOutputMetrics = output.metrics?.ristMetrics.filter(isSrtOutputMetric) ?? []
        if (srtOutputMetrics.length < expectedNumberOfEgressEntries) {
            return outputStatus(
                OutputOperStatus.metricsMissing,
                `Missing measurements: received ${srtOutputMetrics.length}/${expectedNumberOfEgressEntries} srt output bitrate metrics`
            )
        }
        for (const srtOutputMetric of srtOutputMetrics) {
            if (srtOutputMetric.bitrate < minAcceptableBitrateBps) {
                return outputStatus(OutputOperStatus.outputError, `Srt output bitrate too low`)
            }
        }
    }

    if (output.ports[0]?.mode == IpPortMode.rtmp) {
        const rtmpOutputMetrics = output.metrics?.ristMetrics.filter(isRtmpOutputMetric) ?? []
        if (rtmpOutputMetrics.length < expectedNumberOfEgressEntries) {
            return outputStatus(
                OutputOperStatus.metricsMissing,
                `Missing measurements: received ${rtmpOutputMetrics.length}/${expectedNumberOfEgressEntries} rtmp output bitrate metrics`
            )
        }
        for (const rtmpOutputMetric of rtmpOutputMetrics) {
            if (rtmpOutputMetric.state != RtmpOutputState.ok) {
                return outputStatus(OutputOperStatus.outputError, prettyFormatRtmpOutputState(rtmpOutputMetric.state))
            }
            if (rtmpOutputMetric.sendBitrateKbps * 1000 < minAcceptableBitrateBps) {
                return outputStatus(OutputOperStatus.outputError, `Rtmp output bitrate too low`)
            }
        }
    }

    return outputStatus(OutputOperStatus.allOk, '')
}

export function getMetricTypeForOutput(outputPortMode: OutputPort['mode']) {
    switch (outputPortMode) {
        case CoaxPortMode.asi:
            return RistMetricType.udpOutput
        case CoaxPortMode.sdi:
            return RistMetricType.udpOutput
        case IpPortMode.udp:
            return RistMetricType.udpOutput
        case IpPortMode.rtp:
            return RistMetricType.rtpOutput
        case IpPortMode.rist:
            return RistSimpleProfileMetricType.ristSimpleOutput
        case IpPortMode.srt:
            return SrtMetricType.srtOutput
        case IpPortMode.zixi:
            return ZixiMetricType.zixiOutput
        case IpPortMode.rtmp:
            return RtmpMetricType.rtmpOutput
        case MatroxPortMode.matroxSdi:
            return MatroxMetricType.matroxSdiOutput
        case ComprimatoPortMode.comprimatoNdi:
            return ComprimatoMetricType.comprimatoNdiOutput
    }
}

export function isRistInputMetrics(m: StreamMetrics): m is RistInputMetrics {
    return m.type == RistMetricType.ristInput
}

export function isRistOutputMetrics(m: StreamMetrics): m is RistOutputMetrics {
    return m.type == RistMetricType.ristOutput
}

export function isUdpInputMetrics(m: StreamMetrics): m is UdpInputMetrics {
    return m.type == RistMetricType.udpInput
}

export function isOutputMetricsWithPacketsLost(m: StreamMetrics): m is UdpOutputMetrics | RtpOutputMetrics {
    return isUdpOutputMetrics(m) || isRtpOutputMetrics(m)
}

export function isUdpOutputMetrics(m: StreamMetrics): m is UdpOutputMetrics {
    return m.type == RistMetricType.udpOutput
}

export function isRtpInputMetrics(m: StreamMetrics): m is RtpInputMetrics {
    return m.type == RistMetricType.rtpInput
}

export function isMptsMetrics(m: StreamMetrics): m is MptsDemuxMetrics {
    return m.type == MptsMetricType.mptsDemux
}

export function isRtpOutputMetrics(m: StreamMetrics): m is RtpOutputMetrics {
    return m.type == RistMetricType.rtpOutput
}

export function isRistSimpleProfileInputMetrics(m: StreamMetrics): m is RistSimpleProfileInputMetrics {
    return m.type == RistSimpleProfileMetricType.ristSimpleInput
}

export function isRistSimpleProfileOutputMetrics(m: StreamMetrics): m is RistSimpleProfileOutputMetrics {
    return m.type == RistSimpleProfileMetricType.ristSimpleOutput
}

export function isZixiInputMetrics(m: StreamMetrics): m is ZixiInputMetrics {
    return m.type == ZixiMetricType.zixiInput
}

export function isZixiOutputMetric(m: StreamMetrics): m is ZixiOutputMetrics {
    return m.type == ZixiMetricType.zixiOutput
}

export function isSrtInputMetric(m: StreamMetrics): m is SrtInputMetrics {
    return m.type == SrtMetricType.srtInput
}

export function isSrtOutputMetric(m: StreamMetrics): m is SrtOutputMetrics {
    return m.type == SrtMetricType.srtOutput
}

export function isRtmpInputMetric(m: StreamMetrics): m is RtmpInputMetrics {
    return m.type == RtmpMetricType.rtmpInput
}

export function isRtmpOutputMetric(m: StreamMetrics): m is RtmpOutputMetrics {
    return m.type == RtmpMetricType.rtmpOutput
}

const getOutputPacketLossStatus = (output: Output): OutputStatus => {
    const isMissingPacketLossMetric =
        [ApplianceType.edgeConnect, ApplianceType.comprimato, ApplianceType.core].includes(
            output.appliances[0]!.type
        ) &&
        ([IpPortMode.rist, IpPortMode.rtp, ComprimatoPortMode.comprimatoNdi] as Array<OutputPort['mode']>).includes(
            output.ports[0].mode
        )
    const numOutputStreams = output.ports.length
    const hasPacketLossMetric = !isMissingPacketLossMetric
    if (hasPacketLossMetric) {
        const ristMetrics = output.metrics && output.metrics.ristMetrics
        if (!ristMetrics || ristMetrics.length == 0) {
            return outputStatus(OutputOperStatus.metricsMissing, 'No metrics are available')
        }
        const udpOutputs1m = ristMetrics.filter(isUdpOutput1m)
        if (udpOutputs1m.length == 0) {
            return outputStatus(OutputOperStatus.metricsMissing, `Output packet loss measurement missing`)
        }
        const freshUdpOutputs1m = udpOutputs1m.filter((m) => m.sampledAt && ageSeconds(m.sampledAt) <= 120)
        if (freshUdpOutputs1m.length == 0) {
            const ageInSeconds = udpOutputs1m[0].sampledAt && ageSeconds(udpOutputs1m[0].sampledAt)
            return outputStatus(
                OutputOperStatus.metricsMissing,
                `Output packet loss measurement out of date (${ageInSeconds} seconds)`
            )
        }
        const streamIds = new Set<number>()
        const streamIdsWithPacketLoss = new Set<number>()
        for (const m of freshUdpOutputs1m) {
            if (m.streamId) {
                streamIds.add(m.streamId)
                if (m.packetsLost > 0) {
                    streamIdsWithPacketLoss.add(m.streamId)
                }
            }
        }
        const numMissing = numOutputStreams - streamIds.size
        if (numMissing > 0) {
            return outputStatus(
                OutputOperStatus.reducedRedundancy,
                `Output could have reduced redundancy: missing up-to-date metrics from ${numMissing} streams`
            )
        }

        if (streamIdsWithPacketLoss.size > 0) {
            if (streamIdsWithPacketLoss.size == numOutputStreams) {
                return outputStatus(OutputOperStatus.outputError, 'Output has unrecovered packets')
            } else {
                const streamsWithPacketLoss = [...streamIdsWithPacketLoss]
                const streamMessage =
                    streamsWithPacketLoss.length > 1
                        ? `Streams ${streamsWithPacketLoss.join(', ')} have unrecovered packets`
                        : `Stream ${streamsWithPacketLoss[0]} has unrecovered packets`
                return outputStatus(
                    OutputOperStatus.reducedRedundancy,
                    `Output has reduced redundancy: ${streamMessage}`
                )
            }
        }
    }

    return outputStatus(OutputOperStatus.allOk, '')
}

export function isRistServerChannelMetrics(ristMetrics: StreamMetrics): ristMetrics is ChannelMetrics {
    return ristMetrics.type === RistMetricType.channel
}

export function isRistServerIngressMetrics(ristMetrics: StreamMetrics): ristMetrics is AnyRistServerInputMetric {
    return (
        ristMetrics.type == RistMetricType.ristInput ||
        ristMetrics.type == RistMetricType.udpInput ||
        ristMetrics.type == RistMetricType.rtpInput ||
        ristMetrics.type == RistSimpleProfileMetricType.ristSimpleInput
    )
}

export function isRistServerEgressMetrics(ristMetrics: StreamMetrics): ristMetrics is AnyRistServerOutputMetric {
    return (
        ristMetrics.type == RistMetricType.ristOutput ||
        ristMetrics.type == RistMetricType.udpOutput ||
        ristMetrics.type == RistMetricType.rtpOutput ||
        ristMetrics.type == RistSimpleProfileMetricType.ristSimpleOutput
    )
}

export function isRistServerPortMode(mode?: PortMode): mode is AnyRistServerPortMode {
    return mode == IpPortMode.udp || mode == IpPortMode.rtp || mode == IpPortMode.rist
}

export const isEdgeApplianceType = (type: ApplianceType) => {
    return type != ApplianceType.core && type != ApplianceType.thumb
}

export function isMatroxPortMode(portMode: InputPort['mode']): portMode is MatroxPortMode {
    return portMode in MatroxPortMode
}

export function isIpPortMode(portMode: InputPort['mode']): portMode is IpPortMode {
    return portMode in IpPortMode
}

export function isIpPort(inputPort: InputPort): inputPort is IpInputPort {
    return isIpPortMode(inputPort.mode)
}

export function isVideonPortMode(portMode: InputPort['mode']): portMode is VideonPortMode {
    return portMode in VideonPortMode
}
// There are currently no Videon output ports
export function isVideonPort(port: InputPort | OutputPort): port is VideonInputPort {
    return isVideonPortMode(port.mode)
}

export function isComprimatoPortMode(portMode: InputPort['mode']): portMode is ComprimatoPortMode {
    return portMode in ComprimatoPortMode
}

export const getInputOperState = (input: Input | ActiveServiceInput): InputStatus => {
    return input.health || { state: InputOperStatus.metricsMissing, title: 'Health status missing' }
}

export const getInputHealth = (input: Input, now: Date = new Date()): InputStatus => {
    const isCoreInput = input.appliances && input.appliances[0] && input.appliances[0].type == ApplianceType.core
    if ((!input.ports || !input.ports.length) && !input.deriveFrom) {
        return inputStatus(InputOperStatus.notConfigured, 'Input is not configured')
    }

    if (input.alarms) {
        const majorOrCriticalAlarms = input.alarms.filter(
            (alarm) => alarm.alarmSeverity === 'critical' || alarm.alarmSeverity === 'major'
        )

        if (majorOrCriticalAlarms.length > 0) {
            return inputStatus(
                InputOperStatus.alarm,
                majorOrCriticalAlarms.map((alarm) => alarm.text || alarm.alarmCause).join(', ')
            )
        }
    }

    const ristMetrics = input.metrics && input.metrics.ristMetrics
    if (!ristMetrics) {
        return inputStatus(InputOperStatus.metricsMissing, 'Metrics are missing')
    }

    const isFreshMetrics = (metrics: StreamMeasurement<any>) =>
        metrics.sampledAt && ageSeconds(metrics.sampledAt, now) < METRICS_FRESHNESS_LIMIT_SECONDS
    const freshChannelMetrics = ristMetrics.filter(isRistServerChannelMetrics).filter(isFreshMetrics)[0]
    if (!freshChannelMetrics) {
        return inputStatus(InputOperStatus.metricsMissing, 'No fresh channel measurements')
    }
    if (freshChannelMetrics.resetCount > 0) {
        return inputStatus(
            InputOperStatus.inputError,
            `Channel has been reset within the last ${METRICS_FRESHNESS_LIMIT_SECONDS} seconds`
        )
    }

    const ristServerIngressMetrics = ristMetrics.filter(isRistServerIngressMetrics)
    const coreInputMetrics = ristServerIngressMetrics.filter((m) => m.applianceType == ApplianceType.core)
    if (coreInputMetrics.length == 0) {
        return inputStatus(InputOperStatus.metricsMissing, 'No input bitrate measurements')
    }
    const freshCoreInputMetrics = coreInputMetrics.filter(isFreshMetrics)
    if (freshCoreInputMetrics.length == 0) {
        return inputStatus(InputOperStatus.metricsMissing, `No fresh input bitrate measurements`)
    }

    const minAcceptableBitrateBps = getMinimumAcceptableInputBitrateBps((input.tsInfo || [])[0])
    const acceptableReceiveBitrates = freshCoreInputMetrics.filter(
        (m) =>
            m.receiveBitrate >= minAcceptableBitrateBps ||
            (m.type === RistMetricType.ristInput && m.multipathState === RistInputMultipathState.standby) ||
            (m.type === RistMetricType.udpInput &&
                m.packetsWhileInactive > 0 &&
                m.status === UdpInputStatusCode.standby)
    )
    if (acceptableReceiveBitrates.length == 0) {
        return inputStatus(InputOperStatus.transportError, 'Input transport error, bitrate too low')
    }

    const expectedNumberOfCoreInputs =
        (input.ports && isCoreInput ? input.ports.length : input.ports?.[0]?.copies || 1) || 1

    if (acceptableReceiveBitrates.length < expectedNumberOfCoreInputs) {
        if (freshCoreInputMetrics.length < expectedNumberOfCoreInputs) {
            return inputStatus(InputOperStatus.reducedRedundancy, `Input may have reduced redundancy: missing metrics`)
        }
        return inputStatus(
            InputOperStatus.reducedRedundancy,
            `Input has reduced redundancy (got ${acceptableReceiveBitrates.length} healthy input port(s) but expected ${expectedNumberOfCoreInputs})`
        )
    }

    const isTr101290Priority1Error = input.metrics?.tr101290Metrics.some((m) => {
        return m.applianceType !== ApplianceType.thumb && Object.values(m.prio1).some((el) => el > 0)
    })
    if (isTr101290Priority1Error) {
        return inputStatus(InputOperStatus.tr101290Priority1Error, 'Input TR 101 290 Priority 1 error')
    }

    return inputStatus(InputOperStatus.allOk, 'Everything is ok')
}

export function ageSeconds(d: Date | string, dateNow: Date = new Date()) {
    let date
    if (typeof d === 'string') {
        date = Date.parse(d)
    } else {
        date = d.valueOf()
    }

    return Math.floor((dateNow.valueOf() - date) / 1000)
}

export const getProductName = (type: Appliance['type']): string =>
    ({
        [ApplianceType.nimbraVAdocker]: 'Edge Connect+',
        [ApplianceType.nimbra410]: 'Nimbra 410',
        [ApplianceType.nimbra412]: 'Nimbra 412',
        [ApplianceType.nimbra414]: 'Nimbra 414',
        [ApplianceType.nimbraVA225]: 'Nimbra VA 225',
        [ApplianceType.nimbraVA220]: 'Nimbra VA 220',
        [ApplianceType.edgeConnect]: 'Edge Connect',
        [ApplianceType.core]: 'Core node',
        [ApplianceType.thumb]: 'Core thumb',
        [ApplianceType.videon]: 'Videon',
        [ApplianceType.matroxMonarchEdgeE4_8Bit]: 'Monarch EDGE E4 (8 bit)',
        [ApplianceType.matroxMonarchEdgeE4_10Bit]: 'Monarch EDGE E4 (10 bit)',
        [ApplianceType.matroxMonarchEdgeD4]: 'Monarch EDGE D4',
        [ApplianceType.comprimato]: 'Comprimato',
    }[type] || type)

export const getTransferredString = (gbValue?: number) => {
    if (gbValue === undefined) {
        return
    }

    let measure = 'GB'
    let value: number | string = gbValue
    if (Math.floor(gbValue) > 0) {
        value = gbValue.toFixed(2)
    } else {
        measure = 'MB'
        value = (gbValue * 1e3).toFixed(2)
        if (parseFloat(value) === 0) {
            value = `~${value}`
        }
    }
    return `${value} ${measure}`
}

export const validatePassword = (value: string): string | undefined => {
    const minPwdLength = 8
    if (value.length > 0) {
        if (value.length < minPwdLength) {
            return `Password must contain at least ${minPwdLength} characters`
        }
    }
    return undefined
}

function emailIsValid(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const REGEX_ALPHANUMERIC = /^[a-zA-Z0-9]*$/m

export const validateUsername = (value: string): string | undefined => {
    if (!emailIsValid(value)) {
        return `Must be a valid email address`
    }
    return undefined
}

export const validate = (fieldname: string, validationErrorInfo: string | undefined) => {
    if (validationErrorInfo) {
        throw new InvalidParametersError(ErrorCode.invalidParameters, [
            { name: fieldname, reason: validationErrorInfo },
        ])
    }
}

export const getEntityTypeName = (entityType: EntityType): string => {
    switch (entityType) {
        case EntityType.outputRecipientList:
            return 'output list'
        case EntityType.groupRecipientList:
            return 'group list'
        default:
            return entityType
    }
}

export function tr101290Enabled({
    isTr101290Enabled,
    input,
    isFirstInput,
}: {
    isTr101290Enabled: boolean
    input: ConfigInput
    isFirstInput: boolean
}): boolean {
    return (
        isTr101290Enabled &&
        (input.type === 'udp-input' ||
            input.type === 'rist-simple-input' ||
            input.type === 'push-rist-input' ||
            input.type === 'rtp-input') &&
        isFirstInput /* With multiple redundant rtpInputs we still only should spawn one AvTsInfo */
    )
}

export function inputToTr101290Output(input: ConfigInput, allocatedPort: number): UdpOutput {
    const output: UdpOutput = {
        channelId: input.channelId,
        // Note: "Tag" this UDP output with the same streamId as the input so we are able to identify it as a Tr101290-analysis-stream
        // later on (all other "real" UdpOutputs have unique stream ids).
        streamId: input.streamId,
        remoteIp: '127.0.0.1',
        localIp: '127.0.0.1',
        localPort: 0,
        type: 'udp-output',
        remotePort: allocatedPort,
        delayMs: DEFAULT_DELAY_TR101,
        delayMode: DEFAULT_DELAY_MODE_TR_101,
        parseAsTs: false,
        importance: Importance.nonvital,
    }
    return output
}

export function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined
}

export function notUndefinedOrNull<T>(x: T | undefined | null): x is T {
    return x !== undefined && x !== null
}

export function getFormattedTransportStreamContent(tsInfo: TransportStream | undefined): string {
    if (!tsInfo) {
        return 'N/A'
    }
    const pids = tsInfo.pids
    if (!pids) {
        return 'No pids'
    }
    const streamIsMpts = isMpts([tsInfo])
    if (streamIsMpts) {
        return 'MPTS'
    }

    const { hasMpegTsAudio, hasMpegTsVideo } = getTransportStreamContentInfo(pids)
    if (hasMpegTsVideo) {
        return videoFormatFromTransportStream(pids)
    } else if (hasMpegTsAudio) {
        return audioFormatFromTransportStream(pids)
    }
    return `No audio/video`
}

export function getInputPidsOnCoreNode(input?: Input) {
    if (!input) {
        return []
    }
    const tsInfo = input.tsInfo
    if (!tsInfo) {
        return []
    }
    const coreTsInfo = tsInfo.find((tsInfo) => tsInfo.applianceType == ApplianceType.core)
    return coreTsInfo?.pids || []
}

export function getTransportStreamContentInfo(
    pids: (Pid | undefined)[]
): {
    hasMpegTsAudio: boolean
    hasMpegTsVideo: boolean
} {
    return {
        hasMpegTsAudio: pids.some((p) => p?.streamInfo?.audio !== undefined),
        hasMpegTsVideo: pids.some((p) => p?.streamInfo?.video !== undefined),
    }
}

export function tsInfoServiceName(info?: TransportStream) {
    const firstService = info?.services?.[0]
    const noInfo = 'no info'
    if (!firstService) {
        return noInfo
    }
    return `${firstService.name || firstService.type?.description || noInfo}`
}

export function audioFormatFromTransportStream(tsPids: (Pid | undefined)[]): string {
    // TODO: Handle MPTS - we currently extract the first found audio entry
    const audioPid = tsPids.find((p) => p?.streamInfo?.audio)
    if (!audioPid) {
        return 'No audio'
    }
    const audio = audioPid.streamInfo!.audio!
    const sampleRate = audio.sampleRate ? `${audio.sampleRate / 1000}kHz ` : ''
    let codec = getFormattedElementaryStreamType(audioPid.streamInfo?.type?.value)
    if (!isElementaryStreamTypeName(codec)) {
        codec = audioPid.streamInfo?.type?.description || codec
    }
    return `${sampleRate}${codec}`
}

export function videoFormatFromTransportStream(tsPids: (Pid | undefined)[]): string {
    // TODO: Handle MPTS - we currently extract the first found video entry
    const videoPid = tsPids.find((p) => p?.streamInfo?.video)
    if (!videoPid) {
        return 'No video'
    }
    const video = videoPid.streamInfo!.video!

    const videoSize = video.videoSize ? video.videoSize.vertical : ''
    const interlaced = video.interlaced ? (video.interlaced == 'yes' ? 'i' : 'p') : '?'
    const resolution = `${videoSize}${interlaced}`
    const rawFrameRate = video.frameRate || 0
    const frameRateNum = video.interlaced == 'yes' ? rawFrameRate * 2 : rawFrameRate
    const frameRate = frameRateNum ? frameRateNum.toFixed(2) : ''
    const codec = getFormattedElementaryStreamType(videoPid.streamInfo?.type?.value)
    return `${resolution}${frameRate} ${codec}`
}

export const isOutput = (inputOrOutput: ConfigInput | ConfigOutput): inputOrOutput is ConfigOutput => {
    return (
        inputOrOutput.type === 'push-rist-output' ||
        inputOrOutput.type === 'rist-simple-output' ||
        inputOrOutput.type === 'srt-output' ||
        inputOrOutput.type === 'zixi-output' ||
        inputOrOutput.type === 'udp-output' ||
        inputOrOutput.type === 'rtp-output' ||
        inputOrOutput.type === 'rtmp-output'
    )
}

export function formatBitrate(bps?: number | null) {
    if (typeof bps != 'number') {
        return 'N/A'
    }
    const units = [
        ['kbps', 0],
        ['Mbps', 2],
        ['Gbps', 3],
        ['Tbps', 3],
    ] as const
    const absBps = Math.max(bps, 0)
    const log = Math.floor(Math.log10(absBps))
    const size = Math.max(Math.floor(log / 3) - 1, 0)
    const [unit, decimals] = units[size]
    const val = absBps / Math.pow(10, (size + 1) * 3)
    const formattedVal = val.toFixed(decimals)
    return `${formattedVal} ${unit}`
}

export function getApplianceStatus(
    appliance: Pick<Appliance, 'lastMessageAt'>,
    formatDistanceToNow: (date: Date) => string
): ApplianceStatus {
    const applianceOnlineThresholdSeconds = 15
    if (!appliance.lastMessageAt) {
        return {
            title: `Never connected`,
            state: ApplianceConnectionState.neverConnected,
        }
    }
    if (ageSeconds(appliance.lastMessageAt) > applianceOnlineThresholdSeconds) {
        return {
            title: `Last seen ${formatDistanceToNow(appliance.lastMessageAt)} ago`,
            state: ApplianceConnectionState.missing,
        }
    }
    return {
        title: `Last seen less than ${applianceOnlineThresholdSeconds} seconds ago`,
        state: ApplianceConnectionState.connected,
    }
}

export function formatGraphNodeId({ type, id }: EntityReference) {
    return `${type}::${id}`
}

export function splitGraphNodeId(nodeId: string): EntityReference {
    const [type, id] = nodeId.split('::')
    return { type, id } as EntityReference
}

export enum ElementaryStreamTypeName {
    h262 = 'h262',
    mpeg1 = 'mpeg-1',
    mpeg2 = 'mpeg-2',
    adtsAac = 'adts-aac',
    h263 = 'h263',
    h264 = 'h264',
    j2k = 'j2k',
    h265 = 'h265',
    ac3 = 'ac3',
    eac3 = 'eac3',
    unknown = '?',
}

export function isMpts(tsInfo?: Input['tsInfo']) {
    return tsInfo?.some((tsInfo) => (tsInfo.services?.length || 1) > 1)
}

export function getVideoCodec(tsInfo?: Input['tsInfo']): ElementaryStreamTypeName {
    if (!tsInfo) {
        return ElementaryStreamTypeName.unknown
    }
    const videoPid = tsInfo[0]?.pids?.find((p) => p?.streamInfo?.video !== undefined)
    if (!videoPid) {
        return ElementaryStreamTypeName.unknown
    }
    const codec = getFormattedElementaryStreamType(videoPid.streamInfo?.type?.value)
    if (!isElementaryStreamTypeName(codec)) {
        return ElementaryStreamTypeName.unknown
    }
    return codec
}

export function getAudioCodec(tsInfo?: Input['tsInfo']): ElementaryStreamTypeName {
    if (!tsInfo) {
        return ElementaryStreamTypeName.unknown
    }
    const audioPid = tsInfo[0]?.pids?.find((p) => p?.streamInfo?.audio !== undefined)
    if (!audioPid) {
        return ElementaryStreamTypeName.unknown
    }
    const codec = getFormattedElementaryStreamType(audioPid.streamInfo?.type?.value)
    if (!isElementaryStreamTypeName(codec)) {
        return ElementaryStreamTypeName.unknown
    }
    return codec
}

function isElementaryStreamTypeName(streamType?: string): streamType is ElementaryStreamTypeName {
    return !!(streamType && Object.values(ElementaryStreamTypeName).includes(streamType as ElementaryStreamTypeName))
}

/**
 * https://en.wikipedia.org/wiki/Program-specific_information#Elementary_stream_types
 */
function getFormattedElementaryStreamType(elementaryStreamType: number | undefined) {
    switch (elementaryStreamType) {
        case 1:
            return ElementaryStreamTypeName.mpeg1 // Video
        case 2:
            return ElementaryStreamTypeName.h262
        case 3:
            return ElementaryStreamTypeName.mpeg1 // Audio
        case 4:
            return ElementaryStreamTypeName.mpeg2 // Audio
        case 15:
            return ElementaryStreamTypeName.adtsAac // Audio
        case 16:
            return ElementaryStreamTypeName.h263
        case 27:
            return ElementaryStreamTypeName.h264
        case 33:
            return ElementaryStreamTypeName.j2k
        case 36:
            return ElementaryStreamTypeName.h265
        case 129:
            return ElementaryStreamTypeName.ac3 // Audio
        case 132:
            return ElementaryStreamTypeName.eac3 // Audio
        case 135:
            return ElementaryStreamTypeName.eac3 // Audio
        default:
            return elementaryStreamType ? `(${elementaryStreamType})` : '?'
    }
}

// return start of month in UTC
export const startOfMonthUTC = (value: Date): Date => {
    const month = value.getMonth() + 1
    const monthStr = month < 10 ? `0${month}` : `${month}`
    return new Date(`${value.getFullYear()}-${monthStr}-01T00:00:00.000Z`)
}

export function endOfMonthUTC(d: Date): Date {
    const endDate = new Date(d)
    endDate.setUTCDate(1)
    endDate.setUTCMonth(endDate.getUTCMonth() + 1)
    endDate.setUTCDate(0)
    endDate.setUTCHours(23, 59, 59, 999)
    return endDate
}

export function addMonthUTC(d: Date) {
    const addedMonth = new Date(d)
    const date = addedMonth.getUTCDate()
    addedMonth.setUTCDate(1)
    const nextMonth = addedMonth.getUTCMonth() + 1
    addedMonth.setUTCMonth(nextMonth)
    addedMonth.setUTCDate(Math.min(date, endOfMonthUTC(addedMonth).getUTCDate()))
    return addedMonth
}

export const dateToYearMonthDayString = (d: Date): string => {
    return d.toISOString().substring(0, 10)
}

export const dateToYearMonthDayMaxNowString = (d: Date): string => {
    d = d.getTime() > Date.now() ? new Date() : d
    return dateToYearMonthDayString(d)
}

export const startOfDayUTC = (d: Date): Date => {
    return new Date(dateToYearMonthDayString(d))
}

export const endOfDayUTC = (d: Date): Date => {
    return new Date(`${dateToYearMonthDayString(d)}T23:59:59.999Z`)
}

export const endOfDayMaxNowUTC = (d: Date): Date => {
    const endOfDay = endOfDayUTC(d)
    return endOfDay.getTime() > Date.now() ? new Date() : endOfDay
}

function prettyFormatRtmpOutputState(state: RtmpOutputState) {
    switch (state) {
        case RtmpOutputState.ok:
            return 'ok'
        case RtmpOutputState.incompatibleAudioCodec:
            return 'Audio codec not supported by RTMP'
        case RtmpOutputState.incompatibleVideoCodec:
            return 'Video codec not supported by RTMP'
        default:
            return 'Unknown state'
    }
}

export const DATE_FORMAT_SHORT = 'yyyy-MM-dd'
export const DATE_FORMAT_LONG = 'yyyy-MM-dd HH:mm:ss'

export function omit<T, K extends keyof T>(t: T, ...omitKeys: K[]) {
    const o = { ...t }
    for (const k of omitKeys) {
        delete o[k]
    }
    return o as Omit<T, K>
}

export const roleLevels: readonly Role[] = Object.freeze([Role.basic, Role.admin, Role.super])

export function getRoleLevel(role: Role) {
    const index = roleLevels.findIndex((r) => r == role)
    if (index == -1) {
        throw new Error(`Invalid role: ${role}`)
    }
    return index
}

export function maxRole(...roles: Role[]) {
    if (roles.length == 0) {
        throw new Error(`maxRole expects at least one role as input`)
    }
    let maxRole: Role = roles[0]
    for (const role of roles) {
        if (getRoleLevel(role) > getRoleLevel(maxRole)) {
            maxRole = role
        }
    }
    return maxRole
}

export function applyCodecRestrictions<
    T extends SupportedVideoCodec,
    Prop extends Exclude<ArrayKeys<T>, 'restrictions'>
>(
    options: T[Prop],
    property: Prop,
    encoderSettings: GeneralEncoderSettings,
    restrictions?: Restrictions<T>
): NonNullable<T[Prop]> {
    // Yes, the typing here could be improved
    const opts = options || ([] as any[])
    if (!Array.isArray(opts)) {
        return (opts as unknown) as NonNullable<T[Prop]>
    }
    const propRestrictions = restrictions && restrictions[property]
    if (!propRestrictions) {
        return (opts as unknown) as NonNullable<T[Prop]>
    }
    const determinant = propRestrictions.determinedBy
    if (!(determinant in encoderSettings)) {
        return (opts as unknown) as NonNullable<T[Prop]>
    }
    const determinantValue = encoderSettings[determinant as keyof typeof encoderSettings]
    const mappedValues = propRestrictions.mapping[determinantValue as keyof typeof propRestrictions.mapping]
    if (!Array.isArray(mappedValues)) {
        return (opts as unknown) as NonNullable<T[Prop]>
    }
    return (opts.filter((o) => {
        return mappedValues.includes(isNameValueOption(o) ? o.value : o)
    }) as unknown) as NonNullable<T[Prop]>
}

export function isNameValueOption(v: any): v is Option {
    return typeof v == 'object' && typeof v['name'] == 'string' && 'value' in v
}
