import { ApplianceType, CoaxInputPort, CoaxPortMode, Input, MatroxPortMode } from 'common/api/v1/types'
import { Select } from '../../../common/Form'
import { FormikProps } from 'formik'
import { get } from 'lodash'
import { useEffect, useMemo } from 'react'
import { RichOption } from '../../../common/Form/Select'

interface CoaxPortFormProps {
  namePrefix: string
  applianceType: ApplianceType
  form: FormikProps<Input>
  modes?: { name: string; value: string }[]
}

const CoaxPortForm = ({ namePrefix, applianceType, modes, form }: CoaxPortFormProps) => {
  const modeKey = `${namePrefix}.mode`
  const logicalPort: CoaxInputPort = get(form.values, namePrefix)

  const modeOptions: RichOption[] = useMemo(() => {
    let options: RichOption[] | undefined = modes
    if (modes) {
      options = modes
    } else {
      if (
        applianceType === ApplianceType.matroxMonarchEdgeE4_8Bit ||
        applianceType === ApplianceType.matroxMonarchEdgeE4_10Bit
      ) {
        options = [{ name: 'sdi', value: MatroxPortMode.matroxSdi }]
      } else {
        options = Object.values(CoaxPortMode).map(mode => ({ name: mode, value: mode }))
      }
    }
    options = options.sort((m1, m2) => m1.name.localeCompare(m2.name))
    return options
  }, [applianceType, modes])

  useEffect(() => {
    const isValidMode = modeOptions.find(o => o.value === logicalPort.mode)
    if (!isValidMode && (logicalPort.mode as string) !== '') {
      form.setFieldValue(modeKey, '')
    }
  }, [modeOptions, logicalPort.mode, modeKey, form])

  return (
    <Select
      label="Mode"
      name={modeKey}
      required
      newLine
      options={modeOptions}
      validators={{
        oneOf: { validValues: new Set(modeOptions.map(({ value }) => value)) },
      }}
    />
  )
}

export default CoaxPortForm
