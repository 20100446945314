import {
  ApplianceSettings,
  ApplianceType,
  MatroxD4ApplianceSettings,
  MatroxE4ApplianceSettings,
} from 'common/api/v1/types'
import MatroxE4Settings from './MatroxE4Settings'
import MatroxD4Settings from './MatroxD4Settings'
import { Checkbox } from '../../../../common/Form'

interface Props {
  devMode: boolean
  type: ApplianceType
  settings: ApplianceSettings | undefined
}

const ApplianceSpecificSettings = ({ type, settings, devMode }: Props) => {
  return (
    <>
      {devMode && (
        <Checkbox
          label={'Use dynamic tunnel client source address (0.0.0.0)'}
          name={`settings.useDynamicTunnelClientSourceAddress`}
        />
      )}
      {type === ApplianceType.matroxMonarchEdgeD4 && (
        <MatroxD4Settings settings={settings as MatroxD4ApplianceSettings} />
      )}
      {type === ApplianceType.matroxMonarchEdgeE4_8Bit ||
        (type === ApplianceType.matroxMonarchEdgeE4_10Bit && (
          <MatroxE4Settings settings={settings as MatroxE4ApplianceSettings} />
        ))}
    </>
  )
}

export default ApplianceSpecificSettings
