import {
  ApplianceType,
  CoaxPortMode,
  ComprimatoPortMode,
  InputPort,
  MatroxPortMode,
  OutputPort,
  VideonPortMode,
} from 'common/api/v1/types'
import { isVaApplianceType } from 'common/utils'

export function getApplianceDefaultCoaxMode(
  applianceType: ApplianceType,
): InputPort['mode'] | OutputPort['mode'] | undefined {
  if (isVaApplianceType(applianceType)) {
    return CoaxPortMode.sdi
  }
  switch (applianceType) {
    case ApplianceType.comprimato:
      return ComprimatoPortMode.comprimatoSdi
    case ApplianceType.matroxMonarchEdgeE4_8Bit:
    case ApplianceType.matroxMonarchEdgeE4_10Bit:
    case ApplianceType.matroxMonarchEdgeD4:
      return MatroxPortMode.matroxSdi
    case ApplianceType.videon:
      return VideonPortMode.videonSdi
    case ApplianceType.core:
    case ApplianceType.thumb:
    case ApplianceType.edgeConnect:
      return undefined
    default: {
      // Typescript hack to get a exhaustiveness check for the switch statement
      const _: never = applianceType
      void _
    }
  }
}
