import {
    ApplianceFeatures,
    CoaxPortMode,
    ComprimatoPortMode,
    EncoderFeatures,
    EncoderSettings,
    InputInit,
    IpPortMode,
    isMatroxE4InputSettings,
    isGeneralEncoderSettings,
    MatroxAudioChannelPair,
    MatroxE4AACFormat,
    MatroxE4AACQualityLevel,
    MatroxE4EncoderAACEncoder,
    MatroxE4EncoderBitrateControlMode,
    MatroxE4EncoderIncludeOption,
    MatroxE4EncoderSettings,
    MatroxE4EncodingMode,
    MatroxE4FlipOption,
    MatroxE4InputSettings,
    MatroxE4PivotOption,
    MatroxE4PixelFormat,
    MatroxE4ProcessorAudioSourceSettings,
    MatroxE4ProcessorFrameRate,
    MatroxE4ProcessorSettings,
    MatroxE4ScalingOption,
    MatroxE4ProcessorVideoSourceSettings,
    MatroxE4VideoProfile,
    MatroxPortMode,
    PortMode,
    GeneralEncoderSettings,
    VideoCodec,
    VideonPortMode,
    InputPort,
    PhysicalPort,
} from './api/v1/types'
import { BadRequestError, ErrorCode } from './errors'
import { formatBitrate } from './api/v1/helpers'
import { isVirtualAggregatedPort } from './matrox'

export const portModesWithEncoderSettings: InputPort['mode'][] = [
    CoaxPortMode.sdi,
    MatroxPortMode.matroxSdi,
    VideonPortMode.videonAuto,
    VideonPortMode.videonSdi,
    VideonPortMode.videonHdmi,
    ComprimatoPortMode.comprimatoNdi,
    ComprimatoPortMode.comprimatoSdi,
]

export function makeDefaultVaEncoderSettings(): GeneralEncoderSettings {
    return {
        videoCodec: VideoCodec.h264,
        totalBitrate: 15,
        gopSizeFrames: 150,
        audioStreams: [],
    }
}

export function makeDefaultMatroxE4Settings(inputSourcePortIndex: number = 0): MatroxE4InputSettings {
    return {
        type: 'matroxEncoder',

        processorSettings: {
            audioSourceSettings: {
                portIndex: inputSourcePortIndex,
            },
            inputFailSafe: true,
            followVideoSourceInput: true,
            videoLayout: 'single',
            videoSourceSettings: [makeMatroxE4ProcessorVideoSourceSettings(inputSourcePortIndex)],
            overriddenCanvasSettings: {
                frameSize: { width: 1920, height: 1080 },
                frameRate: MatroxE4ProcessorFrameRate.sixty,
                backgroundColor: { red: 0, green: 0, blue: 0, alpha: 255 },
                pixelFormat: MatroxE4PixelFormat.YUV422_10bits,
            },
        },

        encoderSettings: {
            include: MatroxE4EncoderIncludeOption.audioAndVideo,
            videoSettings: {
                forceEncodingSize: false,
                forcedEncodingSettings: {
                    frameSize: {
                        width: 1920,
                        height: 1080,
                    },
                    scaling: MatroxE4ScalingOption.noScalingAtCenter,
                    pivot: MatroxE4PivotOption.none,
                    flip: MatroxE4FlipOption.none,
                },
                // encodingProfile must be 'High YUV 4:2:2' if processorSettings.followVideoSourceInput = true
                encodingProfile: MatroxE4VideoProfile.High422,
                targetBitrateMbps: 15,
                useLosslessEncoding: false,

                gop: {
                    iFrameInterval: 60,
                    pFrameInterval: 1,
                    temporalIframeInterval: 0,
                },

                rateControl: {
                    mode: MatroxE4EncodingMode.highQuality,
                    quantizationMin: 15,
                    quantizationMax: 42,
                    bitrateControl: MatroxE4EncoderBitrateControlMode.variable,
                    allowFrameSkipping: false,
                    maxBitrateMbps: 22.5,
                },

                forceCAVLCEntropyEncoding: false,
            },

            audioSettings: {
                aacEncoder: MatroxE4EncoderAACEncoder.lc,
                bitrateKbps: 32,
                aacQuality: MatroxE4AACQualityLevel.high,
                useTemporalNoiseShaping: true,
                aacFormat: MatroxE4AACFormat.adts,
            },
        },

        tsOutputSettings: {
            selectedAudioPairs: [MatroxAudioChannelPair.channel1And2],
        },
    }
}

export function makeMatroxE4ProcessorAudioSourceSettings(portIndex: number): MatroxE4ProcessorAudioSourceSettings {
    return {
        portIndex,
    }
}

export function makeMatroxE4ProcessorVideoSourceSettings(portIndex: number): MatroxE4ProcessorVideoSourceSettings {
    return {
        portIndex,
        frameCaptureRate: 1,
        scaling: MatroxE4ScalingOption.scaleToFitAllContent,
        pivot: MatroxE4PivotOption.none,
        flip: MatroxE4FlipOption.none,
        opacityPercentage: 100,
        brightness: 500,
        contrast: 500,
        hue: 0,
        saturation: 500,
    }
}

export function validateEncoderSettingsForAppliance(
    applianceFeatures: ApplianceFeatures,
    encoderSettings: InputInit['encoderSettings'],
    physicalPort: Pick<PhysicalPort, 'index'>
) {
    const encoderFeatures = applianceFeatures.encoder
    if (!encoderFeatures) {
        throw new BadRequestError(ErrorCode.badRequest, `Appliance does not have encoder support`)
    }
    if (!encoderSettings) {
        throw new BadRequestError(ErrorCode.badRequest, `Cannot configure an sdi input without encoder settings`)
    }

    if (isMatroxE4InputSettings(encoderSettings)) {
        const availableSources = physicalPort.index?.split(',') ?? []
        const requestedSources = [
            encoderSettings.processorSettings.audioSourceSettings.portIndex.toString(),
            ...encoderSettings.processorSettings.videoSourceSettings.map((src) => src.portIndex.toString()),
        ]
        const isRequestingUnavailableSource = requestedSources.some(
            (requested) => !availableSources.includes(requested)
        )
        if (isRequestingUnavailableSource) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `Attempting to ingress from sources: '${requestedSources.join(
                    ','
                )}' but available sources are: '${availableSources.join(',')}'`
            )
        }

        const isVirtualPort = isVirtualAggregatedPort(physicalPort)
        if (isVirtualPort && encoderSettings.processorSettings.videoLayout !== 'quad') {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `The received physical port only supports videoLayout 'quad'`
            )
        } else if (!isVirtualPort && encoderSettings.processorSettings.videoLayout !== 'single') {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `The received physical port only supports videoLayout 'single'`
            )
        }
        validateMatroxE4InputSettings(encoderSettings)
    } else {
        validateVaEncoderSettingsForAppliance(encoderFeatures, encoderSettings)
    }
}

export function getCompatibleMatroxVideoProfilesForPixelFormat(
    pixelFormat: MatroxE4PixelFormat
): MatroxE4VideoProfile[] {
    const compatibles = {
        [MatroxE4PixelFormat.YUV420_8bits]: [], // Compatible with all profiles
        [MatroxE4PixelFormat.YUV420_10bits]: [MatroxE4VideoProfile.High10Bit, MatroxE4VideoProfile.High422],
        [MatroxE4PixelFormat.YUV422_8bits]: [MatroxE4VideoProfile.High422],
        [MatroxE4PixelFormat.YUV422_10bits]: [MatroxE4VideoProfile.High422],
    }
    return compatibles[pixelFormat]
}

export function getAllowedBitratesForMatroxAACEncoder(
    aacEncoder: MatroxE4EncoderAACEncoder
): { minAllowedBitrateKbps: number; maxAllowedBitrateKbps: number } | undefined {
    const allowedBitratesPerEncoder = {
        [MatroxE4EncoderAACEncoder.lc]: { minAllowedBitrateKbps: 32, maxAllowedBitrateKbps: 576 },
        [MatroxE4EncoderAACEncoder.hev1]: { minAllowedBitrateKbps: 32, maxAllowedBitrateKbps: 288 },
        [MatroxE4EncoderAACEncoder.hev2]: { minAllowedBitrateKbps: 32, maxAllowedBitrateKbps: 144 },
    }
    return allowedBitratesPerEncoder[aacEncoder]
}

export const MATROXE4_VIDEO_CAPTURE_RATE_MIN = 1
export const MATROXE4_VIDEO_CAPTURE_RATE_MAX = 60
export const MATROXE4_VIDEO_OPACITY_PERCENTAGE_MIN = 0
export const MATROXE4_VIDEO_OPACITY_PERCENTAGE_MAX = 100
export const MATROXE4_VIDEO_BRIGHTNESS_MIN = 0
export const MATROXE4_VIDEO_BRIGHTNESS_MAX = 1000
export const MATROXE4_VIDEO_CONTRAST_MIN = 0
export const MATROXE4_VIDEO_CONTRAST_MAX = 1000
export const MATROXE4_VIDEO_HUE_MIN = 0
export const MATROXE4_VIDEO_HUE_MAX = 360
export const MATROXE4_VIDEO_SATURATION_MIN = 0
export const MATROXE4_VIDEO_SATURATION_MAX = 1000

export const MATROXE4_FRAMESIZE_MIN = 64
export const MATROXE4_FRAMESIZE_MAX = 4096
export const MATROXE4_FRAMEWIDTH_MULTIPLIER = 16
export const MATROXE4_FRAMEHEIGHT_MULTIPLIER = 2
export const MATROXE4_TARGET_BITRATE_MIN = 0.05
export const MATROXE4_MAXBITRATE_MIN = 0.1
export const MATROXE4_MAXBITRATE_MAX = 120
export const MATROXE4_QP_MIN = 0
export const MATROXE4_QP_MAX = 51
export const MATROXE4_IFRAME_INTERVAL_MIN = 1
export const MATROXE4_IFRAME_INTERVAL_MAX = 600
export const MATROXE4_IFRAME_INTERVAL_MULTIPLIER = 4
export const MATROXE4_PFRAME_INTERVAL_MIN = 1
export const MATROXE4_PFRAME_INTERVAL_MAX = 4
export const MATROXE4_MIN_ALLOWED_AUDIOSTREAMS = 1
export const MATROXE4_MAX_ALLOWED_AUDIOSTREAMS = 4

export function validateMatroxE4InputSettings(e4InputSettings: MatroxE4InputSettings) {
    function validateProcessorSettings(processorSettings: MatroxE4ProcessorSettings) {
        function validateVideoSourceSettings({
            frameCaptureRate,
            opacityPercentage,
            brightness,
            contrast,
            hue,
            saturation,
        }: MatroxE4ProcessorVideoSourceSettings) {
            if (frameCaptureRate < MATROXE4_VIDEO_CAPTURE_RATE_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `frameCaptureRate must be greater than or equal to ${MATROXE4_VIDEO_CAPTURE_RATE_MIN}`
                )
            }
            if (frameCaptureRate > MATROXE4_VIDEO_CAPTURE_RATE_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `frameCaptureRate must be less than or equal to ${MATROXE4_VIDEO_CAPTURE_RATE_MAX}`
                )
            }

            if (opacityPercentage < MATROXE4_VIDEO_OPACITY_PERCENTAGE_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `opacityPercentage must be greater than or equal to ${MATROXE4_VIDEO_OPACITY_PERCENTAGE_MIN}`
                )
            }
            if (opacityPercentage > MATROXE4_VIDEO_OPACITY_PERCENTAGE_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `opacityPercentage must be less than or equal to ${MATROXE4_VIDEO_OPACITY_PERCENTAGE_MAX}`
                )
            }

            if (brightness < MATROXE4_VIDEO_BRIGHTNESS_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `brightness must be greater than or equal to ${MATROXE4_VIDEO_BRIGHTNESS_MIN}`
                )
            }
            if (brightness > MATROXE4_VIDEO_BRIGHTNESS_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `brightness must be less than or equal to ${MATROXE4_VIDEO_BRIGHTNESS_MAX}`
                )
            }

            if (contrast < MATROXE4_VIDEO_CONTRAST_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `contrast must be greater than or equal to ${MATROXE4_VIDEO_CONTRAST_MIN}`
                )
            }
            if (contrast > MATROXE4_VIDEO_CONTRAST_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `contrast must be less than or equal to ${MATROXE4_VIDEO_CONTRAST_MAX}`
                )
            }

            if (hue < MATROXE4_VIDEO_HUE_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `hue must be greater than or equal to ${MATROXE4_VIDEO_HUE_MIN}`
                )
            }

            if (hue > MATROXE4_VIDEO_HUE_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `hue must be less than or equal to ${MATROXE4_VIDEO_HUE_MAX}`
                )
            }

            if (saturation < MATROXE4_VIDEO_SATURATION_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `saturation must be greater than or equal to ${MATROXE4_VIDEO_SATURATION_MIN}`
                )
            }
            if (saturation > MATROXE4_VIDEO_SATURATION_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `saturation must be less than or equal to ${MATROXE4_VIDEO_SATURATION_MAX}`
                )
            }
        }
        const { followVideoSourceInput, videoLayout, videoSourceSettings, overriddenCanvasSettings } = processorSettings
        const canFollowVideoSourceInput = videoLayout === 'single'
        const numberOfVideoSources = videoLayout === 'single' ? 1 : 4

        if (!canFollowVideoSourceInput && followVideoSourceInput) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `'followVideoSourceInput' cannot be true when ingesting multiple video sources`
            )
        }

        if (videoSourceSettings?.length !== numberOfVideoSources) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `one videoSourceSettings-entry per video source must be supplied`
            )
        }
        for (const setting of videoSourceSettings) {
            validateVideoSourceSettings(setting)
        }

        if (!followVideoSourceInput) {
            if (!overriddenCanvasSettings) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `overriddenCanvasSettings must be supplied when 'followVideoSourceInput' is false`
                )
            }
            const {
                frameSize: { width, height },
                backgroundColor: { red, green, blue, alpha },
            } = overriddenCanvasSettings

            const minSize = MATROXE4_FRAMESIZE_MIN
            const maxSize = MATROXE4_FRAMESIZE_MAX
            if (width < minSize || width > maxSize) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `Frame size width must be between ${minSize}-${maxSize}`
                )
            }
            if (width % MATROXE4_FRAMEWIDTH_MULTIPLIER !== 0) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `Frame size width must be a multiple of ${MATROXE4_FRAMEWIDTH_MULTIPLIER}`
                )
            }
            if (height < minSize || height > maxSize) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `Frame size height must be between ${minSize}-${maxSize}`
                )
            }
            if (height % MATROXE4_FRAMEHEIGHT_MULTIPLIER !== 0) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `Frame size height must be a multiple of ${MATROXE4_FRAMEHEIGHT_MULTIPLIER}`
                )
            }
            const minRgb = 0
            const maxRgb = 255
            if ([red, green, blue, alpha].some((rgba) => rgba < minRgb || rgba > maxRgb)) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `backgroundColor RGBA values must be between ${minRgb}-${maxRgb}`
                )
            }
        }
    }

    function validateEncoderSettings(
        encoderSettings: MatroxE4EncoderSettings,
        processorPixelFormat: MatroxE4PixelFormat | undefined
    ) {
        function validateVideoSettings(
            videoSettings: MatroxE4EncoderSettings['videoSettings'],
            processorPixelFormat: MatroxE4PixelFormat | undefined
        ) {
            const {
                forceEncodingSize,
                forcedEncodingSettings,
                encodingProfile,
                targetBitrateMbps,
                gop,
                rateControl,
            } = videoSettings

            // pixelFormat will be 'undefined' if processor::useVideoSourceSettings == true && encoder::forcePixelFormat == false.
            // If 'undefined' the pixel format being received on the SDI port will be used - we cannot validate this during creation since
            // we don't have access to that information.
            const hasSelectedPixelFormat = processorPixelFormat !== undefined
            if (hasSelectedPixelFormat) {
                const allowedProfilesForPixelFormat = getCompatibleMatroxVideoProfilesForPixelFormat(
                    processorPixelFormat
                )
                if (
                    allowedProfilesForPixelFormat.length > 0 &&
                    !allowedProfilesForPixelFormat.includes(encodingProfile)
                ) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `The selected encoding profile '${encodingProfile}' is not compatible with the selected pixel format '${processorPixelFormat}. Supported profiles are: ${allowedProfilesForPixelFormat.join(
                            ','
                        )}'`
                    )
                }
            } else {
                const requiredEncodingProfile = MatroxE4VideoProfile.High422
                if (encodingProfile !== requiredEncodingProfile) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `The encoding profile '${encodingProfile}' must be: ${requiredEncodingProfile} when pixel format is unspecified (i.e. when applying the video input source settings)`
                    )
                }
            }

            if (forceEncodingSize) {
                if (!forcedEncodingSettings) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `forcedEncodingSettings must be provided when 'forceEncodingSize' is true`
                    )
                }
                const {
                    frameSize: { width, height },
                } = forcedEncodingSettings
                if (width < MATROXE4_FRAMESIZE_MIN || width > MATROXE4_FRAMESIZE_MAX) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `Frame size width must be between ${MATROXE4_FRAMESIZE_MIN}-${MATROXE4_FRAMESIZE_MAX}`
                    )
                }
                if (width % MATROXE4_FRAMEWIDTH_MULTIPLIER !== 0) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `Frame size width must be a multiple of ${MATROXE4_FRAMEWIDTH_MULTIPLIER}`
                    )
                }
                if (height < MATROXE4_FRAMESIZE_MIN || height > MATROXE4_FRAMESIZE_MAX) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `Frame size height must be between ${MATROXE4_FRAMESIZE_MIN}-${MATROXE4_FRAMESIZE_MAX}`
                    )
                }
                if (height % MATROXE4_FRAMEHEIGHT_MULTIPLIER !== 0) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `Frame size height must be a multiple of ${MATROXE4_FRAMEHEIGHT_MULTIPLIER}`
                    )
                }
            }

            if (targetBitrateMbps < MATROXE4_TARGET_BITRATE_MIN || targetBitrateMbps > MATROXE4_MAXBITRATE_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `targetBitrateMbps must be between ${MATROXE4_TARGET_BITRATE_MIN}-${MATROXE4_MAXBITRATE_MAX}`
                )
            }

            const { iFrameInterval, pFrameInterval } = gop
            if (iFrameInterval < MATROXE4_IFRAME_INTERVAL_MIN || iFrameInterval > MATROXE4_IFRAME_INTERVAL_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `I-frame interval must be between ${MATROXE4_IFRAME_INTERVAL_MIN}-${MATROXE4_IFRAME_INTERVAL_MAX}`
                )
            }
            if (iFrameInterval % MATROXE4_IFRAME_INTERVAL_MULTIPLIER !== 0) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `I-frame interval must be a multiple of ${MATROXE4_IFRAME_INTERVAL_MULTIPLIER}`
                )
            }
            if (iFrameInterval < pFrameInterval) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `I-frame interval cannot be less than the P-frame interval`
                )
            }
            if (pFrameInterval < MATROXE4_PFRAME_INTERVAL_MIN || pFrameInterval > MATROXE4_PFRAME_INTERVAL_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `P-frame interval must be between ${MATROXE4_PFRAME_INTERVAL_MIN}-${MATROXE4_PFRAME_INTERVAL_MAX}`
                )
            }

            const { quantizationMin, quantizationMax, maxBitrateMbps } = rateControl
            if (quantizationMin < MATROXE4_QP_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `QP-min must be larger than or equal to ${MATROXE4_QP_MIN}`
                )
            }
            if (quantizationMax > MATROXE4_QP_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `QP-max must be smaller than or equal to ${MATROXE4_QP_MAX}`
                )
            }
            if (quantizationMin > quantizationMax) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `QP-min cannot be larger than QP-max (${quantizationMax}) and QP-max cannot be smaller than QP-min (${quantizationMin})`
                )
            }

            if (maxBitrateMbps < targetBitrateMbps) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `targetBitrateMbps cannot be greater than maxBitrateMbps`
                )
            }
            if (maxBitrateMbps < MATROXE4_MAXBITRATE_MIN || maxBitrateMbps > MATROXE4_MAXBITRATE_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `maxBitrateMbps must be between ${MATROXE4_MAXBITRATE_MIN}-${MATROXE4_MAXBITRATE_MAX}`
                )
            }
        }

        function validateAudioSettings(audioSettings: MatroxE4EncoderSettings['audioSettings']) {
            const { aacEncoder, bitrateKbps } = audioSettings

            const bitrateConstraints = getAllowedBitratesForMatroxAACEncoder(aacEncoder)
            if (bitrateConstraints) {
                const { minAllowedBitrateKbps, maxAllowedBitrateKbps } = bitrateConstraints
                if (bitrateKbps < minAllowedBitrateKbps || bitrateKbps > maxAllowedBitrateKbps) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `bitrateKbps must be between ${minAllowedBitrateKbps}-${maxAllowedBitrateKbps} for aac encoder ${aacEncoder}`
                    )
                }
            }
        }
        validateVideoSettings(encoderSettings.videoSettings, processorPixelFormat)
        validateAudioSettings(encoderSettings.audioSettings)
    }

    function validateTSOutputSettings(outputStreamSettings: MatroxE4InputSettings['tsOutputSettings']) {
        if (
            outputStreamSettings.selectedAudioPairs.length < MATROXE4_MIN_ALLOWED_AUDIOSTREAMS ||
            outputStreamSettings.selectedAudioPairs.length > MATROXE4_MAX_ALLOWED_AUDIOSTREAMS
        ) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `Must select ${MATROXE4_MIN_ALLOWED_AUDIOSTREAMS}-${MATROXE4_MAX_ALLOWED_AUDIOSTREAMS} number of audio pairs`
            )
        }
    }

    validateProcessorSettings(e4InputSettings.processorSettings)
    validateEncoderSettings(
        e4InputSettings.encoderSettings,
        e4InputSettings.processorSettings.followVideoSourceInput
            ? undefined
            : e4InputSettings.processorSettings.overriddenCanvasSettings.pixelFormat
    )
    validateTSOutputSettings(e4InputSettings.tsOutputSettings)
}

export function validateVaEncoderSettingsForAppliance(
    encoderFeatures: EncoderFeatures,
    encoderSettings: GeneralEncoderSettings
) {
    const videoCodec = encoderSettings.videoCodec

    const supportedCodec = encoderFeatures.video.codecs.find(
        (supportedVideoCodec) => videoCodec == supportedVideoCodec.name
    )
    if (!supportedCodec) {
        throw new BadRequestError(ErrorCode.badRequest, `Appliance does not support video codec ${videoCodec}`)
    }
    if (supportedCodec.bitrate.max < encoderSettings.totalBitrate) {
        throw new BadRequestError(
            ErrorCode.badRequest,
            `Appliance supports a maximum bitrate of ${formatBitrate(
                supportedCodec.bitrate.max
            )} when using video codec ${videoCodec}`
        )
    }
    if (supportedCodec.bitrate.min > encoderSettings.totalBitrate) {
        throw new BadRequestError(
            ErrorCode.badRequest,
            `Appliance supports a minimum bitrate of ${formatBitrate(
                supportedCodec.bitrate.min
            )} when using video codec ${videoCodec}`
        )
    }

    if (encoderSettings.videoFlags) {
        for (const [videoFlag, enabled] of Object.entries(encoderSettings.videoFlags)) {
            const applianceFeatureEncoderVideoFlag = encoderFeatures.video.flags?.find(
                (encoderVideoFlag) => encoderVideoFlag.value === videoFlag
            )
            if (enabled && applianceFeatureEncoderVideoFlag?.disabledForCodecs.includes(videoCodec)) {
                throw new BadRequestError(ErrorCode.badRequest, `${videoCodec} does not support enabling ${videoFlag}.`)
            }
        }
    }

    for (const audioStream of encoderSettings.audioStreams) {
        const supportedAudioCodec = encoderFeatures.audio.codecs.find(
            (audioCodec) => audioCodec.name == audioStream.codec
        )
        if (!supportedAudioCodec) {
            const indexOfAudioStream = encoderSettings.audioStreams.indexOf(audioStream)
            throw new BadRequestError(
                ErrorCode.badRequest,
                `Appliance does not support audio codec ${audioStream.codec} used by audio stream ${indexOfAudioStream +
                    1}`
            )
        }
        if (!supportedAudioCodec.bitrates.includes(audioStream.bitrate)) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `Appliance only supports audio bitrates ${supportedAudioCodec.bitrates.join(
                    ', '
                )} when using audio codec ${audioStream.codec}`
            )
        }
    }
}

export function isCorrectEncoderSettingsTypeForPortMode(encoderSettings: EncoderSettings | undefined, mode: PortMode) {
    if (!encoderSettings) {
        return false
    }
    switch (mode) {
        case MatroxPortMode.matroxSdi:
            return isMatroxE4InputSettings(encoderSettings)

        case CoaxPortMode.sdi: // fallthrough
        case CoaxPortMode.asi: // fallthrough
        case VideonPortMode.videonSdi: // fallthrough
        case VideonPortMode.videonHdmi: // fallthrough
        case VideonPortMode.videonAuto: // fallthrough
        case ComprimatoPortMode.comprimatoSdi:
        case ComprimatoPortMode.comprimatoNdi:
            return isGeneralEncoderSettings(encoderSettings)

        case IpPortMode.rist: // fallthrough
        case IpPortMode.udp: // fallthrough
        case IpPortMode.rtp: // fallthrough
        case IpPortMode.srt: // fallthrough
        case IpPortMode.zixi: // fallthrough
        case IpPortMode.rtmp: // fallthrough
        case IpPortMode.generator: // fallthrough
            return false
    }
}
