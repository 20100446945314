import { darken, Theme } from '@mui/material/styles'

export const styles = {
  success: (theme: Theme) => ({
    backgroundColor: darken(theme.palette.success.light, 0.5),
    color: theme.palette.common.white,
  }),
  warning: (theme: Theme) => ({
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.black,
  }),
  error: (theme: Theme) => ({
    backgroundColor: darken(theme.palette.error.light, 0.5),
    color: theme.palette.common.white,
  }),
  info: (theme: Theme) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[700]}`,
    color: theme.palette.common.white,
  }),
}
