import { useEffect } from 'react'
import { FormikProps } from 'formik'
import {
  EncoderSettings,
  MatroxE4InputSettings,
  MatroxPortMode,
  PortMode,
  VideoCodec,
  EncoderFeatures,
  RawVideo,
} from 'common/api/v1/types'
import MatroxE4SettingsComp from './MatroxE4EncoderSettings'
import VaEncoderSettingsComp from './VaEncoderSettings'
import {
  isCorrectEncoderSettingsTypeForPortMode,
  makeDefaultMatroxE4Settings,
  makeDefaultVaEncoderSettings,
  portModesWithEncoderSettings,
} from 'common/encoderSettingsUtil'
import { EnrichedInputPort } from '../../../../api/nm-types'

interface Props {
  namePrefix: string
  mode: PortMode
  selectedInterfaces: EnrichedInputPort[]
  encoderSettings?: EncoderSettings
  encoderFeatures?: EncoderFeatures

  setFieldValue: FormikProps<any>['setFieldValue']
  limitCodecs?: (VideoCodec | RawVideo)[]
}
const EncoderSettings = ({
  namePrefix,
  mode,
  selectedInterfaces,
  encoderSettings,
  encoderFeatures,
  setFieldValue,
  limitCodecs,
}: Props) => {
  useEffect(() => {
    if (!isCorrectEncoderSettingsTypeForPortMode(encoderSettings, mode)) {
      const newEncoderSettings =
        mode === MatroxPortMode.matroxSdi ? makeDefaultMatroxE4Settings() : makeDefaultVaEncoderSettings()
      setFieldValue('encoderSettings', newEncoderSettings)
    }
  }, [encoderSettings, mode])

  if (!portModesWithEncoderSettings.includes(mode)) {
    return null
  }
  if (!isCorrectEncoderSettingsTypeForPortMode(encoderSettings, mode)) {
    return null
  }

  return (
    <>
      {mode !== MatroxPortMode.matroxSdi && (
        <VaEncoderSettingsComp
          limitCodecs={limitCodecs}
          namePrefix={namePrefix}
          encoderFeatures={encoderFeatures}
          setFieldValue={setFieldValue}
        />
      )}
      {mode === MatroxPortMode.matroxSdi && (
        <MatroxE4SettingsComp
          namePrefix={namePrefix}
          selectedInterfaces={selectedInterfaces.map(enriched => enriched._port)}
          matroxSettings={encoderSettings as MatroxE4InputSettings}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  )
}

export default EncoderSettings
