import React from 'react'
import { useLocation } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import { defaultThemeDark } from './themes/defaultDark'
import { defaultThemeLight } from './themes/defaultLight'
import { getIsStreamManager } from './utils'

export const ThemeSwitcher: React.FunctionComponent = ({ children }) => {
  const { pathname } = useLocation()
  const currentTheme = getIsStreamManager(pathname) ? defaultThemeLight : defaultThemeDark
  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeSwitcher
