import { useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'
import MuiCheckbox from '@mui/material/Checkbox'

import { Address, ApplianceType, Input, InputAdminStatus, RtpInputPort } from 'common/api/v1/types'
import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import { isVaApplianceType } from '../../../../appliances/utils'
import { disallowNonNumericCharacters } from '../../../../../utils/events'
import { OccupiedPort } from 'common/ports'
import { CIDRToolTip } from '../../../../../texts'

export enum RtpFields {
  address = 'address',
  port = 'port',
  fec = 'fec',
  multicastAddress = 'multicastAddress',
  multicastSource = 'multicastSource',
  whitelistCidrBlock = 'whitelistCidrBlock',
  failoverPriority = 'failoverPriority',
}

export const rtpDefaults = createDefaultFiledValues(Object.keys(RtpFields), [RtpFields.fec])

interface RtpFormProps {
  addresses: Array<Address>
  form: FormikProps<Input>
  index: number
  applianceType: ApplianceType
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
  allocatedPort?: { addresses: Address[]; portNumber: number }
}
const RtpForm = ({
  form,
  addresses,
  namePrefix,
  applianceType,
  index,
  occupiedPorts,
  adminStatus,
  allocatedPort,
}: RtpFormProps) => {
  const isEdgeConnect = applianceType == ApplianceType.edgeConnect
  const isCoreNode = applianceType == ApplianceType.core

  const portsNamePrefix = namePrefix.substr(0, namePrefix.length - 2) // _interfaceSection-xxx.ports.0 --> _interfaceSection-xxx.ports
  const ports: RtpInputPort[] = get(form.values, portsNamePrefix)
  const rtpInputPort: RtpInputPort = get(form.values, namePrefix)

  const getMulticast = () => ({
    address: rtpInputPort.multicastAddress,
    source: rtpInputPort.multicastSource,
  })
  const { address, source } = getMulticast()
  const [isMulticast, setIsMulticast] = useState(!!(address || source))
  const [multicast, setMulticast] = useState(getMulticast())
  const addressSelector = `${namePrefix}.${RtpFields.address}`
  const disableLocalPortFields = !!allocatedPort

  const rtpAddresses = { ports, index }

  const showDash7Checkbox = index > 0
  const isDash7Redundant = typeof rtpInputPort.failoverPriority !== 'number'

  useEffect(() => {
    if (allocatedPort) {
      const portNumber = get(form.values, `${namePrefix}.${RtpFields.port}`)
      if (portNumber != allocatedPort.portNumber) {
        form.setFieldValue(`${namePrefix}.${RtpFields.port}`, allocatedPort.portNumber)
      }
      const allocatedAddress = allocatedPort.addresses[0].address
      if (get(form.values, addressSelector) != allocatedAddress) {
        form.setFieldValue(addressSelector, allocatedAddress)
      }
    }
  }, [allocatedPort, form])

  useEffect(() => {
    if (!isMulticast) {
      setMulticast(getMulticast())
      form.setFieldValue(`${namePrefix}.${RtpFields.multicastAddress}`, '')
      form.setFieldValue(`${namePrefix}.${RtpFields.multicastSource}`, '')
    } else {
      form.setFieldValue(`${namePrefix}.${RtpFields.multicastAddress}`, multicast.address)
      form.setFieldValue(`${namePrefix}.${RtpFields.multicastSource}`, multicast.source)
    }
  }, [isMulticast])
  return (
    <>
      {showDash7Checkbox && (
        <Checkbox
          label="Identical RTP streams (SMPTE 2022-7)"
          tooltip={`Requires that the two interfaces receive binary equal streams, e.g. from the same encoder.`}
          control={
            <MuiCheckbox
              id={`${namePrefix}.${RtpFields.failoverPriority}`}
              name={`${namePrefix}.${RtpFields.failoverPriority}`}
              checked={isDash7Redundant}
              onChange={e => {
                form.setFieldValue(
                  portsNamePrefix,
                  ports.map((port, portIndex) => ({
                    ...port,
                    [RtpFields.failoverPriority]: e.target.checked ? undefined : portIndex,
                  })),
                )
              }}
            />
          }
        />
      )}
      <Select
        name={addressSelector}
        label="Address"
        required
        disabled={disableLocalPortFields}
        options={makeAddressOptions(get(form.values, addressSelector), addresses)}
        newLine
        validators={{
          rtpAddresses,
          addressIn: { addresses },
        }}
      />
      <TextInput
        name={`${namePrefix}.${RtpFields.port}`}
        label="Port"
        required
        disabled={disableLocalPortFields}
        type="number"
        noNegative
        validators={{
          port: { disallowInternal: true },
          rtpAddresses,
          isPortAvailable: {
            occupiedPorts,
            isMulticast: !!address,
            isPortDisabled: adminStatus === InputAdminStatus.off,
          },
        }}
        onKeyPress={disallowNonNumericCharacters}
      />
      {!isEdgeConnect && !isCoreNode && (
        <Checkbox name={`${namePrefix}.${RtpFields.fec}`} label="Use Forward Error Correction?" />
      )}
      <Checkbox
        control={
          <MuiCheckbox
            checked={isMulticast}
            onChange={e => setIsMulticast(e.target.checked)}
            id={`${namePrefix}.multicast`}
            name={`${namePrefix}.multicast`}
          />
        }
        label="Is this port multicast?"
        newLine
      />
      {isMulticast && (
        <>
          <TextInput
            name={`${namePrefix}.${RtpFields.multicastAddress}`}
            label="Multicast address"
            validators={{
              rtpAddresses,
              multicastAddress: {},
            }}
            newLine
          />
          <TextInput
            name={`${namePrefix}.${RtpFields.multicastSource}`}
            label="Source specific multicast address"
            validators={{
              ip: {},
            }}
          />
        </>
      )}{' '}
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${RtpFields.whitelistCidrBlock}`}
          label="Whitelist CIDR block"
          tooltip={CIDRToolTip(applianceType === ApplianceType.core, 'Input')}
          required={applianceType === ApplianceType.core}
          validators={{
            ipv4CidrBlock: {},
          }}
        />
      )}
    </>
  )
}

export default RtpForm
