import { useCallback, useEffect, useRef, useState } from 'react'
import { ErrorMessage, Field, FieldAttributes, FormikProps } from 'formik'
import { FormControl } from '@mui/material'
import Autocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import { debounce, get, upperFirst } from 'lodash'

import { ListResult } from 'common/api/v1/types'
import GridItem, { GridItemProps } from '../GridItem'
import { PaginatedRequestParams } from '../../../../api/nm-types'

type MuiAC<T> = MuiAutocompleteProps<T, any, any, any>
type GenericAutocompleteProps<TEntity> = {
  name: FieldAttributes<MuiAC<TEntity>>['name']
  formik: FormikProps<any>
  api: (params: PaginatedRequestParams<any>) => Promise<ListResult<TEntity>>
  getOptionLabel: (option: TEntity) => string
  getOptionValue: (option: TEntity) => any
  optionComparator: MuiAC<TEntity>['isOptionEqualToValue']
  getOptionDisabled?: MuiAC<TEntity>['getOptionDisabled']
  label?: TextFieldProps['label']
  comment?: string
  required?: TextFieldProps['required']
  disabled?: boolean
  xs?: GridItemProps['xs']
  onClear?: () => void
  renderOption?: MuiAC<TEntity>['renderOption']
  tooltip?: GridItemProps['tooltip']
}

type SingleAutocompleteProps<TEntity> = GenericAutocompleteProps<TEntity> & {
  defaultOption?: TEntity | undefined
}

type MultipleAutocompleteProps<TEntity> = GenericAutocompleteProps<TEntity> & {
  multiple: true
  defaultOption?: TEntity[]
}

type AutocompleteProps<TEntity> = SingleAutocompleteProps<TEntity> | MultipleAutocompleteProps<TEntity>

const FormAutocomplete = <TEntity,>(props: AutocompleteProps<TEntity>) => {
  const {
    required = false,
    xs,
    label,
    name,
    defaultOption,
    formik,
    api,
    onClear,
    getOptionValue,
    optionComparator,
    getOptionDisabled,
    tooltip,
    comment,
    ...restProps
  } = props

  const multiple = 'multiple' in restProps && restProps.multiple

  const initialized = useRef(false)
  const isMounted = useRef(false)
  const [value, setValue] = useState(defaultOption || (multiple ? [] : null))
  const [inputValue, setInputValue] = useState(
    defaultOption && !multiple ? props.getOptionLabel(defaultOption as TEntity) : '',
  )
  const [options, setOptions] = useState<TEntity[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [apiSearchFilter, setApiSearchFilter] = useState<string | undefined>(undefined)

  const doClear = () => {
    setApiSearchFilter('')
    onClear?.()
  }

  const hasErrors = () => {
    const path = name.split('.')
    return get(formik.errors, path) && get(formik.touched, path) ? true : false
  }

  const fetch = async (filter: string | undefined) => {
    const { items } = await api({ pageNumber: '0', rowsPerPage: '500', filter })
    if (isMounted.current) {
      setOptions(items)
      setIsLoading(false)
    }
    initialized.current = true
  }

  const debouncedFetch = useCallback(debounce(fetch, 500), [])

  useEffect(() => {
    isMounted.current = true
    setOptions([])
    setIsLoading(true)

    if (initialized.current) {
      debouncedFetch(apiSearchFilter)
    } else {
      const value = defaultOption ? getOptionValue(defaultOption as TEntity) : null
      if (value !== null && formik.values[name] !== value) formik.setFieldValue(name, value)
      fetch(apiSearchFilter)
    }

    return () => {
      isMounted.current = false
    }
  }, [apiSearchFilter])

  return (
    <GridItem xs={xs} tooltip={tooltip}>
      <FormControl
        margin="normal"
        fullWidth
        id={`autocomplete-${name}`}
        data-is-loading={isLoading}
        data-num-options={options.length}
      >
        <Field
          name={name}
          value={value}
          inputValue={inputValue}
          component={Autocomplete}
          options={options}
          defaultValue={defaultOption}
          loading={isLoading}
          isOptionEqualToValue={optionComparator}
          getOptionDisabled={getOptionDisabled}
          validate={(value: string) => {
            if (required && (multiple ? !value.length : !value)) {
              return "Can't be blank"
            }
          }}
          onChange={(_: any, newOption: TEntity | TEntity[] | null) => {
            formik.setFieldValue(
              name,
              multiple ? (newOption as TEntity[]) : newOption ? getOptionValue(newOption as TEntity) : null,
            )
            setValue(newOption)
          }}
          onInputChange={(_: any, newInputValue: string, reason: string) => {
            if (reason === 'clear') {
              doClear()
            }
            formik.setFieldTouched(name)
            setInputValue(newInputValue)
          }}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              {...params}
              name={name}
              label={label || upperFirst(name)}
              variant="outlined"
              required={required}
              error={hasErrors()}
              onChange={e => {
                setApiSearchFilter(e.target.value)
              }}
              onBlur={() => {
                if (inputValue == '' || !value) {
                  doClear()
                }
              }}
              inputProps={{ ...params.inputProps, autoComplete: 'off' }}
            />
          )}
          {...restProps}
        />
        <ErrorMessage
          name={name}
          render={msg => (
            <FormHelperText error variant="filled">
              {msg}
            </FormHelperText>
          )}
        />
        {comment && <FormHelperText variant="filled">{comment}</FormHelperText>}
      </FormControl>
    </GridItem>
  )
}

export default FormAutocomplete
