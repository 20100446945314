import { get } from 'lodash'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { Appliance, Group } from 'common/api/v1/types'

const styles = {
  title: {
    margin: (theme: Theme) => theme.spacing(2, 0),
  },
}

interface RemoveApplianceProps {
  appliance: Appliance
}
export const RemoveDialog = ({ appliance }: RemoveApplianceProps) => {
  const owner = appliance.owner as Group

  return (
    <Typography variant="h2" color="textPrimary" sx={styles.title}>
      You are about to delete appliance &apos;{appliance.name}&apos;, owned by &apos;{get(owner, 'name')}&apos;&nbsp;
    </Typography>
  )
}
