import { FormikProps } from 'formik'
import { Button } from '@mui/material'

import {
  Address,
  ApplianceFeatures,
  ComprimatoPortMode,
  Input,
  InputAdminStatus,
  LimitedPhysicalPort,
  Output,
  OutputAdminStatus,
  PortType,
  VideonPortMode,
} from 'common/api/v1/types'
import IpPortForm from './IpPortForm'
import CoaxPortForm from './CoaxPortForm'
import { GridItem } from '../../../common/Form'
import { getPortsInUseBy } from '../../../../utils'
import VideonPortForm from './VideonPortForm'
import _ from 'lodash'
import { useEffect } from 'react'
import { getApplianceDefaultCoaxMode } from '../../../../utils/applianceDefaults'

interface IPortFormProps {
  inputId?: string
  form: FormikProps<Input>
  index: number
  inputPort: LimitedPhysicalPort & { _inputs?: Array<Input>; _outputs?: Array<Output> }
  allocatedPort?: { addresses: Address[]; portNumber: number }
  isModeDisabled: boolean
  onModeChanged?: () => void
  namePrefix: string
  onRemove?: (index: number) => void

  applianceFeatures?: ApplianceFeatures
}

export const PortForm = ({
  inputId,
  inputPort,
  allocatedPort,
  isModeDisabled,
  onModeChanged,
  namePrefix,
  form,
  index,
  onRemove,
  applianceFeatures,
}: IPortFormProps) => {
  const otherInputsOnInterface =
    inputPort?._inputs?.filter(input => input.adminStatus == InputAdminStatus.on && input.id != inputId) || []
  const portsInUseOnSelectedInterface = getPortsInUseBy({
    inputs: otherInputsOnInterface,
    outputs:
      '_outputs' in inputPort && inputPort._outputs
        ? inputPort._outputs.filter(output => output.adminStatus == OutputAdminStatus.on)
        : [],
  })

  const mode = _.get(form.values, `${namePrefix}.mode`)
  const physicalPortType = _.get(form.values, `${namePrefix}._port.portType`) as PortType | undefined
  const applianceType = inputPort.appliance.type
  useEffect(() => {
    if (physicalPortType == PortType.ndi) {
      if (mode != ComprimatoPortMode.comprimatoNdi) {
        form.setFieldValue(`${namePrefix}.mode`, ComprimatoPortMode.comprimatoNdi)
      }
    } else if (physicalPortType == PortType.coax) {
      const defaultMode = getApplianceDefaultCoaxMode(applianceType)
      if (defaultMode && !mode) {
        form.setFieldValue(`${namePrefix}.mode`, defaultMode)
      }
    }
  }, [physicalPortType, applianceType, mode])

  return (
    <>
      {inputPort.portType === PortType.ip && (
        <IpPortForm
          index={index}
          namePrefix={namePrefix}
          form={form}
          addresses={inputPort.addresses}
          applianceType={inputPort.appliance.type}
          allocatedPort={allocatedPort}
          occupiedPorts={portsInUseOnSelectedInterface}
          isModeDisabled={isModeDisabled}
          onModeChanged={onModeChanged}
        />
      )}

      {inputPort.portType === PortType.coax && (
        <CoaxPortForm
          namePrefix={namePrefix}
          form={form}
          applianceType={inputPort.appliance.type}
          modes={applianceFeatures?.coaxModes || []}
        />
      )}

      {inputPort.portType === PortType.videon && (
        <VideonPortForm
          namePrefix={namePrefix}
          limitToMode={
            otherInputsOnInterface[0] && (otherInputsOnInterface[0]?.ports?.[0]?.mode as VideonPortMode | undefined)
          }
        />
      )}

      {onRemove && (
        <GridItem newLine>
          <Button style={{ marginTop: 12 }} variant="outlined" color="primary" onClick={() => onRemove(index)}>
            Remove interface
          </Button>
        </GridItem>
      )}
    </>
  )
}
