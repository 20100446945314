import { FormikProps } from 'formik'
import { shallowEqual, useSelector } from 'react-redux'

import { Address, Input, InputAdminStatus } from 'common/api/v1/types'
import { defaultResolution, defaultScanMode, defaultFrameRate, defaultTimestampResolution } from 'common/api/defaults'

import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import _, { get } from 'lodash'
import { disallowNonNumericCharacters } from '../../../../../utils/events'
import { OccupiedPort } from 'common/ports'
import { RichOption } from 'src/components/common/Form/Select'
import { GlobalState } from '../../../../../store'

export enum GeneratorFields {
  address = 'address',
  port = 'port',
  bitrate = 'bitrate',
  audioOnly = 'audioOnly',
  resolution = 'resolution',
  scanMode = 'scanMode',
  frameRate = 'frameRate',
  timestampResolution = 'timestampResolution',
}

export const generatorDefaults = createDefaultFiledValues(Object.keys(GeneratorFields), ['audioOnly'], {
  resolution: defaultResolution,
  frameRate: defaultFrameRate,
  scanMode: defaultScanMode,
  timestampResolution: defaultTimestampResolution,
})

interface GeneratorFormProps {
  form: FormikProps<Input>
  addresses: Array<Address>
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
}

const allowedFrameRates: RichOption[] = [
  {
    name: '30',
    value: '30',
  },
  {
    name: '60',
    value: '60',
  },
  {
    name: '25',
    value: '25',
  },
  {
    name: '50',
    value: '50',
  },
  {
    name: '29.97',
    value: '29.97',
  },
  {
    name: '59.94',
    value: '59.94',
  },
]

const allowedResolutions: RichOption[] = [
  {
    name: '1280x720',
    value: '1280x720',
  },
  {
    name: '1920x1080',
    value: '1920x1080',
  },
]

const allowedScanModes: RichOption[] = [
  {
    name: 'progressive',
    value: 'progressive',
  },
  {
    name: 'interlaced - Top Frame First (TFF)',
    value: 'interlaced_tff',
  },
  {
    name: 'interlaced - Bottom Frame First (BFF)',
    value: 'interlaced_bff',
  },
]

const allowedTimestampResolutions: RichOption[] = [
  {
    name: 'seconds',
    value: 'seconds',
  },
  {
    name: 'milliseconds',
    value: 'milliseconds',
  },
]
const GeneratorForm = ({ form, addresses, namePrefix, occupiedPorts, adminStatus }: GeneratorFormProps) => {
  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const addressSelector = `${namePrefix}.${GeneratorFields.address}`
  return (
    <>
      <Select
        name={addressSelector}
        label="Address"
        required
        options={makeAddressOptions(get(form.values, addressSelector), addresses)}
        newLine
        validators={{
          addressIn: { addresses },
        }}
      />
      <TextInput
        name={`${namePrefix}.${GeneratorFields.port}`}
        label="Port"
        required
        type="number"
        noNegative
        validators={{
          port: { disallowInternal: true },
          isPortAvailable: {
            occupiedPorts,
            isMulticast: false,
            isPortDisabled: adminStatus === InputAdminStatus.off,
          },
        }}
        onKeyPress={disallowNonNumericCharacters}
      />

      <Select
        name={`${namePrefix}.${GeneratorFields.resolution}`}
        label="Resolution"
        options={allowedResolutions}
        newLine
        disabled={_.get(form.values, `${namePrefix}.${GeneratorFields.audioOnly}`)}
      />

      {devMode && (
        <Select
          name={`${namePrefix}.${GeneratorFields.scanMode}`}
          label="Scan mode"
          options={allowedScanModes}
          newLine
          disabled={_.get(form.values, `${namePrefix}.${GeneratorFields.audioOnly}`)}
        />
      )}

      <Select
        name={`${namePrefix}.${GeneratorFields.frameRate}`}
        label="Frame rate"
        options={allowedFrameRates}
        newLine
        disabled={_.get(form.values, `${namePrefix}.${GeneratorFields.audioOnly}`)}
      />
      <Select
        name={`${namePrefix}.${GeneratorFields.timestampResolution}`}
        label="Timestamp resolution"
        options={allowedTimestampResolutions}
        newLine
        disabled={_.get(form.values, `${namePrefix}.${GeneratorFields.audioOnly}`)}
      />
      <Checkbox name={`${namePrefix}.${GeneratorFields.audioOnly}`} label="Audio only" />
    </>
  )
}

export default GeneratorForm
