import { CoaxPortMode, Input, InputPort, IpPortMode, MatroxPortMode, Output, OutputPort, SrtMode } from './api/v1/types'

export interface OccupiedPort {
    portNumber: number
    protocol: 'tcp' | 'udp'
    input?: Input
    output?: Output
}

export function getLocalPortFromPortDbConfiguration(configuration: any): number {
    // Rendezvous 'remotePort' is also the local listen port
    if ('remotePort' in configuration && 'srtMode' in configuration && configuration.srtMode === SrtMode.rendezvous)
        return configuration.remotePort
    if ('localPort' in configuration && configuration.localPort) return configuration.localPort
    return 0
}

export function getPortsUsedByInputPort(port: InputPort): OccupiedPort[] {
    const portNumbers = getLocalPortNumbersUsedByInputPort(port)
    return portNumbers.map((portNumber) => ({ portNumber, protocol: port.mode === IpPortMode.rtmp ? 'tcp' : 'udp' }))
}

export function getLocalPortNumbersUsedByInputPort(port: InputPort): number[] {
    const portNumbers = new Set<number>()

    switch (port.mode) {
        case CoaxPortMode.asi: // fallthrough
        case CoaxPortMode.sdi: // fallthrough
        case MatroxPortMode.matroxSdi:
            break

        case IpPortMode.generator:
            portNumbers.add(port.port)
            break

        case IpPortMode.rist:
            /**
             * The local port specified for Rist simple inputs is used for data,
             * and local port+1 is used for control traffic.
             */
            portNumbers.add(port.port)
            portNumbers.add(port.port + 1)
            break

        case IpPortMode.udp: // fallthrough
        case IpPortMode.rtp: // fallthrough
            if (!port.multicastAddress) portNumbers.add(port.port)
            break

        case IpPortMode.rtmp:
            portNumbers.add(port.port)
            break

        case IpPortMode.srt: {
            switch (port.srtMode) {
                case SrtMode.rendezvous:
                    portNumbers.add(port.remotePort)
                    break
                case SrtMode.listener: // fallthrough
                case SrtMode.caller:
                    if (port.localPort) portNumbers.add(port.localPort)
                    break
            }
            break
        }

        case IpPortMode.zixi:
            break
    }
    return Array.from(portNumbers)
}

export function getPortsUsedByOutputPort(port: OutputPort): OccupiedPort[] {
    const portNumbers = getLocalPortNumbersUsedByOutputPort(port)
    return portNumbers.map((portNumber) => ({ portNumber, protocol: port.mode === IpPortMode.rtmp ? 'tcp' : 'udp' }))
}

export function getLocalPortNumbersUsedByOutputPort(port: OutputPort): number[] {
    const portNumbers = new Set<number>()

    switch (port.mode) {
        case CoaxPortMode.asi: // fallthrough
        case CoaxPortMode.sdi: // fallthrough
        case MatroxPortMode.matroxSdi: // fallthrough
        case IpPortMode.rtmp:
            break

        case IpPortMode.rist: // fallthrough
        case IpPortMode.rtp: // fallthrough
        case IpPortMode.udp: // fallthrough
            if (port.localPort) portNumbers.add(port.localPort)
            break

        case IpPortMode.srt: {
            switch (port.srtMode) {
                case SrtMode.rendezvous:
                    portNumbers.add(port.remotePort)
                    break
                case SrtMode.listener: // fallthrough
                case SrtMode.caller:
                    if (port.localPort) portNumbers.add(port.localPort)
                    break
            }
            break
        }

        case IpPortMode.zixi:
            break
    }
    return Array.from(portNumbers)
}
