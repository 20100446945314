import cn from 'classnames'
import { styled } from '@mui/material/styles'

import { getNode, graphNodeNames, layout } from './graph'
import { Position, RenderGraph } from './types'
import {
  AnyRistServerInputMetric,
  AnyRistServerOutputMetric,
  Appliance,
  ApplianceConnectionState,
  GraphNodeType,
  Input,
  InputAdminStatus,
  InputOperStatus,
  InputStatus,
  LimitedAppliance,
  NonRistServerOutputMetrics,
  Output,
  OutputAdminStatus,
  OutputOperStatus,
  OutputStatus,
  RistInputMultipathState,
  RistMetricType,
  RtmpMetricType,
  SrtMetricType,
  Tr101290Metrics,
  ZixiMetricType,
} from 'common/api/v1/types'
import {
  getMetricTypeForOutput,
  getMinimumAcceptableInputBitrateBps,
  isComprimatoPortMode,
  isMatroxPortMode,
  isRistServerEgressMetrics,
  isRistServerIngressMetrics,
  isRistServerPortMode,
} from 'common/api/v1/helpers'
import {
  HelpOutlineSvg,
  IconData,
  InputSvg,
  LinkOffSvg,
  NotInterestedSvg,
  OndemandVideoOutlinedSvg,
  ReducedRedundancySvg,
  RoundSvg,
  VideocamOffSvg,
} from './icons'
import { getConnectionMetrics } from '../Overview/Info'
import { UdpInputStatusCode } from 'common/rist'
import { isVaApplianceType } from 'common/utils'
import { isApplianceStandby } from '../Overview/utils'

const CIRCLE_RADIUS = 1.8

const defaultStyle = {
  baseColor: '#3366CC',
  highlightColor: 'rgb(103, 226, 191)',
  fillBaseColor: '#202631',
  fillHighlightColor: '#3D4451',
  textColor: '#ccc',
  errorColor: 'hsl(353, 57%, 61%)',
  errorHighlightColor: 'hsl(353, 57%, 75%)',
  warningColor: 'hsl(48, 75.3%, 63.5%)',
  warningHighlightColor: 'hsl(48, 75.3%, 83.5%)',
  disabledColor: 'rgba(255, 255, 255, 0.3)',
  disabledHighlightColor: 'rgba(255, 255, 255, 0.5)',
}

type ObjectStyle = 'base' | 'disabled' | 'error' | 'warning' | 'standby' | 'errorStandby'

function makeParentStyle(property: 'stroke' | 'fill') {
  return {
    '&:hover .svgElement': {
      [property]: defaultStyle.highlightColor,
      '&.error': {
        [property]: defaultStyle.errorHighlightColor,
      },
      '&.errorStandby': {
        [property]: defaultStyle.errorHighlightColor,
      },
      '&.disabled': {
        [property]: defaultStyle.disabledHighlightColor,
      },
      '&.warning': {
        [property]: defaultStyle.warningHighlightColor,
      },
    },
  }
}

function makeColorStyle(property: 'stroke' | 'fill') {
  return {
    [property]: defaultStyle.baseColor,
    '&.error': {
      [property]: defaultStyle.errorColor,
    },
    '&.warning': {
      [property]: defaultStyle.warningColor,
    },
    '&.disabled': {
      [property]: defaultStyle.disabledColor,
    },
  }
}

const HoverableSvgParent = styled('g')<{ fillType: 'stroke' | 'fill' }>(({ fillType }) => makeParentStyle(fillType))

const Circle = styled('circle')({
  fill: defaultStyle.fillBaseColor,
  stroke: defaultStyle.baseColor,
  '&.highlight': {
    fill: defaultStyle.fillHighlightColor,
    stroke: defaultStyle.highlightColor,
    '&.error': {
      stroke: defaultStyle.errorHighlightColor,
    },
    '&.warning': {
      stroke: defaultStyle.warningHighlightColor,
    },
  },
  '&.error': {
    stroke: defaultStyle.errorColor,
  },
  '&.warning': {
    stroke: defaultStyle.warningColor,
  },
  '&.disabled': {
    stroke: defaultStyle.disabledColor,
  },
})

const Icon = styled('path')({
  ...makeColorStyle('fill'),
})

const Line = styled('path')({
  strokeWidth: '0.35',
  stroke: defaultStyle.baseColor,
  '&.highlight': {
    stroke: defaultStyle.highlightColor,
  },
  '&.error': {
    stroke: defaultStyle.errorColor,
    '&.highlight': {
      stroke: defaultStyle.errorHighlightColor,
    },
  },
  '&.warning': {
    stroke: defaultStyle.warningColor,
    '&.highlight': {
      stroke: defaultStyle.warningHighlightColor,
    },
  },
  '&.standby': {
    strokeWidth: '0.6',
    strokeDasharray: '0.1 1.35',
    strokeLinecap: 'round',
  },
  '&.errorStandby': {
    stroke: defaultStyle.errorColor,
    '&.highlight': {
      stroke: defaultStyle.errorHighlightColor,
    },
    strokeWidth: '0.6',
    strokeDasharray: '0.1 1.35',
    strokeLinecap: 'round',
  },
  '&.disabled': {
    stroke: defaultStyle.disabledColor,
    '&.highlight': {
      stroke: defaultStyle.disabledHighlightColor,
    },
  },
})

export interface SelectedGraphItem<TData = any> {
  type: GraphNodeType
  id: string
  data?: TData
}

export interface ServiceOverviewGraphProps {
  selectedItem: SelectedGraphItem | undefined
  onSelect: <TData>(selected: SelectedGraphItem<TData> | undefined) => void
  graph: RenderGraph
  outputId?: string
  input: Input
  outputs: Output[]
  appliances: (Appliance | LimitedAppliance)[]
}

type Coords = [number, number]

function coordsFromPosition(p?: Position): Coords {
  if (!p) {
    return [0, 0]
  }
  return [p.x, p.y]
}

interface ConnectionData {
  fromType: GraphNodeType
  toType: GraphNodeType
  type: GraphNodeType
  logicalPortId?: string
  streamId?: number
  fromId?: string
  toId?: string
}

function getRenderObjects(
  graph: RenderGraph,
  input: Input,
  outputs: Output[],
  appliances: (Appliance | LimitedAppliance)[],
): { lines: Line<ConnectionData>[]; circles: Circle[]; icons: Icon[] } {
  const types = [
    [LineType.curveAntiClockwise, CurveBendSize.small],
    [LineType.curveAntiClockwise, CurveBendSize.big],
  ] as const
  const inputIndex: { [applianceId: string]: number } = {}
  const hasMultipleInputPorts =
    graph.edges.filter(edge => getNode(graph, edge.source).data.type === GraphNodeType.input).length > 1
  const lines = graph.edges.map(
    (edge): Line<ConnectionData> => {
      const { source, target } = edge
      const sourceNode = getNode(graph, source)
      const targetNode = getNode(graph, target)
      const sourcePosition = coordsFromPosition(sourceNode.position)
      const targetPosition = coordsFromPosition(targetNode.position)
      const fromType = sourceNode.data.type
      const toType = targetNode.data.type
      const type =
        fromType == GraphNodeType.input
          ? GraphNodeType.inputPort
          : toType == GraphNodeType.output
          ? GraphNodeType.outputPort
          : GraphNodeType.connection

      const id =
        type == GraphNodeType.connection
          ? `${sourceNode.data.id}>${targetNode.data.id}`
          : type == GraphNodeType.inputPort
          ? `${sourceNode.data.id}-${edge.logicalPortId}`
          : `${targetNode.data.id}-${edge.logicalPortId}`

      let output: Output | undefined = undefined
      if (type === GraphNodeType.outputPort) {
        output = outputs.find(o => o.id === targetNode.data.id)
      }

      const { lineClass } =
        type === GraphNodeType.inputPort
          ? getInputPortStatus(input, edge.streamId)
          : type === GraphNodeType.outputPort
          ? getOutputPortStatus(output, edge.streamId, edge.logicalPortId, input.adminStatus === InputAdminStatus.off)
          : getConnectionStatus(type, input, outputs, sourceNode.data.id, targetNode.data.id)

      if (type === GraphNodeType.inputPort && !inputIndex[targetNode.data.id]) {
        inputIndex[targetNode.data.id] = 0
      }

      let index: number | undefined = undefined
      if (hasMultipleInputPorts && type === GraphNodeType.inputPort) {
        index = inputIndex[targetNode.data.id]
        inputIndex[targetNode.data.id] = index + 1
      }

      const [lineType, curveBendSize] =
        index !== undefined ? types[index % types.length] : [LineType.straight, undefined]

      return {
        from: sourcePosition,
        to: targetPosition,
        curveType: lineType,
        curveBendSize: curveBendSize,
        id,
        data: {
          type,
          fromType,
          toType,
          fromId: sourceNode.data.id,
          toId: targetNode.data.id,
          logicalPortId: edge.logicalPortId,
          streamId: edge.streamId,
        },
        lineClass,
      }
    },
  )
  const nodeNames = graphNodeNames(graph)
  const { circles, icons }: { circles: Circle[]; icons: Icon[] } = nodeNames.reduce(
    ({ circles, icons }, n) => {
      const node = getNode(graph, n)
      const coords = coordsFromPosition(node.position)
      switch (node.data.type) {
        case GraphNodeType.input:
          {
            const { svg, style } = getInputIconStatus(input?.health, input?.adminStatus === InputAdminStatus.off)
            icons.push({
              svg,
              rect: {
                width: 5.2,
                height: 4.6,
                xOffset: -3.1,
                yOffset: -2.3,
              },
              xOffset: -12,
              yOffset: -12,
              coords: coords,
              scale: 0.24,
              title: node.name,
              type: node.data.type,
              id: node.data.id,
              style,
            })
          }
          break

        case GraphNodeType.output:
          {
            const output = outputs.find(o => o.id === node.data.id)
            const { svg, style } = getOutputIconStatus(
              output?.health,
              output?.adminStatus === OutputAdminStatus.off || input?.adminStatus === InputAdminStatus.off,
            )
            icons.push({
              svg,
              rect: {
                width: 5.4,
                height: 4.8,
                xOffset: -2.1,
                yOffset: -2.5,
              },
              xOffset: -11,
              yOffset: -11,
              coords: coords,
              scale: 0.24,
              title: node.name,
              type: node.data.type,
              id: node.data.id,
              style,
            })
          }
          break

        default: {
          const { style } = getApplianceIconStatus(
            input?.tr101290Enabled ?? true,
            isApplianceStandby(node.data.id, input, outputs),
            appliances.find(a => a.id === node.data.id),
            input.metrics?.tr101290Metrics.find(v => v.applianceId === node.data.id),
            input.adminStatus === InputAdminStatus.off,
          )
          circles.push({
            center: coords,
            radius: CIRCLE_RADIUS,
            title: node.name,
            type: node.data.type,
            id: node.data.id,
            style,
          })
        }
      }
      return { circles, icons }
    },
    { circles: [] as Circle[], icons: [] as Icon[] },
  )
  return { lines, circles, icons }
}

export function ServiceOverviewGraph({
  graph,
  selectedItem,
  onSelect,
  outputId,
  input,
  outputs,
  appliances,
}: ServiceOverviewGraphProps) {
  const { textColor, fillBaseColor } = defaultStyle
  void outputId
  const graphToRender = layout(graph)
  const { lines, circles, icons } = getRenderObjects(graphToRender, input, outputs, appliances)
  avoidOverlappingLines(lines.filter(line => line.curveType === LineType.straight))
  const xColSize = 30
  const yColSize = 10
  let maxX = 0
  let maxY = 0
  for (const {
    from: [x1, y1],
    to: [x2, y2],
  } of lines) {
    if (x1 > maxX) {
      maxX = x1
    }
    if (x2 > maxX) {
      maxX = x2
    }
    if (y1 > maxY) {
      maxY = y1
    }
    if (y2 > maxY) {
      maxY = y2
    }
  }
  maxX++
  maxY++
  const radius = 1
  return (
    <div style={{ overflow: 'auto' }} data-test-id="overview-graph">
      <svg
        style={{ overflow: 'visible', minWidth: '800px', maxWidth: '1200px', marginTop: 40 }}
        viewBox={`-12 -5 ${xColSize * Math.max(maxX, 5)} ${yColSize * maxY}`}
        width="100%"
      >
        {lines.reverse().map(line => {
          const { from, to, id, data, lineClass, curveType: lineType, curveBendSize: curveSize } = line
          const type = (data && data.type) || GraphNodeType.connection
          const scale = scalePoint(xColSize, yColSize)
          const [x1, y1] = scale(from)
          const [x2, y2] = scale(to)
          const highlighted = selectedItem?.type == type && selectedItem?.id == id
          const pathPos = `M${x1},${y1}`
          const pathString =
            lineType == LineType.straight
              ? `${pathPos} L${x2},${y2}`
              : `${pathPos} Q${controlPoint([x1, y1], [x2, y2], lineType, curveSize).join(',')} ${x2},${y2}`

          return (
            <HoverableSvgParent key={id} fillType="stroke">
              <path
                fill={'transparent'}
                d={pathString}
                strokeWidth={3}
                stroke={'transparent'}
                onClick={() => onSelect({ type, id, data })}
                style={{ cursor: 'pointer' }}
              />
              <Line
                d={pathString}
                fill={'transparent'}
                style={{ pointerEvents: 'none' }}
                className={cn('svgElement', lineClass, highlighted ? 'highlight' : '')}
              />
            </HoverableSvgParent>
          )
        })}
        {circles.map(circle => {
          const {
            center: [cx, cy],
            id,
            title,
            radius: r,
            style,
          } = circle
          const name = id

          let highlighted = false
          if (selectedItem) {
            const { id: selectedId, type: selectedType } = selectedItem
            highlighted = highlighted || (selectedType == circle.type && selectedId == id)
          }

          return (
            <HoverableSvgParent key={name} fillType="stroke">
              <Circle
                cx={cx * xColSize}
                cy={cy * yColSize}
                r={r}
                strokeWidth={0.4}
                onClick={() => onSelect({ type: circle.type, id })}
                className={cn('svgElement', style, highlighted ? ' highlight' : '')}
                style={{ cursor: 'pointer' }}
              >
                <title>{title}</title>
              </Circle>
              <text
                style={{ fontSize: '2.5px', cursor: 'pointer' }}
                fill={textColor}
                textAnchor="middle"
                x={cx * xColSize}
                y={cy * yColSize - radius * 2.8}
                onClick={() => {
                  onSelect({ type: circle.type, id })
                }}
              >
                {maxLength(title, 22)}
              </text>
            </HoverableSvgParent>
          )
        })}

        {icons.map(icon => {
          const {
            coords: [x, y],
            id,
            title,
            scale,
            rect,
            xOffset,
            yOffset,
            svg,
            style,
          } = icon
          const name = id
          let highlighted = false
          if (selectedItem) {
            const { id: selectedId, type: selectedType } = selectedItem
            highlighted = highlighted || (selectedType == icon.type && selectedId == id)
          }
          return (
            <HoverableSvgParent key={name} fillType={svg.fillType}>
              <rect
                width={rect.width}
                height={rect.height}
                fill={fillBaseColor}
                x={x * xColSize + rect.xOffset}
                y={y * yColSize + rect.yOffset}
                style={{ cursor: 'pointer' }}
                onClick={() => onSelect({ type: icon.type, id })}
              />
              <Icon
                transform={`translate(${x * xColSize} ${y *
                  yColSize}) scale(${scale}) translate(${xOffset} ${yOffset})`}
                d={svg.path}
                onClick={() => onSelect({ type: icon.type, id })}
                className={cn('svgElement', style, highlighted ? ' highlight' : '')}
                style={{ cursor: 'pointer' }}
              >
                <title>{title}</title>
              </Icon>
              <text
                style={{ fontSize: '2.5px', cursor: 'pointer' }}
                fill={textColor}
                textAnchor="middle"
                x={x * xColSize}
                y={y * yColSize - radius * 2.8}
                onClick={() => {
                  onSelect({ type: icon.type, id })
                }}
              >
                {maxLength(title, 18)}
              </text>
            </HoverableSvgParent>
          )
        })}
      </svg>
    </div>
  )
}

export interface Line<TData = never> {
  from: Coords
  to: Coords
  // title: string,
  curveType: LineType
  curveBendSize?: CurveBendSize
  id: string
  data?: TData
  lineClass: ObjectStyle
}

export enum LineType {
  straight = 'straight',
  curveClockwise = 'curveClockwise',
  curveAntiClockwise = 'curveAntiClockwise',
}

export enum CurveBendSize {
  small = 'small',
  big = 'big',
}

export interface Circle<TData = never> {
  center: Coords
  radius: number
  title: string
  id: string
  type: GraphNodeType
  data?: TData
  style: ObjectStyle
}

export interface Icon<TData = never> {
  coords: Coords
  svg: IconData
  rect: {
    width: number
    height: number
    xOffset: number
    yOffset: number
  }
  xOffset: number
  yOffset: number
  scale: number
  title: string
  id: string
  type: GraphNodeType
  data?: TData

  style: ObjectStyle
}

function maxLength(v: string, length: number) {
  if (v.length <= length) {
    return v
  }
  return v.substring(0, length) + '...'
}

const getConnectionStatus = (
  type: GraphNodeType,
  input: Input,
  outputs: Output[],
  from: string,
  to: string,
): {
  lineClass: ObjectStyle
} => {
  if (type !== GraphNodeType.connection) {
    return { lineClass: 'base' }
  }

  if (input.adminStatus === InputAdminStatus.off) {
    return { lineClass: 'disabled' }
  }

  const { sendMetrics, receiveMetrics } = getConnectionMetrics(input, outputs, from, to)
  const minimumAcceptableBitrate = getMinimumAcceptableInputBitrateBps((input.tsInfo || [])[0])
  const notOk =
    !sendMetrics ||
    !receiveMetrics ||
    sendMetrics.sendBitrate < minimumAcceptableBitrate ||
    receiveMetrics.bytesReceived < minimumAcceptableBitrate

  const standby = receiveMetrics?.multipathState == RistInputMultipathState.standby

  if (notOk) {
    if (standby) {
      return { lineClass: 'standby' }
    } else {
      return { lineClass: 'error' }
    }
  } else {
    return { lineClass: 'base' }
  }
}
const getInputPortStatus = (
  input: Input,
  streamId?: number,
): {
  lineClass: ObjectStyle
} => {
  if (input.adminStatus === InputAdminStatus.off) {
    return { lineClass: 'disabled' }
  }

  const receiveMetrics = input.metrics?.ristMetrics.find(
    metric => metric.streamId === streamId && isRistServerIngressMetrics(metric),
  ) as AnyRistServerInputMetric
  const minimumAcceptableBitrate = getMinimumAcceptableInputBitrateBps((input.tsInfo || [])[0])
  const notOk =
    !receiveMetrics ||
    (receiveMetrics?.type !== RistMetricType.udpInput && receiveMetrics?.receiveBitrate < minimumAcceptableBitrate) ||
    // Source failover RTP inputs are actually UDP inputs with packetFormat: RTP which thus gets handled below here
    (receiveMetrics?.type === RistMetricType.udpInput &&
      receiveMetrics?.receiveBitrate === 0 &&
      (receiveMetrics?.packetsWhileInactive === 0 ||
        (receiveMetrics?.status === UdpInputStatusCode.active && receiveMetrics?.packetsWhileInactive > 0)))

  const standby =
    receiveMetrics?.type === RistMetricType.udpInput && receiveMetrics?.status === UdpInputStatusCode.standby

  if (notOk) {
    if (standby) {
      return { lineClass: 'errorStandby' }
    } else {
      return { lineClass: 'error' }
    }
  } else {
    if (standby) {
      return { lineClass: 'standby' }
    } else {
      return { lineClass: 'base' }
    }
  }
}

const getOutputPortStatus = (
  output?: Output,
  streamId?: number,
  logicalPortId?: string,
  inputDisabled?: boolean,
): {
  lineClass: ObjectStyle
} => {
  if (!output) {
    return { lineClass: 'error' }
  }

  if (inputDisabled) {
    return { lineClass: 'disabled' }
  }

  const outputPort = output.ports.find(port => port.id === logicalPortId)

  const ristserverSendMetric = output.metrics?.ristMetrics.find(
    metric => metric.streamId === streamId && isRistServerEgressMetrics(metric),
  ) as AnyRistServerOutputMetric

  // Use minimum acceptable input bitrate as the required output bitrate
  const minimumAcceptableBitrate = getMinimumAcceptableInputBitrateBps((output.tsInfo || [])[0])

  // Check the ristserver send metrics (i.e. the outgoing udp/rtp/rist bitrates)
  if (!ristserverSendMetric || ristserverSendMetric?.sendBitrate < minimumAcceptableBitrate) {
    return { lineClass: 'error' }
  }

  const isVa = isVaApplianceType(output.appliances[0]?.type)
  const ristserverOutputPortMode = isRistServerPortMode(outputPort?.mode)
  const isMatroxOutput = outputPort && isMatroxPortMode(outputPort.mode)
  const isComprimatoOutput = outputPort && isComprimatoPortMode(outputPort.mode)
  if (ristserverOutputPortMode || isVa || isMatroxOutput || isComprimatoOutput) {
    return { lineClass: 'base' }
  }

  // Check the corresponding non-native output metrics (i.e. RTMP/Zixi/SRT etc)
  const nonRistserverSendMetric = output.metrics?.ristMetrics.find(
    metric => outputPort && metric.type === getMetricTypeForOutput(outputPort.mode) && metric.streamId === streamId,
  ) as NonRistServerOutputMetrics | undefined

  let nonRistserverOutputBitrate: number | undefined
  if (nonRistserverSendMetric) {
    switch (nonRistserverSendMetric.type) {
      case RtmpMetricType.rtmpOutput:
        nonRistserverOutputBitrate = nonRistserverSendMetric.sendBitrateKbps * 1000
        break
      case ZixiMetricType.zixiOutput:
      case SrtMetricType.srtOutput:
        nonRistserverOutputBitrate = nonRistserverSendMetric.bitrate
        break
    }
  }

  if (!nonRistserverOutputBitrate || nonRistserverOutputBitrate < minimumAcceptableBitrate) {
    return { lineClass: 'error' }
  }

  return { lineClass: 'base' }
}

const getApplianceIconStatus = (
  isTr101290Enabled: boolean,
  isApplianceStandby: boolean,
  appliance?: Appliance | LimitedAppliance,
  tr101290Metrics?: Tr101290Metrics,
  disabled?: boolean,
): { style: ObjectStyle } => {
  const isMissingTr101290Metrics =
    isTr101290Enabled && !isApplianceStandby && (!tr101290Metrics || !tr101290Metrics.prio1 || !tr101290Metrics.prio2)
  const hasTr101290Errors = isTr101290Enabled && Object.values({ ...tr101290Metrics?.prio1 }).some(value => value > 0)
  if (disabled) {
    return { style: 'disabled' }
  }
  switch ((appliance as Appliance)?.health?.state) {
    case ApplianceConnectionState.missing:
    case ApplianceConnectionState.neverConnected:
      return { style: 'error' }
    case ApplianceConnectionState.connected:
    // Default case handles where user can't see Appliance stats but can see tr101290
    default:
      if (isMissingTr101290Metrics) {
        return { style: 'error' }
      } else if (hasTr101290Errors) {
        return { style: 'warning' }
      } else {
        return { style: 'base' }
      }
  }
}

const getInputIconStatus = (
  status?: InputStatus,
  disabled?: boolean,
): {
  svg: IconData
  style: ObjectStyle
} => {
  if (disabled)
    return {
      svg: NotInterestedSvg,
      style: 'disabled',
    }

  switch (status?.state) {
    case InputOperStatus.allOk:
      return {
        svg: InputSvg,
        style: 'base',
      }
    case InputOperStatus.inputError:
      return {
        svg: VideocamOffSvg,
        style: 'error',
      }
    case InputOperStatus.notConfigured:
      return {
        svg: RoundSvg,
        style: 'base',
      }
    case InputOperStatus.tr101290Priority1Error:
      return {
        svg: InputSvg,
        style: 'warning',
      }
    case InputOperStatus.transportError:
      return {
        svg: LinkOffSvg,
        style: 'error',
      }
    case InputOperStatus.reducedRedundancy:
      return {
        svg: ReducedRedundancySvg,
        style: 'base',
      }
    case InputOperStatus.metricsMissing:
    default:
      return {
        svg: HelpOutlineSvg,
        style: 'warning',
      }
  }
}

const getOutputIconStatus = (
  status?: OutputStatus,
  disabled?: boolean,
): {
  svg: IconData
  style: ObjectStyle
} => {
  if (disabled)
    return {
      svg: NotInterestedSvg,
      style: 'disabled',
    }
  switch (status?.state) {
    case OutputOperStatus.allOk:
      return {
        svg: OndemandVideoOutlinedSvg,
        style: 'base',
      }
    case OutputOperStatus.inputError:
      return {
        svg: VideocamOffSvg,
        style: 'error',
      }
    case OutputOperStatus.notConfigured:
      return {
        svg: RoundSvg,
        style: 'base',
      }
    case OutputOperStatus.outputError:
      return {
        svg: LinkOffSvg,
        style: 'error',
      }
    case OutputOperStatus.reducedRedundancy:
      return {
        svg: ReducedRedundancySvg,
        style: 'base',
      }
    case OutputOperStatus.metricsMissing:
    default:
      return {
        svg: HelpOutlineSvg,
        style: 'warning',
      }
  }
}

interface LineWithSlope<T> {
  line: Line<T>
  slope: number
}

function avoidOverlappingLines<T>(lines: Line<T>[]) {
  const linesAndSlope = lines.map(l => {
    const lineWithSlope: LineWithSlope<T> = {
      line: l,
      slope: slope(l),
    }
    return lineWithSlope
  })
  linesAndSlope.sort((l1, l2) => {
    if (l1.slope < l2.slope) {
      return -1
    }
    if (l1.slope > l2.slope) {
      return 1
    }
    if (l1.line.from[0] < l2.line.from[0]) {
      return -1
    }
    if (l1.line.from[0] > l2.line.from[0]) {
      return 1
    }
    if (l1.line.to[0] > l2.line.to[0]) {
      return -1
    }
    if (l1.line.to[0] < l2.line.to[0]) {
      return 1
    }
    return 0
  })

  let lastItem: typeof linesAndSlope[number] | undefined
  let overlapReference: typeof lastItem
  for (const item of linesAndSlope) {
    if (lastItem && overlaps(overlapReference || lastItem, item)) {
      if (!overlapReference) {
        overlapReference = lastItem
      }
      overlapReference.line.curveType = LineType.curveAntiClockwise
    } else {
      overlapReference = undefined
    }
    lastItem = item
  }

  const ylength = ({ from, to }: Line<T>) => Math.abs(to[1] - from[1])
  const infinitySlope = linesAndSlope.filter(l => (l.slope == -Infinity || l.slope == Infinity) && ylength(l.line) > 1)
  for (const { line } of infinitySlope) {
    if (line.curveType == LineType.straight) {
      line.curveType = LineType.curveAntiClockwise
    }
  }
}

function overlaps<T>(
  { line: line1, slope: slope1 }: LineWithSlope<T>,
  { line: line2, slope: slope2 }: LineWithSlope<T>,
) {
  if (slope1 != slope2) {
    return false
  }
  const m1 = line1.from[1] - slope1 * line1.from[0]
  const m2 = line2.from[1] - slope1 * line2.from[0]
  const doesOverlap = line1.to[0] > line2.from[0] && m1 == m2
  return doesOverlap
}

function slope<T>(line: Line<T>) {
  const { from, to } = line
  const dx = to[0] - from[0]
  const dy = to[1] - from[1]
  return dy / dx
}

function controlPoint(from: Coords, to: Coords, lineType: LineType, curveSize?: CurveBendSize): Coords {
  const offset = (curveSize === CurveBendSize.big ? 6 : 3) * CIRCLE_RADIUS
  const [x1, y1] = from
  const [x2, y2] = to
  const dx = x2 - x1
  const dy = y2 - y1
  const tanAlpha = dy / dx
  const alpha = Math.atan(tanAlpha)
  const midx = (x2 + x1) / 2
  const midy = (y1 + y2) / 2
  const sinAlpha = Math.sin(alpha)
  const cosAlpha = Math.cos(alpha)
  const tmpOffsetX = -sinAlpha * offset
  const tmpOffsetY = cosAlpha * offset
  const offsetX = lineType === LineType.curveAntiClockwise ? tmpOffsetX : -tmpOffsetX
  const offsetY = lineType === LineType.curveAntiClockwise ? tmpOffsetY : -tmpOffsetY
  const x3 = midx + offsetX
  const y3 = midy + offsetY
  // console.log(`Control point from`, { from, to, x3, y3, offsetX, offsetY })
  return [x3, y3]
}

function scalePoint(xscale: number, yscale: number) {
  return function(c: Coords) {
    return [c[0] * xscale, c[1] * yscale]
  }
}
