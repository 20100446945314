import React from 'react'
import { FieldArray, FieldArrayRenderProps, FormikProps, useFormikContext } from 'formik'
import { get } from 'lodash'
import Grid from '@mui/material/Grid'

import { AudioStream, EncoderFeatures, SupportedAudioCodec, VideoCodec, RawVideo } from 'common/api/v1/types'
import { Checkbox, Paper, Select, TextInput } from '../../../common/Form'
import AudioStreamForm from '../AudioStream'
import { EnrichedInputWithEnrichedPorts } from '../'
import { RichOption } from 'src/components/common/Form/Select'
import { assertExclude } from 'common/util'
import { applyCodecRestrictions } from 'common/api/v1/helpers'

const initialAudioStream = {
  codec: '',
  pair: '',
  bitrate: '',
  type: 'stereo',
}

const AudioStreamsPick: React.FunctionComponent<FieldArrayRenderProps & { supportedCodecs: SupportedAudioCodec[] }> = ({
  form,
  name,
  remove,
  supportedCodecs,
}) => {
  const streams = get(form, `values.${name}`)

  return (
    <Grid item xs={12}>
      {streams &&
        streams.length > 0 &&
        streams.map((_: AudioStream, ind: number) => (
          <AudioStreamForm
            supportedCodecs={supportedCodecs}
            key={ind}
            namePrefix={`${name}.${ind}`}
            index={ind}
            remove={remove}
            form={form}
          />
        ))}
    </Grid>
  )
}

interface EncoderSettingsProps {
  namePrefix: string
  encoderFeatures?: EncoderFeatures
  setFieldValue: FormikProps<any>['setFieldValue']
  limitCodecs?: (VideoCodec | RawVideo)[] | undefined
}

const EncoderSettings = ({ namePrefix, encoderFeatures, setFieldValue, limitCodecs }: EncoderSettingsProps) => {
  const { values } = useFormikContext<EnrichedInputWithEnrichedPorts>()
  const audioStreams = get(values.encoderSettings, 'audioStreams') || []

  const encoderSettings = values.encoderSettings
  if (!encoderSettings) {
    throw new Error(`Encoder settings missing`)
  }
  if ('type' in encoderSettings) {
    throw new Error('Invalid encoder settings type: ' + encoderSettings.type)
  }
  const chosenVideoCodec = encoderFeatures?.video.codecs.find(c => c.name == encoderSettings.videoCodec)
  const videoFlags = encoderFeatures?.video.flags || []
  const enabledVideoFlags = videoFlags.filter(
    flag =>
      chosenVideoCodec !== undefined &&
      !flag.disabledForCodecs.includes(assertExclude(chosenVideoCodec?.name, RawVideo)),
  )
  for (const flag of videoFlags) {
    if (!(flag.value in (encoderSettings?.videoFlags || {})) || !enabledVideoFlags.includes(flag)) {
      if (encoderSettings) {
        if (!encoderSettings.videoFlags) {
          encoderSettings.videoFlags = {}
        }
        encoderSettings.videoFlags[flag.value] = false
      }
    }
  }
  const codecOptions =
    encoderFeatures?.video.codecs
      ?.filter(c => !limitCodecs || limitCodecs?.includes(c.name))
      .map(
        c =>
          ({
            name: c.name == RawVideo ? 'Uncompressed' : c.name,
            value: c.name,
          } as RichOption),
      ) || Object.values(VideoCodec)

  const restrictions = chosenVideoCodec?.restrictions
  const pixelFormats = (chosenVideoCodec && chosenVideoCodec.pixelFormat && chosenVideoCodec.pixelFormat) || []
  const pixelFormatOptions = applyCodecRestrictions(pixelFormats, 'pixelFormat', encoderSettings, restrictions)

  const resolutions = (chosenVideoCodec && chosenVideoCodec.resolution && chosenVideoCodec.resolution) || []
  const resolutionOptions = applyCodecRestrictions(resolutions, 'resolution', encoderSettings, restrictions)

  const bitDepths = (chosenVideoCodec && chosenVideoCodec.bitDepth) || []
  const bitDepthOptions = applyCodecRestrictions(bitDepths, 'bitDepth', encoderSettings, restrictions)

  const colorSamplingOptions =
    (chosenVideoCodec && chosenVideoCodec.colorSampling && chosenVideoCodec.colorSampling) || []

  const scanRateOptions =
    (chosenVideoCodec && chosenVideoCodec.scanRate && chosenVideoCodec.scanRate.map(s => ({ name: s, value: s }))) || []

  const scanOptions = (chosenVideoCodec && chosenVideoCodec.scan && chosenVideoCodec.scan) || []

  const profileOptions = (chosenVideoCodec && chosenVideoCodec.profile && chosenVideoCodec.profile) || []

  return (
    <>
      <Paper className="outlined" title="Encoder Settings" collapsible>
        <Grid item xs={12}>
          <Paper>
            <Select
              label="Video codec"
              name={`${namePrefix}.videoCodec`}
              required
              disabled={codecOptions.length < 2}
              options={codecOptions}
            />

            {(profileOptions && profileOptions.length && (
              <Select
                disabled={profileOptions.length == 0}
                label="Profile"
                name={`${namePrefix}.profile`}
                required
                options={profileOptions}
              />
            )) ||
              null}

            {(pixelFormatOptions && pixelFormatOptions.length && (
              <Select
                disabled={pixelFormatOptions.length == 0}
                label="Pixel format"
                name={`${namePrefix}.pixelFormat`}
                required
                options={pixelFormatOptions}
              />
            )) ||
              null}

            {(resolutionOptions && resolutionOptions.length && (
              <Select
                disabled={resolutionOptions.length == 0}
                label="Resolution"
                name={`${namePrefix}.resolution`}
                required
                options={resolutionOptions}
              />
            )) ||
              null}

            {(scanRateOptions && scanRateOptions.length && (
              <Select
                disabled={scanRateOptions.length == 0}
                label="Frame rate"
                name={`${namePrefix}.scanRate`}
                required
                options={scanRateOptions}
              />
            )) ||
              null}

            {(scanOptions && scanOptions.length && (
              <Select
                disabled={scanOptions.length == 0}
                label="Scan"
                name={`${namePrefix}.scan`}
                required
                options={scanOptions}
              />
            )) ||
              null}

            {(bitDepthOptions && bitDepthOptions.length && (
              <Select
                disabled={bitDepthOptions.length == 0}
                label="Bit depth"
                name={`${namePrefix}.bitDepth`}
                required
                options={bitDepthOptions}
              />
            )) ||
              null}

            {(colorSamplingOptions && colorSamplingOptions.length && (
              <Select
                disabled={colorSamplingOptions.length == 0}
                label="Color sampling"
                name={`${namePrefix}.colorSampling`}
                required
                options={colorSamplingOptions}
              />
            )) ||
              null}

            {encoderFeatures?.video.latencyModes && encoderFeatures?.video.latencyModes.length > 0 && (
              <Select
                label="Latency mode"
                name={`${namePrefix}.latencyMode`}
                required
                options={encoderFeatures?.video.latencyModes}
              />
            )}

            {encoderFeatures?.video.scalingModes && encoderFeatures?.video.scalingModes.length > 0 && (
              <Select
                label="Scaling mode"
                name={`${namePrefix}.scalingMode`}
                required
                options={encoderFeatures?.video.scalingModes}
              />
            )}

            <TextInput
              label="Total bitrate (Mbps)"
              name={`${namePrefix}.totalBitrate`}
              required
              type="number"
              noNegative
              validators={{
                number: {
                  greaterThanOrEqualTo: 1,
                  lessThanOrEqualTo: 60,
                  message: `Must be 1 - 60`,
                  noStrings: false,
                },
              }}
            />
            <TextInput
              name={`${namePrefix}.gopSizeFrames`}
              label="GOP Size Frames"
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: 1,
                  lessThanOrEqualTo: 500,
                  message: `Must be 1 - 500`,
                  noStrings: false,
                },
              }}
            />

            {encoderSettings?.videoFlags &&
              enabledVideoFlags.map(flag => (
                <Checkbox
                  disabled={
                    chosenVideoCodec !== undefined &&
                    flag.disabledForCodecs.includes(assertExclude(chosenVideoCodec.name, RawVideo))
                  }
                  key={flag.value}
                  name={`${namePrefix}.videoFlags.${flag.value}`}
                  label={flag.name}
                />
              ))}
          </Paper>
        </Grid>
      </Paper>
      <Paper
        className="outlined"
        title="Audio Streams"
        collapsible
        actionsPane={
          audioStreams.length < 8
            ? [
                {
                  title: 'Add Audio Stream',
                  onClick: () =>
                    setFieldValue(
                      'encoderSettings.audioStreams',
                      audioStreams.concat(({ ...initialAudioStream } as unknown) as AudioStream),
                    ),
                  id: 'add-audio-btn',
                },
              ]
            : []
        }
      >
        <FieldArray
          name="encoderSettings.audioStreams"
          render={(formikArrayHelpers: FieldArrayRenderProps) => {
            return <AudioStreamsPick {...formikArrayHelpers} supportedCodecs={encoderFeatures?.audio.codecs || []} />
          }}
        />
      </Paper>
    </>
  )
}

export default EncoderSettings
