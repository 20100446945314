import { useEffect } from 'react'
import { FormikProps } from 'formik'

import { Address, Output, RistProfile } from 'common/api/v1/types'

import { Select, PerformantTextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import { get } from 'lodash'

export enum RistFields {
  profile = 'profile',
  sourceAddress = 'sourceAddress',
  address = 'address',
  port = 'port',
}

export const ristDefaults = createDefaultFiledValues(Object.keys(RistFields), [])
export const getRistFieldsToSave = () => Object.keys(RistFields)

interface RistFormProps {
  form: FormikProps<Output>
  addresses: Array<Address>
  namePrefix: string
  allocatedPort?: { addresses: Address[]; portNumber: number }
}
const RistForm = ({ form, namePrefix, addresses, allocatedPort }: RistFormProps) => {
  const sourceAddressSelector = `${namePrefix}.${RistFields.sourceAddress}`
  const addressesAndEmptyAddress = [{ address: '', netmask: '' }, ...addresses]
  const addressOptions = [
    { name: 'any', value: '' },
    ...makeAddressOptions(get(form.values, sourceAddressSelector), addresses),
  ]

  const disableLocalPortFields = !!allocatedPort
  useEffect(() => {
    if (allocatedPort) {
      const allocatedAddress = allocatedPort.addresses[0].address
      if (get(form.values, sourceAddressSelector) != allocatedAddress) {
        form.setFieldValue(sourceAddressSelector, allocatedAddress)
      }
    }
  }, [allocatedPort, form])

  return (
    <>
      <Select name={`${namePrefix}.${RistFields.profile}`} label="Profile" options={[RistProfile.simple]} required />
      <Select
        name={sourceAddressSelector}
        label="Source Address"
        options={addressOptions}
        disabled={disableLocalPortFields}
        newLine
        validators={{
          addressIn: { addresses: addressesAndEmptyAddress },
        }}
      />
      <PerformantTextInput
        name={`${namePrefix}.${RistFields.address}`}
        label="Destination address"
        required
        validators={{ ip: {} }}
        type="text"
        newLine
      />
      <PerformantTextInput
        name={`${namePrefix}.${RistFields.port}`}
        label="Destination Port"
        required
        type="number"
        noNegative
        validators={{
          port: { even: true },
        }}
      />
    </>
  )
}

export default RistForm
