import { Checkbox, Paper, Select } from '../../../../common/Form'
import { MatroxE4ApplianceSettings, MatroxE4GenlockResolution } from 'common/api/v1/types'

interface Props {
  settings: MatroxE4ApplianceSettings
}

const resolutionOptions = Object.values(MatroxE4GenlockResolution)

const MatroxE4Settings = ({ settings }: Props) => {
  return (
    <Paper title="MatroxE4 Settings">
      <Paper title="Genlock">
        <Checkbox label={'Enabled'} name={`settings.genlock.isEnabled`} />
        <Select
          label="Resolution"
          name={`settings.genlock.resolution`}
          required
          disabled={!settings.genlock.isEnabled}
          options={resolutionOptions}
        />
      </Paper>
    </Paper>
  )
}

export default MatroxE4Settings
