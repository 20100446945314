import { DispatchProp } from 'react-redux'
import { format } from 'date-fns'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { ApplianceType, ExperimentalFeatures, Input, ListInputSortableField, User } from 'common/api/v1/types'
import {
  getFormattedTransportStreamContent,
  DATE_FORMAT_LONG,
  DATE_FORMAT_SHORT,
  tsInfoServiceName,
} from 'common/api/v1/helpers'

import routes from '../../../utils/routes'
import { InputHealthIndicator } from '../../common/Indicator'
import { TableConfig } from '../../common/Table'
import Thumbnail from '../../common/Thumbnail'
import { Link } from '../../common/Link'
import { inputOutputColor, inputType, isEditableGroup } from '../../../utils'
import { EnrichedInput } from '../../../api/nm-types'
import { hasAccessToAppliance } from '../../../utils'
import { ActionMenu } from './ActionMenu'
import { TransportStream } from 'common/tr101Types'

interface GetConfigProps {
  selected: Array<Input['id']>
  inputs: Array<Input>
  handleSelect: (id: string) => void
  handleSelectAll: () => void
  user: User
  dispatch: DispatchProp['dispatch']
  experimentalFeatures?: ExperimentalFeatures
}

const getConfig: (props: GetConfigProps) => TableConfig<EnrichedInput, ListInputSortableField> = ({
  selected,
  inputs,
  experimentalFeatures,
  handleSelect,
  handleSelectAll,
  user,
  dispatch,
}) => [
  {
    title: (
      <Checkbox
        indeterminate={selected.length > 0 && selected.length < inputs.length}
        checked={selected.length === inputs.length}
        onChange={handleSelectAll}
        inputProps={{ 'aria-label': 'select all inputs' }}
        data-test-id={'select-all'}
      />
    ),
    getValue: ({ id }) => (
      <Checkbox
        checked={selected.includes(id)}
        onChange={() => {
          handleSelect(id)
        }}
        onDoubleClick={e => e.stopPropagation()}
      />
    ),
    props: {
      padding: 'checkbox',
    },
  },
  {
    title: 'preview',
    getValue: input => <Thumbnail input={input} />,
    props: {
      padding: 'none',
      sx: { padding: theme => theme.spacing(1, 1) },
      noTypography: true,
    },
    headerProps: {},
  },
  {
    title: 'name',
    getValue: ({ name, adminStatus }) => (
      <Typography {...inputOutputColor({ adminStatus })} data-test-id="input-name">
        {name}
      </Typography>
    ),
    sorting: {
      byParameter: ListInputSortableField.inputName,
    },
  },
  {
    title: 'protocol',
    getValue: input => {
      const inputTypes = inputType(input)
      const chips = inputTypes.map((label, index) => (
        <Chip
          key={index}
          style={input.ports && input.ports.length > 0 ? { margin: '1pt' } : {}} // TODO: Why margin only when there are ports?
          size="small"
          label={label}
        />
      ))
      return <> {chips} </>
    },
    sorting: {
      byParameter: ListInputSortableField.protocol,
    },
  },
  {
    title: 'appliance',
    getValue: ({ appliances, adminStatus }) =>
      appliances?.length
        ? appliances?.map(a => (
            <Link
              key={a.id}
              to={routes.appliancesUpdate({ id: a.id })}
              available={hasAccessToAppliance(a, user)}
              underline="hover"
            >
              <Typography {...inputOutputColor({ adminStatus })} variant="body2">
                {a.name}
              </Typography>
            </Link>
          ))
        : '',
    sorting: {
      byParameter: ListInputSortableField.applianceName,
    },
  },

  {
    title: 'owner',
    getValue: ({ _owner }) => (
      <Link
        to={routes.groupsUpdate({ id: _owner?.id })}
        available={!!_owner?.id && isEditableGroup(_owner?.id, user)}
        underline="hover"
      >
        <Typography variant="body2" color="textSecondary">
          {!!_owner && _owner.name}
        </Typography>
      </Link>
    ),
    sorting: {
      byParameter: ListInputSortableField.ownerGroupName,
    },
  },
  {
    title: 'created',
    getValue: ({ createdAt, adminStatus }) => (
      <Tooltip title={format(new Date(createdAt), DATE_FORMAT_LONG)} placement="top">
        <Typography {...inputOutputColor({ adminStatus })} variant="body2">
          {format(new Date(createdAt), DATE_FORMAT_SHORT)}
        </Typography>
      </Tooltip>
    ),
    sorting: {
      byParameter: ListInputSortableField.creationDate,
    },
  },
  {
    title: 'format',
    getValue: input => {
      const tsInfo: TransportStream[] = (input.appliances ?? [])
        .map(a =>
          a.type == ApplianceType.videon
            ? input.tsInfo?.find(info => info.applianceId === input.downstreamAppliances[0]?.id)!
            : input.tsInfo?.find(info => info.applianceId === a.id)!,
        )
        .filter(Boolean)

      if (tsInfo.length === 0) {
        return (
          <Tooltip title={'no info'} placement="top">
            <Typography {...inputOutputColor({ adminStatus: input.adminStatus })} variant="body2">
              {getFormattedTransportStreamContent(undefined)}
            </Typography>
          </Tooltip>
        )
      }

      const formattedContent = tsInfo.map(info => getFormattedTransportStreamContent(info)).join('\n')
      const toolTip =
        formattedContent == 'MPTS' ? 'Input contains multiple video streams' : tsInfo.map(tsInfoServiceName).join(', ')
      return (
        <Tooltip title={toolTip} placement="top">
          <Typography
            {...inputOutputColor({ adminStatus: input.adminStatus })}
            variant="body2"
            style={{ whiteSpace: 'pre-line' }}
          >
            {formattedContent}
          </Typography>
        </Tooltip>
      )
    },
  },

  {
    title: 'status',
    getValue: input =>
      input.canSubscribe ? (
        input.alarms && input.alarms.length ? (
          <Link type="button" to={routes.alarms()}>
            <InputHealthIndicator inputId={input.id} />
          </Link>
        ) : (
          <InputHealthIndicator inputId={input.id} />
        )
      ) : (
        'preview'
      ),
  },
  {
    title: '',
    getValue: row => (
      <ActionMenu input={row} user={user} dispatch={dispatch} experimentalFeatures={experimentalFeatures} />
    ),
  },
]

export default getConfig
