import { createContext, useState, FC, ReactNode } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Close } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

const styles = {
  title: {
    margin: 0,
    padding: (theme: Theme) => theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: (theme: Theme) => theme.spacing(1),
    top: (theme: Theme) => theme.spacing(1),
    color: (theme: Theme) => theme.palette.grey[500],
  },
  content: {
    padding: (theme: Theme) => theme.spacing(0, 2, 2, 2),
  },
}

interface ContentDialogStateData {
  isOpen: boolean
  comp?: ReactNode
  title?: string
}

export interface ContentDialogData {
  state: ContentDialogStateData
  actions: {
    handleOpen: (comp: ReactNode, title?: string) => void
    handleClose: () => void
  }
}

export const defaultContextValue: ContentDialogData = {
  state: {
    isOpen: false,
    comp: undefined,
    title: '',
  },
  actions: {
    handleOpen: () => undefined,
    handleClose: () => undefined,
  },
}

export const ContentDialogContext = createContext<ContentDialogData>(defaultContextValue)

export const ContentDialogProvider: FC = ({ children }) => {
  const defaultState = defaultContextValue.state

  const [state, setState] = useState<ContentDialogStateData>(defaultState)

  const handleOpen = (comp: ReactNode, title?: string) => {
    setState(s => ({ ...s, isOpen: true, comp, title }))
  }

  const handleClose = () => {
    setState(s => ({ ...s, isOpen: false }))
  }

  return (
    <ContentDialogContext.Provider
      value={{
        state: state,
        actions: {
          handleOpen,
          handleClose,
        },
      }}
    >
      {children}
      {state.isOpen && (
        <Dialog
          open={state.isOpen}
          onClose={(_event, reason) => {
            if (reason === 'escapeKeyDown') {
              handleClose()
            }
          }}
        >
          <MuiDialogTitle sx={styles.title}>
            <Typography variant="h4" component="span">
              {state.title}
            </Typography>
            <IconButton aria-label="close" sx={styles.closeButton} onClick={handleClose}>
              <Close />
            </IconButton>
          </MuiDialogTitle>
          <Box sx={styles.content}>{state.comp}</Box>
        </Dialog>
      )}
    </ContentDialogContext.Provider>
  )
}
