import { Checkbox, GridItem, Paper, Select, TextInput } from '../../../common/Form'
import Typography from '@mui/material/Typography'
import fullscreen from '../../../../img/1FullScreen.png'
import grid from '../../../../img/4Grid.png'
import { FormikProps } from 'formik'
import {
  MatroxAudioChannelPair,
  MatroxE4AACFormat,
  MatroxE4AACQualityLevel,
  MatroxE4EncoderAACEncoder,
  MatroxE4EncoderBitrateControlMode,
  MatroxE4EncoderIncludeOption,
  MatroxE4EncodingMode,
  MatroxE4FlipOption,
  MatroxE4InputSettings,
  MatroxE4PivotOption,
  MatroxE4PixelFormat,
  MatroxE4ProcessorFrameRate,
  MatroxE4ScalingOption,
  MatroxE4VideoProfile,
  PhysicalPort,
} from 'common/api/v1/types'
import Grid from '@mui/material/Grid'
import { RichOption } from '../../../common/Form/Select'
import {
  getAllowedBitratesForMatroxAACEncoder,
  getCompatibleMatroxVideoProfilesForPixelFormat,
  makeMatroxE4ProcessorAudioSourceSettings,
  makeMatroxE4ProcessorVideoSourceSettings,
  MATROXE4_FRAMEHEIGHT_MULTIPLIER,
  MATROXE4_FRAMESIZE_MAX,
  MATROXE4_FRAMESIZE_MIN,
  MATROXE4_FRAMEWIDTH_MULTIPLIER,
  MATROXE4_IFRAME_INTERVAL_MAX,
  MATROXE4_IFRAME_INTERVAL_MIN,
  MATROXE4_IFRAME_INTERVAL_MULTIPLIER,
  MATROXE4_MAX_ALLOWED_AUDIOSTREAMS,
  MATROXE4_MAXBITRATE_MAX,
  MATROXE4_MAXBITRATE_MIN,
  MATROXE4_MIN_ALLOWED_AUDIOSTREAMS,
  MATROXE4_PFRAME_INTERVAL_MAX,
  MATROXE4_PFRAME_INTERVAL_MIN,
  MATROXE4_QP_MAX,
  MATROXE4_QP_MIN,
  MATROXE4_TARGET_BITRATE_MIN,
  MATROXE4_VIDEO_BRIGHTNESS_MAX,
  MATROXE4_VIDEO_BRIGHTNESS_MIN,
  MATROXE4_VIDEO_CAPTURE_RATE_MAX,
  MATROXE4_VIDEO_CAPTURE_RATE_MIN,
  MATROXE4_VIDEO_CONTRAST_MAX,
  MATROXE4_VIDEO_CONTRAST_MIN,
  MATROXE4_VIDEO_HUE_MAX,
  MATROXE4_VIDEO_HUE_MIN,
  MATROXE4_VIDEO_OPACITY_PERCENTAGE_MAX,
  MATROXE4_VIDEO_OPACITY_PERCENTAGE_MIN,
  MATROXE4_VIDEO_SATURATION_MAX,
  MATROXE4_VIDEO_SATURATION_MIN,
} from 'common/encoderSettingsUtil'
import MuiCheckbox from '@mui/material/Checkbox'
import { useEffect } from 'react'
import { isVirtualAggregatedPort } from 'common/matrox'
import { ColorPicker } from '../../../common/ColorPicker'

interface Props {
  namePrefix: string
  selectedInterfaces: PhysicalPort[]
  matroxSettings: MatroxE4InputSettings
  setFieldValue: FormikProps<any>['setFieldValue']
}

const inputSourceOptions: RichOption[] = [0, 1, 2, 3].map(i => ({ name: `SDI ${i + 1}`, value: i }))

const frameRateOptions = Object.keys(MatroxE4ProcessorFrameRate)
  .map(fr => Number(fr))
  .filter(num => !isNaN(num))
  .sort()

const encoderIncludeOptions: RichOption[] = Object.values(MatroxE4EncoderIncludeOption)
  .map((option: MatroxE4EncoderIncludeOption) => {
    switch (option) {
      case MatroxE4EncoderIncludeOption.none:
        return undefined
      case MatroxE4EncoderIncludeOption.audioOnly:
        return { name: 'Audio only', value: option }
      case MatroxE4EncoderIncludeOption.videoOnly:
        return { name: 'Video only', value: option }
      case MatroxE4EncoderIncludeOption.audioAndVideo:
        return { name: 'Audio and video', value: option }
    }
  })
  .filter(Boolean) as RichOption[]

const scalingOptions: RichOption[] = Object.values(MatroxE4ScalingOption).map((option: MatroxE4ScalingOption) => {
  switch (option) {
    case MatroxE4ScalingOption.noScalingAtTopLeft:
      return { name: 'Unscaled from top left', value: option }
    case MatroxE4ScalingOption.noScalingAtCenter:
      return { name: 'Unscaled centered', value: option }
    case MatroxE4ScalingOption.scaleToFitDestination:
      return { name: 'Stretched to all edges', value: option }
    case MatroxE4ScalingOption.scaleToFillButKeepContentAspectRatio:
      return { name: 'Scaled to all edges', value: option }
    case MatroxE4ScalingOption.scaleToFitAllContent:
      return { name: 'Scaled to nearest edge', value: option }
  }
})

const pivotOptions: RichOption[] = Object.values(MatroxE4PivotOption).map((option: MatroxE4PivotOption) => {
  switch (option) {
    case MatroxE4PivotOption.none:
      return { name: 'None', value: option }
    case MatroxE4PivotOption.by90Degrees:
      return { name: 'By 90 degrees', value: option }
    case MatroxE4PivotOption.by180Degrees:
      return { name: 'By 180 degrees', value: option }
    case MatroxE4PivotOption.by270Degrees:
      return { name: 'By 270 degrees', value: option }
  }
})

const flipOptions: RichOption[] = Object.values(MatroxE4FlipOption).map((option: MatroxE4FlipOption) => {
  switch (option) {
    case MatroxE4FlipOption.none:
      return { name: 'None', value: option }
    case MatroxE4FlipOption.horizontally:
      return { name: 'Horizontally', value: option }
    case MatroxE4FlipOption.vertically:
      return { name: 'Vertically', value: option }
    case MatroxE4FlipOption.bothAxis:
      return { name: 'Both axis', value: option }
  }
})

const encodingProfileOptions: RichOption[] = Object.values(MatroxE4VideoProfile).map((option: MatroxE4VideoProfile) => {
  switch (option) {
    case MatroxE4VideoProfile.Baseline:
      return { name: 'Baseline', value: option }
    case MatroxE4VideoProfile.Main:
      return { name: 'Main', value: option }
    case MatroxE4VideoProfile.High:
      return { name: 'High', value: option }
    case MatroxE4VideoProfile.High10Bit:
      return { name: 'High 10-bit', value: option }
    case MatroxE4VideoProfile.High422:
      return { name: 'High YUV 4:2:2', value: option }
  }
})

const encodingModeOptions: RichOption[] = Object.values(MatroxE4EncodingMode).map((option: MatroxE4EncodingMode) => {
  switch (option) {
    case MatroxE4EncodingMode.lowLatency:
      return { name: 'Optimised for low latency', value: option }
    case MatroxE4EncodingMode.highQuality:
      return { name: 'Favour image quality', value: option }
  }
})

const bitrateControlOptions: RichOption[] = Object.values(MatroxE4EncoderBitrateControlMode).map(
  (option: MatroxE4EncoderBitrateControlMode) => {
    switch (option) {
      case MatroxE4EncoderBitrateControlMode.constant:
        return { name: 'Constant', value: option }
      case MatroxE4EncoderBitrateControlMode.variable:
        return { name: 'Variable', value: option }
    }
  },
)

const aacEncoderOptions: RichOption[] = Object.values(MatroxE4EncoderAACEncoder).map(
  (option: MatroxE4EncoderAACEncoder) => {
    switch (option) {
      case MatroxE4EncoderAACEncoder.lc:
        return { name: 'LC', value: option }
      case MatroxE4EncoderAACEncoder.hev1:
        return { name: 'HEV1', value: option }
      case MatroxE4EncoderAACEncoder.hev2:
        return { name: 'HEV2', value: option }
    }
  },
)

const aacQualityLevelOptions: RichOption[] = Object.values(MatroxE4AACQualityLevel)
  .map((option: MatroxE4AACQualityLevel) => {
    switch (option) {
      case MatroxE4AACQualityLevel.low:
        return { name: 'Low', value: option }
      case MatroxE4AACQualityLevel.medium:
        return { name: 'Medium', value: option }
      case MatroxE4AACQualityLevel.high:
        return { name: 'High', value: option }
      case MatroxE4AACQualityLevel.unknown:
        return undefined
    }
  })
  .filter(Boolean) as RichOption[]

const aacFormatOptions: RichOption[] = Object.values(MatroxE4AACFormat)
  .map((option: MatroxE4AACFormat) => {
    switch (option) {
      case MatroxE4AACFormat.noContainer:
        return { name: 'No container/Raw', value: option }
      case MatroxE4AACFormat.adts:
        return { name: 'ADTS', value: option }
      case MatroxE4AACFormat.adif:
        return undefined
      case MatroxE4AACFormat.unknown:
        return undefined
    }
  })
  .filter(Boolean) as RichOption[]

function formatAudioPair(audioPair: MatroxAudioChannelPair) {
  switch (audioPair) {
    case MatroxAudioChannelPair.channel1And2:
      return '1-2'
    case MatroxAudioChannelPair.channel3And4:
      return '3-4'
    case MatroxAudioChannelPair.channel5And6:
      return '5-6'
    case MatroxAudioChannelPair.channel7And8:
      return '7-8'
    case MatroxAudioChannelPair.channel9And10:
      return '9-10'
    case MatroxAudioChannelPair.channel11And12:
      return '11-12'
    case MatroxAudioChannelPair.channel13And14:
      return '13-14'
    case MatroxAudioChannelPair.channel15And16:
      return '15-16'
  }
}

const MatroxE4SettingsComp = ({
  namePrefix,
  setFieldValue,
  selectedInterfaces,
  matroxSettings: {
    processorSettings,
    encoderSettings: { include, audioSettings, videoSettings },
    tsOutputSettings,
  },
}: Props) => {
  // Port indexes of the currently selected interfaces
  const availableInputSources: Set<number> = selectedInterfaces.reduce((set, port) => {
    const indexes = (port.index ?? '')
      .split(',')
      .map(str => Number(str))
      .filter(i => !isNaN(i))
    for (const i of indexes) {
      set.add(i)
    }
    return set
  }, new Set<number>())

  useEffect(() => {
    const isVirtualMultiPortSelected = selectedInterfaces.some(i => isVirtualAggregatedPort(i))
    if (isVirtualMultiPortSelected) {
      if (processorSettings.videoLayout !== 'quad') {
        // The virtual aggregated port only supports quad mode
        setFieldValue(`${namePrefix}.processorSettings.videoLayout`, 'quad')
      }
      if (processorSettings.videoSourceSettings.length !== 4) {
        setFieldValue(`${namePrefix}.processorSettings.videoSourceSettings`, [
          makeMatroxE4ProcessorVideoSourceSettings(0),
          makeMatroxE4ProcessorVideoSourceSettings(1),
          makeMatroxE4ProcessorVideoSourceSettings(2),
          makeMatroxE4ProcessorVideoSourceSettings(3),
        ])
      }
      if (processorSettings.followVideoSourceInput) {
        // Cannot use video input settings when there are multiple video sources
        setFieldValue(`${namePrefix}.processorSettings.followVideoSourceInput`, false)
      }
    } else {
      // Regular (non virtual-aggregated) interface:
      const physicalPortIndex = availableInputSources.values().next().value ?? 0
      if (processorSettings.videoLayout !== 'single') {
        // Going from 'quad' --> 'single'
        setFieldValue(`${namePrefix}.processorSettings.videoLayout`, 'single')
        setFieldValue(`${namePrefix}.processorSettings.followVideoSourceInput`, true)
        setFieldValue(`${namePrefix}.processorSettings.videoSourceSettings`, [
          makeMatroxE4ProcessorVideoSourceSettings(physicalPortIndex),
        ])
      } else {
        // Going from 'single' --> 'single', e.g. SDI 1 --> SDI 2
        if (processorSettings.videoSourceSettings[0].portIndex !== physicalPortIndex) {
          setFieldValue(`${namePrefix}.processorSettings.videoSourceSettings`, [
            { ...processorSettings.videoSourceSettings[0], portIndex: physicalPortIndex },
          ])
        }
      }

      if (processorSettings.audioSourceSettings.portIndex !== physicalPortIndex) {
        setFieldValue(
          `${namePrefix}.processorSettings.audioSourceSettings`,
          makeMatroxE4ProcessorAudioSourceSettings(physicalPortIndex),
        )
      }
    }
  }, [selectedInterfaces])

  const audioBitrateConstraints = getAllowedBitratesForMatroxAACEncoder(audioSettings.aacEncoder)

  const audioCheckboxes = Object.values(MatroxAudioChannelPair)
    .filter(pair => !isNaN(Number(pair)))
    .map(pair => {
      const currentValue = tsOutputSettings.selectedAudioPairs
      const handleClick = () => {
        const newValue = currentValue.includes(pair as any)
          ? currentValue.filter(p => p !== pair)
          : [...currentValue, pair]
        setFieldValue(`${namePrefix}.tsOutputSettings.selectedAudioPairs`, newValue)
      }
      const isSelected = tsOutputSettings.selectedAudioPairs.includes(pair as MatroxAudioChannelPair)
      const canDeselectAdditional = tsOutputSettings.selectedAudioPairs.length > MATROXE4_MIN_ALLOWED_AUDIOSTREAMS // Min 1
      const canSelectAdditional = tsOutputSettings.selectedAudioPairs.length < MATROXE4_MAX_ALLOWED_AUDIOSTREAMS // Max 4
      return (
        <Checkbox
          key={pair}
          label={formatAudioPair(pair as any)}
          control={
            <MuiCheckbox
              checked={isSelected}
              disabled={(isSelected && !canDeselectAdditional) || (!isSelected && !canSelectAdditional)}
              onChange={handleClick}
            />
          }
        />
      )
    })

  const isVideoFormDisabled = include === MatroxE4EncoderIncludeOption.audioOnly
  const isAudioFormDisabled = include === MatroxE4EncoderIncludeOption.videoOnly

  const hasMultipleVideoSources = processorSettings.videoLayout !== 'single'
  const selectedPixelFormat = processorSettings.followVideoSourceInput
    ? undefined
    : processorSettings.overriddenCanvasSettings.pixelFormat

  return (
    <Paper className="outlined" title="Matrox Settings" collapsible>
      <Grid item xs={12}>
        <Paper title="Processor" collapsible>
          <Paper title="Canvas">
            <Checkbox
              name={`${namePrefix}.processorSettings.inputFailSafe`}
              label={'Input fail safe'}
              tooltip={`Whether or not to continue streaming on signal loss`}
            />

            <Checkbox
              name={`${namePrefix}.processorSettings.followVideoSourceInput`}
              disabled={hasMultipleVideoSources}
              label={'Use video input source settings'}
              tooltip={`Use the same settings as the video input source. Not applicable if there are multiple video input sources. Note: setting it to 'false' will apply progressive encoding.`}
            />

            <TextInput
              label="Frame width"
              name={`${namePrefix}.processorSettings.overriddenCanvasSettings.frameSize.width`}
              disabled={processorSettings.followVideoSourceInput}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROXE4_FRAMESIZE_MIN,
                  lessThanOrEqualTo: MATROXE4_FRAMESIZE_MAX,
                  divisibleBy: MATROXE4_FRAMEWIDTH_MULTIPLIER,
                  message: `Must be a multiplier of ${MATROXE4_FRAMEWIDTH_MULTIPLIER} between ${MATROXE4_FRAMESIZE_MIN}-${MATROXE4_FRAMESIZE_MAX}`,
                  noStrings: false,
                },
              }}
            />
            <TextInput
              label="Frame height"
              name={`${namePrefix}.processorSettings.overriddenCanvasSettings.frameSize.height`}
              disabled={processorSettings.followVideoSourceInput}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROXE4_FRAMESIZE_MIN,
                  lessThanOrEqualTo: MATROXE4_FRAMESIZE_MAX,
                  divisibleBy: MATROXE4_FRAMEHEIGHT_MULTIPLIER,
                  message: `Must be a multiplier of ${MATROXE4_FRAMEHEIGHT_MULTIPLIER} between ${MATROXE4_FRAMESIZE_MIN}-${MATROXE4_FRAMESIZE_MAX}`,
                  noStrings: false,
                },
              }}
            />
            <Select
              label="Frame rate"
              name={`${namePrefix}.processorSettings.overriddenCanvasSettings.frameRate`}
              disabled={processorSettings.followVideoSourceInput}
              required
              options={frameRateOptions}
            />

            <Select
              label="Pixel format"
              name={`${namePrefix}.processorSettings.overriddenCanvasSettings.pixelFormat`}
              disabled={processorSettings.followVideoSourceInput}
              required
              options={Object.values(MatroxE4PixelFormat)}
            />

            <GridItem>
              <ColorPicker
                label={'Background color'}
                initialColor={processorSettings.overriddenCanvasSettings.backgroundColor}
                required
                disabled={processorSettings.followVideoSourceInput}
                onColorSelected={rgb => {
                  setFieldValue(`${namePrefix}.processorSettings.overriddenCanvasSettings.backgroundColor`, {
                    ...rgb,
                    alpha: 255,
                  })
                }}
              />
            </GridItem>
          </Paper>

          <Paper title="Input Sources">
            <Paper title="Audio">
              <Select
                label="Input source"
                name={`${namePrefix}.processorSettings.audioSourceSettings.portIndex`}
                disabled={!hasMultipleVideoSources}
                required
                options={inputSourceOptions}
              />
            </Paper>

            <Paper title="Video">
              <Paper title="Layout">
                <GridItem lg={12} xl={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" marginRight={4}>
                      {processorSettings.videoLayout}
                    </Typography>
                    <img
                      src={hasMultipleVideoSources ? grid : fullscreen}
                      alt={hasMultipleVideoSources ? 'Grid' : 'Fullscreen'}
                    />
                  </div>
                </GridItem>
              </Paper>

              {processorSettings.videoSourceSettings.map((_, index) => {
                const name = `${namePrefix}.processorSettings.videoSourceSettings.${index}`
                const source = ['A', 'B', 'C', 'D'][index]
                return (
                  <Paper key={name} title={`Source ${source}`}>
                    <Select
                      label="Input source"
                      name={`${name}.portIndex`}
                      disabled={!hasMultipleVideoSources}
                      required
                      options={inputSourceOptions}
                    />
                    <TextInput
                      label="Capture rate (frames)"
                      name={`${name}.frameCaptureRate`}
                      disabled={processorSettings.followVideoSourceInput}
                      tooltip={
                        'Defines if and how frames are skipped. A value of 1 indicates all frames are processed while a value of 3 indicates 1 out of every 3 frames are processed and 2 frames are skipped.'
                      }
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROXE4_VIDEO_CAPTURE_RATE_MIN,
                          lessThanOrEqualTo: MATROXE4_VIDEO_CAPTURE_RATE_MAX,
                          message: `Must be between ${MATROXE4_VIDEO_CAPTURE_RATE_MIN}-${MATROXE4_VIDEO_CAPTURE_RATE_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <Select
                      label="Scaling"
                      name={`${name}.scaling`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      options={scalingOptions}
                    />
                    <Select
                      label="Pivot"
                      name={`${name}.pivot`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      options={pivotOptions}
                    />
                    <Select
                      label="Flip"
                      name={`${name}.flip`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      options={flipOptions}
                    />
                    <TextInput
                      label="Opacity (percentage)"
                      name={`${name}.opacityPercentage`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROXE4_VIDEO_OPACITY_PERCENTAGE_MIN,
                          lessThanOrEqualTo: MATROXE4_VIDEO_OPACITY_PERCENTAGE_MAX,
                          message: `Must be between ${MATROXE4_VIDEO_OPACITY_PERCENTAGE_MIN}-${MATROXE4_VIDEO_OPACITY_PERCENTAGE_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <TextInput
                      label="Brightness"
                      name={`${name}.brightness`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROXE4_VIDEO_BRIGHTNESS_MIN,
                          lessThanOrEqualTo: MATROXE4_VIDEO_BRIGHTNESS_MAX,
                          message: `Must be between ${MATROXE4_VIDEO_BRIGHTNESS_MIN}-${MATROXE4_VIDEO_BRIGHTNESS_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <TextInput
                      label="Contrast"
                      name={`${name}.contrast`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROXE4_VIDEO_CONTRAST_MIN,
                          lessThanOrEqualTo: MATROXE4_VIDEO_CONTRAST_MAX,
                          message: `Must be between ${MATROXE4_VIDEO_CONTRAST_MIN}-${MATROXE4_VIDEO_CONTRAST_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <TextInput
                      label="Hue"
                      name={`${name}.hue`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROXE4_VIDEO_HUE_MIN,
                          lessThanOrEqualTo: MATROXE4_VIDEO_HUE_MAX,
                          message: `Must be between ${MATROXE4_VIDEO_HUE_MIN}-${MATROXE4_VIDEO_HUE_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <TextInput
                      label="Saturation"
                      name={`${name}.saturation`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROXE4_VIDEO_SATURATION_MIN,
                          lessThanOrEqualTo: MATROXE4_VIDEO_SATURATION_MAX,
                          message: `Must be between ${MATROXE4_VIDEO_SATURATION_MIN}-${MATROXE4_VIDEO_SATURATION_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                  </Paper>
                )
              })}
            </Paper>
          </Paper>
        </Paper>

        <Paper title="Encoder" collapsible>
          <Select
            label="Include"
            name={`${namePrefix}.encoderSettings.include`}
            required
            options={encoderIncludeOptions}
          />

          <Paper title="Audio">
            <Select
              label="AAC encoder"
              name={`${namePrefix}.encoderSettings.audioSettings.aacEncoder`}
              disabled={isAudioFormDisabled}
              required
              options={aacEncoderOptions}
            />

            <TextInput
              label="Bit rate (kb/s)"
              name={`${namePrefix}.encoderSettings.audioSettings.bitrateKbps`}
              disabled={isAudioFormDisabled}
              required
              type="number"
              noNegative
              validators={
                audioBitrateConstraints && {
                  numericality: {
                    greaterThanOrEqualTo: audioBitrateConstraints.minAllowedBitrateKbps,
                    lessThanOrEqualTo: audioBitrateConstraints.maxAllowedBitrateKbps,
                    message: `Must be between ${audioBitrateConstraints.minAllowedBitrateKbps}-${audioBitrateConstraints.maxAllowedBitrateKbps} for the selected AAC encoder (${audioSettings.aacEncoder})`,
                    noStrings: false,
                  },
                }
              }
            />

            <Select
              label="AAC quality"
              name={`${namePrefix}.encoderSettings.audioSettings.aacQuality`}
              disabled={isAudioFormDisabled}
              required
              options={aacQualityLevelOptions}
            />

            {/*  Currently only allow format ADTS due to Matrox API-errors when using e.g. Raw format */}
            <Select
              label="AAC format"
              name={`${namePrefix}.encoderSettings.audioSettings.aacFormat`}
              disabled={true}
              required
              options={aacFormatOptions}
            />

            <Checkbox
              label={'Use temporal noise shaping'}
              name={`${namePrefix}.encoderSettings.audioSettings.useTemporalNoiseShaping`}
              disabled={isAudioFormDisabled}
            />
          </Paper>

          <Paper title="Video">
            <Checkbox
              name={`${namePrefix}.encoderSettings.videoSettings.forceEncodingSize`}
              label={'Override video settings'}
              disabled={isVideoFormDisabled}
            />

            <TextInput
              label="Frame width"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.frameSize.width`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROXE4_FRAMESIZE_MIN,
                  lessThanOrEqualTo: MATROXE4_FRAMESIZE_MAX,
                  divisibleBy: MATROXE4_FRAMEWIDTH_MULTIPLIER,
                  message: `Must be a multiplier of ${MATROXE4_FRAMEWIDTH_MULTIPLIER} between ${MATROXE4_FRAMESIZE_MIN}-${MATROXE4_FRAMESIZE_MAX}`,
                  noStrings: false,
                },
              }}
            />
            <TextInput
              label="Frame height"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.frameSize.height`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROXE4_FRAMESIZE_MIN,
                  lessThanOrEqualTo: MATROXE4_FRAMESIZE_MAX,
                  divisibleBy: MATROXE4_FRAMEHEIGHT_MULTIPLIER,
                  message: `Must be multiplier of ${MATROXE4_FRAMEHEIGHT_MULTIPLIER} between ${MATROXE4_FRAMESIZE_MIN}-${MATROXE4_FRAMESIZE_MAX}`,
                  noStrings: false,
                },
              }}
            />
            <Select
              label="Scaling"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.scaling`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              options={scalingOptions}
            />
            <Select
              label="Pivot"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.pivot`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              options={pivotOptions}
            />
            <Select
              label="Flip"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.flip`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              options={flipOptions}
            />

            <Select
              label="Encoding profile"
              name={`${namePrefix}.encoderSettings.videoSettings.encodingProfile`}
              disabled={isVideoFormDisabled || processorSettings.followVideoSourceInput}
              required
              tooltip={`Must be compatible with the selected pixel format`}
              options={encodingProfileOptions}
              validators={{
                oneOf: {
                  validValues: new Set(
                    selectedPixelFormat
                      ? getCompatibleMatroxVideoProfilesForPixelFormat(selectedPixelFormat)
                      : [MatroxE4VideoProfile.High422],
                  ),
                },
              }}
            />

            <TextInput
              label="Target bitrate (Mbps)"
              name={`${namePrefix}.encoderSettings.videoSettings.targetBitrateMbps`}
              disabled={isVideoFormDisabled}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROXE4_TARGET_BITRATE_MIN,
                  lessThanOrEqualTo: videoSettings.rateControl.maxBitrateMbps,
                  message: `Must be between ${MATROXE4_TARGET_BITRATE_MIN}-${MATROXE4_MAXBITRATE_MAX} and less than or equal to the selected max bitrate (${videoSettings.rateControl.maxBitrateMbps})`,
                  noStrings: false,
                },
              }}
            />

            <Paper title="Group Of Pictures (GOP)">
              <TextInput
                label="GOP length"
                name={`${namePrefix}.encoderSettings.videoSettings.gop.iFrameInterval`}
                disabled={isVideoFormDisabled}
                required
                tooltip={`Insert a key frame every "${videoSettings.gop.iFrameInterval}" pictures`}
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: videoSettings.gop.pFrameInterval,
                    lessThanOrEqualTo: MATROXE4_IFRAME_INTERVAL_MAX,
                    divisibleBy: MATROXE4_IFRAME_INTERVAL_MULTIPLIER,
                    message: `Must be a multiplier of ${MATROXE4_IFRAME_INTERVAL_MULTIPLIER} between ${MATROXE4_IFRAME_INTERVAL_MIN}-${MATROXE4_IFRAME_INTERVAL_MAX} and greater than or equal to the selected P-frame interval (${videoSettings.gop.pFrameInterval})`,
                    noStrings: false,
                  },
                }}
              />
              <TextInput
                label="P-frame interval"
                name={`${namePrefix}.encoderSettings.videoSettings.gop.pFrameInterval`}
                disabled={isVideoFormDisabled}
                required
                tooltip={`Insert a P-frame every "${videoSettings.gop.pFrameInterval}":th frame`}
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: MATROXE4_PFRAME_INTERVAL_MIN,
                    lessThanOrEqualTo: MATROXE4_PFRAME_INTERVAL_MAX,
                    message: `Must be between ${MATROXE4_PFRAME_INTERVAL_MIN}-${MATROXE4_PFRAME_INTERVAL_MAX} and less than or equal to the selected GOP length (${videoSettings.gop.iFrameInterval})`,
                    noStrings: false,
                  },
                }}
              />
            </Paper>
            <Paper title="Rate Control">
              <Select
                label="Encoding Mode"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.mode`}
                disabled={isVideoFormDisabled}
                required
                options={encodingModeOptions}
              />

              <TextInput
                label="Quantization min"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.quantizationMin`}
                disabled={isVideoFormDisabled}
                required
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: MATROXE4_QP_MIN,
                    lessThanOrEqualTo: videoSettings.rateControl.quantizationMax,
                    message: `Must be between ${MATROXE4_QP_MIN}-${MATROXE4_QP_MAX} and less than or equal to the selected quantization max (${videoSettings.rateControl.quantizationMax})`,
                    noStrings: false,
                  },
                }}
              />
              <TextInput
                label="Quantization max"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.quantizationMax`}
                disabled={isVideoFormDisabled}
                required
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: videoSettings.rateControl.quantizationMin,
                    lessThanOrEqualTo: MATROXE4_QP_MAX,
                    message: `Must be between ${MATROXE4_QP_MIN}-${MATROXE4_QP_MAX} and greater than or equal to the selected quantization min (${videoSettings.rateControl.quantizationMin})`,
                    noStrings: false,
                  },
                }}
              />
              <Select
                label="Bit rate control"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.bitrateControl`}
                disabled={isVideoFormDisabled}
                required
                options={bitrateControlOptions}
              />

              <TextInput
                label="Max bit rate (Mb/s)"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.maxBitrateMbps`}
                disabled={isVideoFormDisabled}
                required
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: Math.max(MATROXE4_MAXBITRATE_MIN, videoSettings.targetBitrateMbps),
                    lessThanOrEqualTo: MATROXE4_MAXBITRATE_MAX,
                    message: `Must be between ${MATROXE4_MAXBITRATE_MIN}-${MATROXE4_MAXBITRATE_MAX} and greater than or equal to the selected target bitrate (${videoSettings.targetBitrateMbps})`,
                    noStrings: false,
                  },
                }}
              />
            </Paper>

            <Checkbox
              label={'Force CAVLC entroy encoding'}
              name={`${namePrefix}.encoderSettings.videoSettings.forceCAVLCEntropyEncoding`}
              disabled={isVideoFormDisabled}
            />
          </Paper>
        </Paper>
        <Paper title="MPEG2TS Output" collapsible>
          <Paper title="Audio Pair Selection">{audioCheckboxes}</Paper>
        </Paper>
      </Grid>
    </Paper>
  )
}

export default MatroxE4SettingsComp
