import { Paper, Select } from '../../../../common/Form'
import {
  ApplianceSettings,
  MatroxD4ApplianceSettings,
  MatroxD4DecoderSettings,
  MatroxD4FramerateFamily,
  MatroxD4OutputResolution,
  MatroxSdiOutputPort,
} from 'common/api/v1/types'
import { RichOption } from '../../../../common/Form/Select'
import { getResolutionsInFramerateFamily, is4kResolution, isMatroxSdi12gPort } from 'common/matrox'

enum MatroxSdiFields {
  decoderSettings = 'decoderSettings',
}

export function matroxSdiPortDefaults(
  settings?: ApplianceSettings,
): { [MatroxSdiFields.decoderSettings]: MatroxD4DecoderSettings } {
  const currentlySelectedFamily =
    settings && 'genlock' in settings && 'framerateFamily' in settings.genlock
      ? settings.genlock.framerateFamily
      : MatroxD4FramerateFamily.familyThree

  const options = getResolutionOptions(0, currentlySelectedFamily, undefined)
  const initialOption = options.find(option => !option.disabled) ?? options[0]
  return {
    [MatroxSdiFields.decoderSettings]: {
      outputSettings: {
        resolution: initialOption.value as MatroxD4OutputResolution,
      },
    },
  }
}

export const getMatroxSdiPortFormFields = () => {
  const fields: string[] = [MatroxSdiFields.decoderSettings]
  return fields
}

interface Props {
  namePrefix: string
  physicalPortIndex: string
  port: MatroxSdiOutputPort
  settings: MatroxD4ApplianceSettings
}

function getResolutionOptions(
  portIndex: number,
  currentlySelectedFamilyOnDevice: MatroxD4FramerateFamily,
  currentResolution: string | undefined,
): RichOption[] {
  const sdi4kTooltip = 'Selecting this will disable all other ports on the appliance'
  const notAvailable4kTooltip = 'Only available on 12G port (SDI-4)'

  const familyMembers: RichOption[] = getResolutionsInFramerateFamily(currentlySelectedFamilyOnDevice).map(res => ({
    name: res,
    value: res,
    disabled: is4kResolution(res) && !isMatroxSdi12gPort(portIndex),
    tooltip: is4kResolution(res) ? (isMatroxSdi12gPort(portIndex) ? sdi4kTooltip : notAvailable4kTooltip) : undefined,
  }))

  const options: RichOption[] = familyMembers.sort((a, b) => a.name.localeCompare(b.name))
  if (currentResolution && !familyMembers.find(res => res.value === currentResolution)) {
    options.push({
      name: currentResolution,
      value: currentResolution,
      disabled: true,
      tooltip: 'Not a member of the current framerate family on the appliance',
    })
  }
  return options
}

const MatroxSdiPortForm = ({ namePrefix, physicalPortIndex, port, settings }: Props) => {
  return (
    <Paper title="Output Settings">
      <Select
        label="Resolution"
        name={`${namePrefix}.${MatroxSdiFields.decoderSettings}.outputSettings.resolution`}
        required
        options={getResolutionOptions(
          Number(physicalPortIndex),
          settings.genlock.framerateFamily,
          port.decoderSettings.outputSettings.resolution,
        )}
      />
    </Paper>
  )
}

export default MatroxSdiPortForm
