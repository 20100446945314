import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select as MUISelect } from '@mui/material'
import Typography from '@mui/material/Typography'

import { Api } from '../../../store'
import {
  AllocatedPhysicalPort,
  AllocatePortRequest,
  Appliance,
  IpPortMode,
  LimitedPhysicalPort,
  PortBase,
  PortMode,
  Region,
  RegionalPort,
} from 'common/api/v1/types'
import { GridItem, Paper } from '../../common/Form'

import { PortForm as InputPortForm } from '../../inputs/Edit/PortForm'
import { initialPort as initialInputPort } from '../../inputs/Edit/InputForm'

import { PortForm as OutputPortForm } from '../../outputs/Edit/PortForm'
import { initialPort as initialOutputPort } from '../../outputs/Edit/OutputForm'
import DelayedAppearance from '../../common/DelayedAppearance'
import { CommonFields as CommonInputFields } from '../../inputs/Edit/PortForm/IpPortForm'
import { CommonFields as CommonOutputFields } from '../../outputs/Edit/PortForm/IpPortForm'
import {
  areMultipleInterfacesPerApplianceSupported,
  collectInterfaceSectionEntries,
  formatInterfaceAddress,
  InterfaceSectionForm,
  isRegionalPort,
  interfaceSectionPaper,
} from './Base'

function limitedPhysicalPortFromAllocatedPort(
  allocatedPort?: AllocatedPhysicalPort,
): (LimitedPhysicalPort & { portNumber: number }) | undefined {
  if (!allocatedPort) return
  return {
    ...allocatedPort.physicalPort,
    portNumber: allocatedPort.portNumber,
  }
}

interface Props {
  namePrefix: string
  isInputForm: boolean
  isEditingExistingEntity: boolean
  isCopyingExistingEntity: boolean
  inputId: string | undefined
  outputId: string | undefined
  initialApplianceOrRegionId: string | undefined
  selectedRegion: Pick<Region, 'id' | 'name'>
  enforcedAppliance: Pick<Appliance, 'id' | 'name' | 'type'> | undefined
  enforcedPortMode: PortMode | undefined
  isModeSelectionDisabled: boolean
  ports: Array<RegionalPort & { _allocatedPort?: AllocatedPhysicalPort }>
}

const Component = <T extends InterfaceSectionForm>(
  props: FormikProps<T> & Props,
  ref: React.Ref<{
    onAddInterfaceButtonClicked: Function
  }>,
) => {
  const {
    namePrefix,
    isInputForm,
    isEditingExistingEntity,
    isCopyingExistingEntity,
    inputId,
    outputId,
    setFieldValue,
    initialApplianceOrRegionId,
    selectedRegion,
    enforcedAppliance,
    enforcedPortMode,
    isModeSelectionDisabled,
    ports,
    values,
  } = props
  const [state, setState] = useState({ isAllocatingPort: false, error: false })

  // TODO: Allocate a new interface every time a new input is selected? Right now the input-picker is at the bottom of the page, i.e. a input will be selected after an interface has already been allocated and thus inputId will not be provided in the allocatePort request.

  // Appliance ids of:
  // 1) all regional interfaces for this input/output and
  // 2) any allocated but not-yet-consumed ports
  const occupiedAppliances = collectInterfaceSectionEntries(values)
    .flatMap(([_k, { region, ports }]) => {
      if (region) {
        return ports.map((p: PortBase) => {
          if (isRegionalPort(p)) return p.region?.allocatedPort?.appliance.id
          else if ('_allocatedPort' in p) return (p as any)._allocatedPort.physicalPort.appliance.id
        })
      }
    })
    .filter(Boolean) as string[]

  const primarySelectedInterface = ports[0]
  const areMultipleInterfacesSupported = areMultipleInterfacesPerApplianceSupported({
    isInput: isInputForm,
    region: selectedRegion,
    portMode: primarySelectedInterface?.mode,
  })

  // Effect triggered when user selects a different region
  useEffect(() => {
    setState({ isAllocatingPort: false, error: false })

    if (enforcedAppliance) {
      // Temporary constraint: Only allow the same appliances and regions as the input if the user has assigned a multi-appliance-input to an output
      allocateInterfaceOnSpecificAppliance(enforcedAppliance.id).then(allocatedInterface =>
        addNewPortToForm(0, allocatedInterface),
      )
    } else {
      const isInitialRegion = initialApplianceOrRegionId === selectedRegion.id
      if (isEditingExistingEntity && isInitialRegion && ports.length > 0) {
        return
      }
      if (isCopyingExistingEntity && isInitialRegion && ports.length > 0) {
        for (let portIndex = 0; portIndex < ports.length; portIndex++) updatePortWithNewAllocatedInterface(portIndex)
        return
      } else {
        const initialPortIndex = 0
        if (occupiedAppliances.length === 0) {
          allocateInterfaceOnArbitraryAppliance(selectedRegion.id).then(allocatedInterface =>
            addNewPortToForm(initialPortIndex, allocatedInterface),
          )
        } else {
          allocateInterfaceOnDifferentAppliance(selectedRegion.id, occupiedAppliances).then(allocatedInterface =>
            addNewPortToForm(initialPortIndex, allocatedInterface),
          )
        }
      }
    }
  }, [selectedRegion])

  useImperativeHandle(ref, () => ({
    onAddInterfaceButtonClicked: async () => {
      const applianceId =
        enforcedAppliance?.id ??
        primarySelectedInterface?._allocatedPort?.physicalPort.appliance.id ??
        primarySelectedInterface?.region?.allocatedPort?.appliance.id
      const allocatedPortId = primarySelectedInterface?._allocatedPort?.id
      const excludedAllocationIds = allocatedPortId ? [allocatedPortId] : []
      const allocatedPort = await (applianceId
        ? allocateInterfaceOnSpecificAppliance(applianceId, excludedAllocationIds)
        : allocateInterfaceOnDifferentAppliance(selectedRegion.id, occupiedAppliances))
      const nextIndex = ports.length
      addNewPortToForm(nextIndex, allocatedPort)
    },
  }))

  const addNewPortToForm = (portIndex: number, allocatedPort: AllocatedPhysicalPort | undefined) => {
    if (!allocatedPort) return
    const portInit = {
      allocatedPortId: allocatedPort.id,
      physicalPortId: allocatedPort.physicalPort.id,
      enforcedMode: areMultipleInterfacesSupported ? (primarySelectedInterface.mode as IpPortMode) : undefined,
    }
    const initialPort = {
      ...(isInputForm ? initialInputPort(portInit) : initialOutputPort(portInit)),
      _allocatedPort: allocatedPort,
    }

    setFieldValue(`${namePrefix}.ports.${portIndex}`, initialPort)
  }
  const updatePortWithNewAllocatedInterface = (portIndex: number) => {
    const portToUpdate = ports[portIndex]
    const portApplianceId =
      portToUpdate._allocatedPort?.physicalPort.appliance.id ?? portToUpdate.region?.allocatedPort?.appliance.id
    const excludedAllocationIds = ports
      .filter((_, i) => i != portIndex)
      .map(p => p.allocatedPortId)
      .filter(nonEmptyString)

    if (!portApplianceId) return
    allocateInterfaceOnSpecificAppliance(portApplianceId, excludedAllocationIds).then(allocatedPort => {
      if (!allocatedPort) return
      setFieldValue(`${namePrefix}.ports.${portIndex}`, {
        ...portToUpdate,
        [isInputForm ? CommonInputFields.allocatedPortId : CommonOutputFields.allocatedPortId]: allocatedPort.id,
        [isInputForm ? CommonInputFields.physicalPort : CommonOutputFields.physicalPort]: allocatedPort.physicalPort.id,
        _allocatedPort: allocatedPort,
      })
    })
  }

  const allocateInterfaceOnArbitraryAppliance = (regionId: string) => {
    return doAllocateInterface({ regionId, inputId: isInputForm ? undefined : inputId })
  }
  const allocateInterfaceOnSpecificAppliance = async (applianceId: string, excludedAllocationIds?: string[]) => {
    return doAllocateInterface({ applianceId, excludedAllocationIds })
  }
  const allocateInterfaceOnDifferentAppliance = (regionId: string, excludedApplianceIds: string[]) => {
    return doAllocateInterface({ regionId, excludedApplianceIds, inputId: isInputForm ? undefined : inputId })
  }
  const doAllocateInterface = async (params: AllocatePortRequest): Promise<AllocatedPhysicalPort | undefined> => {
    setState({ isAllocatingPort: true, error: false })
    try {
      const allocatedPort = await Api.portsApi.requestRegionalPort(params)
      setState({ isAllocatingPort: false, error: false })
      return allocatedPort
    } catch {
      setState({ isAllocatingPort: false, error: true })
    }
  }

  return (
    <>
      {state.isAllocatingPort && (
        <GridItem newLine>
          <DelayedAppearance gracePeriodMs={1000}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Typography component="div" variant="body1">
                Allocating port in region {selectedRegion.name}...
              </Typography>
              <CircularProgress style={{ marginLeft: 20 }} />
            </div>
          </DelayedAppearance>
        </GridItem>
      )}

      {state.error && (
        <GridItem newLine>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Typography component="div" variant="body1">
              Failed allocating a port in region {selectedRegion.name}...
            </Typography>
          </div>
        </GridItem>
      )}

      <FieldArray
        name={`${namePrefix}.ports`}
        render={(formikArrayHelpers: FieldArrayRenderProps) => {
          return ports.map((currentPort, portIndex) => {
            const isPrimaryInterface = portIndex === 0
            const interfaceText = `Regional Interface${isPrimaryInterface ? '' : `-${portIndex + 1}`}`
            const interfaceToolTip = isPrimaryInterface
              ? undefined
              : `Additional interface to ${isInputForm ? 'receive' : 'egress'} the same stream for redundancy.`

            return (
              <Paper key={portIndex} sx={interfaceSectionPaper}>
                <GridItem tooltip={interfaceToolTip} newLine>
                  <FormControl key={portIndex} variant="outlined" style={{ width: '100%' }}>
                    <InputLabel>{interfaceText}</InputLabel>
                    <MUISelect
                      name={`${formikArrayHelpers.name}.${portIndex}.interface`}
                      label={interfaceText}
                      labelId={`select-input-interface-${portIndex}-label`}
                      disabled={true}
                      fullWidth
                      value={currentPort._allocatedPort?.physicalPort?.id || currentPort.region!.allocatedPort!.id}
                    >
                      <MenuItem
                        value={currentPort._allocatedPort?.physicalPort?.id || currentPort.region!.allocatedPort!.id}
                        disabled={true}
                      >
                        <div>
                          <Typography>
                            {currentPort._allocatedPort?.physicalPort?.name || currentPort.region!.allocatedPort!.name}
                          </Typography>
                          {
                            <Typography component="div" variant="body2" color="textSecondary">
                              {formatInterfaceAddress(
                                currentPort._allocatedPort?.physicalPort?.addresses?.[0] ||
                                  currentPort.region!.allocatedPort!.addresses[0],
                              )}
                            </Typography>
                          }
                        </div>
                      </MenuItem>
                    </MUISelect>
                  </FormControl>
                </GridItem>

                {isInputForm && (
                  <InputPortForm
                    index={portIndex}
                    inputId={inputId}
                    isModeDisabled={isModeSelectionDisabled}
                    onModeChanged={() => updatePortWithNewAllocatedInterface(portIndex)}
                    namePrefix={`${formikArrayHelpers.name}.${portIndex}`}
                    inputPort={currentPort._allocatedPort?.physicalPort ?? currentPort.region!.allocatedPort!}
                    allocatedPort={
                      limitedPhysicalPortFromAllocatedPort(currentPort._allocatedPort) ||
                      currentPort.region!.allocatedPort
                    }
                    onRemove={formikArrayHelpers.remove}
                    form={formikArrayHelpers.form}
                  />
                )}
                {!isInputForm && (
                  <OutputPortForm
                    index={portIndex}
                    outputId={outputId}
                    isModeDisabled={isModeSelectionDisabled}
                    onModeChanged={() => updatePortWithNewAllocatedInterface(portIndex)}
                    namePrefix={`${formikArrayHelpers.name}.${portIndex}`}
                    outputPort={currentPort._allocatedPort?.physicalPort ?? currentPort.region!.allocatedPort!}
                    allocatedPort={
                      limitedPhysicalPortFromAllocatedPort(currentPort._allocatedPort) ||
                      currentPort.region!.allocatedPort
                    }
                    enforcedPortMode={enforcedPortMode}
                    onRemove={formikArrayHelpers.remove}
                    form={formikArrayHelpers.form}
                    settings={undefined}
                  />
                )}
              </Paper>
            )
          })
        }}
      />
    </>
  )
}

function nonEmptyString(s?: string): s is string {
  return typeof s == 'string' && !!s
}

export const ReferencedComponent = forwardRef(Component)

export const RegionalInterfaceSection = <T extends InterfaceSectionForm>({
  myRef,
  ...rest
}: FormikProps<T> & Props & { myRef: React.Ref<{ onAddInterfaceButtonClicked: Function }> }) => (
  <ReferencedComponent {...(rest as any)} ref={myRef} />
)
