import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Menu } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import { toggleSidebarMenu } from '../../redux/actions/uiActions'
import { useDebouncedFilter, usePageParams } from '../../utils'
import { AppDispatch, GlobalState } from '../../store'
import SearchInput from './SearchInput'

const styles = {
  spacer: {
    flexGrow: 1,
  },
  content: {
    marginTop: (theme: Theme) => theme.spacing(2),
  },
  search: (theme: Theme) => ({
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }),
}

export interface WrapperProps {
  name: string
  entityName?: string
  searchbarPlaceholder?: string
}
/**
 * Common wrapper for edge manager page content
 * @param entityName - to show the name of the entity (input name for example) we opened
 * @param name - the name of the page
 * @param searchbarPlaceholder
 * @param children - content of the page
 */
const Wrapper: React.FunctionComponent<WrapperProps> = ({ entityName, name, searchbarPlaceholder, children }) => {
  const dispatch = useDispatch<AppDispatch>()
  const ToggleSidebarMenuAction = () => dispatch(toggleSidebarMenu())
  const { isSearchable } = useSelector(({ uiReducer }: GlobalState) => uiReducer, shallowEqual)
  const [params, setPageParams] = usePageParams<{ filter: string; pageNumber: string }>()
  const { filter: initial = '' } = params
  const [filter, setFilter] = useState(initial)
  useDebouncedFilter(filter, filter => setPageParams({ filter, pageNumber: '0' }))
  useEffect(() => {
    if (initial !== filter) setFilter(initial)
  }, [initial])
  return (
    <section>
      <AppBar position="relative" data-test-id="app-bar">
        <Toolbar disableGutters>
          <IconButton edge="start" color="inherit" aria-label="Open drawer" onClick={ToggleSidebarMenuAction}>
            <Menu />
          </IconButton>

          {entityName ? (
            <Typography component="h1" variant="h2" color="inherit" noWrap>
              {name || 'Content dashboard'} / {entityName}
            </Typography>
          ) : (
            <Typography component="h1" variant="h2" color="inherit" noWrap>
              {name || 'Content dashboard'}
            </Typography>
          )}

          <Box sx={styles.spacer} />
          {!!isSearchable && (
            <Box sx={styles.search}>
              <SearchInput
                initialValue={filter}
                onChange={input => setFilter(input)}
                placeholder={searchbarPlaceholder}
                stretchable
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Grid container spacing={5}>
        <Grid item xs={12} sx={styles.content}>
          {children}
        </Grid>
      </Grid>
    </section>
  )
}

export default Wrapper
