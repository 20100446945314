import { useEffect } from 'react'
import { FormikProps } from 'formik'

import {
  ApplianceSettings,
  ApplianceType,
  CoaxOutputPort,
  CoaxPortMode,
  MatroxD4ApplianceSettings,
  MatroxOutputPort,
  MatroxPortMode,
  Output,
} from 'common/api/v1/types'

import { Select } from '../../../../common/Form'
import { CommonFields } from '../IpPortForm'
import { get } from 'lodash'
import { RichOption } from 'src/components/common/Form/Select'
import MatroxSdiPortForm, { getMatroxSdiPortFormFields } from './MatroxSdiPortForm'
import VaSdiPortForm, { getVaSdiPortFormFields } from './VaSdiPortForm'

interface CoaxPortFormProps {
  namePrefix: string
  physicalPortIndex: string
  form: FormikProps<Output>
  applianceType: ApplianceType
  settings: ApplianceSettings | undefined
}

export const getCoaxPortFormFields = (port: CoaxOutputPort | MatroxOutputPort) => {
  const fields: string[] = [CommonFields.id, CommonFields.physicalPort, CommonFields.copies, CommonFields.mode]
  if (port.mode === CoaxPortMode.sdi) {
    fields.push(...getVaSdiPortFormFields())
  } else if (port.mode === MatroxPortMode.matroxSdi) {
    fields.push(...getMatroxSdiPortFormFields())
  }
  return fields
}

const CoaxPortForm = ({ namePrefix, physicalPortIndex, applianceType, settings, form }: CoaxPortFormProps) => {
  const modeKey = `${namePrefix}.mode`
  const port = get(form.values, namePrefix)
  const modes: RichOption[] = []
  if (applianceType === ApplianceType.matroxMonarchEdgeD4) {
    modes.push({ name: 'sdi', value: MatroxPortMode.matroxSdi })
  } else {
    modes.push(
      ...[
        { name: 'sdi', value: CoaxPortMode.sdi },
        { name: 'asi', value: CoaxPortMode.asi },
      ],
    )
  }

  useEffect(() => {
    const isValidMode = modes.find(o => o.value === port.mode)
    if (!isValidMode && (port.mode as string) !== '') {
      form.setFieldValue(modeKey, '')
    }
  }, [modeKey, port.mode, modes])

  return (
    <>
      <Select
        label="Mode"
        name={`${namePrefix}.mode`}
        required
        newLine
        options={modes}
        validators={{
          oneOf: { validValues: new Set(modes?.map(m => m.value)) },
        }}
      />

      {port.mode === CoaxPortMode.sdi && <VaSdiPortForm namePrefix={namePrefix} />}
      {port.mode === MatroxPortMode.matroxSdi && (
        <MatroxSdiPortForm
          namePrefix={namePrefix}
          physicalPortIndex={physicalPortIndex}
          port={port}
          settings={settings as MatroxD4ApplianceSettings}
        />
      )}
    </>
  )
}

export default CoaxPortForm
