import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { RouteComponentProps } from 'react-router-dom'
import { mergeWith, omit, omitBy, pick } from 'lodash'
import Grid from '@mui/material/Grid'

import {
  Appliance,
  EncoderSettings,
  GlobalSettings,
  Input,
  InputAdminStatus,
  InputInit,
  InputPort,
  PhysicalPort,
  Role,
  ThumbnailMode,
  VideoPreviewMode,
  VideoCodec,
} from 'common/api/v1/types'
import { clearInput, createInput, getInput, updateInput } from '../../../redux/actions/inputsActions'
import { Api, AppDispatch, GlobalState } from '../../../store'
import { formTransform, useConfirmationDialog, usePageParams, useUser } from '../../../utils'
import Pendable from '../../common/Pendable'
import Wrapper from '../../common/Wrapper'

import { CommonFields, getIpPortFormFields } from './PortForm/IpPortForm'
import routes from '../../../utils/routes'

import InputForm, { initialPort } from './InputForm'
import { EnrichedInput, EnrichedInputPort, EnrichedInputWithPorts, EnrichedPhysicalPort } from '../../../api/nm-types'
import {
  collectPortsFromInterfaceSections,
  groupPortsByApplianceOrRegion,
  isCoreNode,
} from '../../common/Interface/Base'
import { isIpPort } from 'common/api/v1/helpers'
import { enqueueErrorSnackbar } from '../../../redux/actions/notificationActions'
import { portModesWithEncoderSettings } from 'common/encoderSettingsUtil'

export interface EnrichedInputWithEnrichedPorts extends EnrichedInput {
  ports?: Array<InputPort & { _port: EnrichedPhysicalPort & { _appliance: Appliance } }>
  _derived: boolean
}

const getInitialState = (
  selectedInput: EnrichedInputWithPorts | undefined,
  isCopy: boolean,
  pageParams: Record<string, string>,
  settings: GlobalSettings | undefined,
  parentInput: Input | null,
): EnrichedInputWithEnrichedPorts => {
  const encoderSettings: EncoderSettings = {
    videoCodec: '' as VideoCodec,
    totalBitrate: ('' as unknown) as number,
    gopSizeFrames: 150,
    audioStreams: [],
    latencyMode: '',
    bitDepth: ('' as unknown) as number,
    colorSampling: '',
    scan: '',
    scanRate: '',
    pixelFormat: '',
    profile: '',
    resolution: '',
  }
  const broadcastStandard =
    parentInput?.broadcastStandard || selectedInput?.broadcastStandard || settings?.defaultBroadcastStandard || 'dvb'
  const receiver = {
    name: '',
    maxBitrateMbps: (selectedInput?.maxBitrate && selectedInput?.maxBitrate / 10 ** 6) || '',
    tr101290Enabled: true,
    broadcastStandard: selectedInput?.tr101290Enabled === false ? 'none' : broadcastStandard,
    thumbnailMode: true,
    videoPreviewMode: selectedInput?.previewSettings?.mode || VideoPreviewMode.ondemand,
    adminStatus: true,
    ports: [],
    downstreamAppliances: [],
    _redundant: !!(selectedInput && selectedInput.ports && selectedInput.ports[0]?.copies == 2),
    bufferSize: 6000,
    encoderSettings,
    deriveFrom: {
      ingestTransform: selectedInput?.deriveFrom?.ingestTransform || {
        services: [],
      },
      parentInput: pageParams.deriveFrom || selectedInput?.deriveFrom?.parentInput || '',
    },
    parentInput: '',
    _derived: !!selectedInput?.deriveFrom?.parentInput || !!pageParams.deriveFrom,
  }
  mergeWith(
    receiver,
    omit(selectedInput, ['metrics', 'alarms', 'broadcastStandard']),
    (_: any, existingValueForKey: any, key: any) => {
      if ('totalBitrate' === key && existingValueForKey) {
        return existingValueForKey / 1000000
      }
      if (key === 'thumbnailMode') {
        return existingValueForKey === ThumbnailMode.core
      }
      if (key === 'adminStatus') {
        return existingValueForKey === InputAdminStatus.on
      }
      if (key === 'ports') {
        return existingValueForKey.map((item: EnrichedInputPort & { _port: EnrichedPhysicalPort }) =>
          mergeWith(
            initialPort({
              physicalPortId: item.physicalPort,
              port: item._port,
            }),
            item,
            (_, existingValueForKey2, key2) => {
              if ('reducedBitrateThreshold' === key2 && existingValueForKey2) {
                return existingValueForKey2 / 1000
              }
            },
          ),
        )
      }
    },
  )
  if (isCopy) {
    receiver.name += ' (copy)'
  }

  // 1. Divide ports into groups by their appliance or region, using key '_interfaceSection-${id}'
  // 2. We will later create one <InterfaceSection> per group, passing the above key as namePrefix
  // 3. Each InterfaceSection and sub-component modifies their nameprefix-XXX entry
  // 4. When the user submits, we will merge each namePrefix-entry back into the 'ports' again
  const portsGroupedByApplianceOrRegion = groupPortsByApplianceOrRegion(receiver.ports)
  return ({ ...receiver, ...portsGroupedByApplianceOrRegion } as unknown) as EnrichedInputWithEnrichedPorts
}

export const Edit = ({ history, match }: RouteComponentProps<{ id?: string }>) => {
  const inputId = match.params.id
  const user = useUser()
  const [parameters] = usePageParams()
  const dispatch = useDispatch<AppDispatch>()
  const [parentInput, setParentInput] = useState<Input | null>(null)

  useEffect(() => {
    inputId && dispatch(getInput(inputId))
    return () => {
      dispatch(clearInput())
    }
  }, [dispatch])

  const selectedInput = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.input, shallowEqual)
  const showConfirmation = useConfirmationDialog()

  useEffect(() => {
    if (parameters.deriveFrom) {
      Api.inputApi
        .getInput(parameters.deriveFrom)
        .then(input => {
          setParentInput(input)
        })
        .catch(err => {
          dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch parent input' }))
          history.goBack()
        })
    }
  }, [parameters.deriveFrom])

  const { settings } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  if (selectedInput && user.group !== selectedInput.owner && user.role !== Role.super) {
    history.goBack()
    return null
  }

  const initialState = getInitialState(
    selectedInput,
    match.path === routes.inputsCopy.route,
    parameters,
    settings,
    parentInput,
  )

  const onSubmit = (input: InputInit | Input) => {
    const action = () => dispatch(updateInput(input as Input))
    if (selectedInput && match.path === routes.inputsUpdate.route) {
      if (selectedInput.numOutputs)
        showConfirmation(() => {
          action()
        }, 'Current input is in use! Are you sure you want to edit it?')
      else action()
    } else dispatch(createInput(omit(input, ['id']) as InputInit))
  }

  const isLoading = (Boolean(inputId) && !selectedInput) || (Boolean(parameters.deriveFrom) && !parentInput)

  return (
    <Wrapper name="Inputs" entityName={inputId ? selectedInput?.name : 'New'}>
      <Grid container spacing={0}>
        <Pendable pending={isLoading}>
          <Formik
            component={InputForm}
            initialValues={initialState}
            validateOnChange={false}
            onSubmit={values => {
              values.ports = collectPortsFromInterfaceSections(values)

              const hasEncoderSettings =
                values.ports.length > 0 && portModesWithEncoderSettings.includes(values.ports[0].mode)

              const transformed = formTransform(values, {
                deriveFrom: {
                  _transform: (deriveFrom: InputInit['deriveFrom']) =>
                    deriveFrom?.parentInput && values._derived
                      ? {
                          ...deriveFrom,
                          delay: 1000,
                          ingestTransform: { ...deriveFrom.ingestTransform, type: 'mpts-demux' },
                        }
                      : undefined,
                },
                thumbnailMode: { _transform: (mode: boolean) => (mode ? ThumbnailMode.core : ThumbnailMode.none) },
                videoPreviewMode: {
                  _transform: (val: string) => (values.thumbnailMode ? val : VideoPreviewMode.off),
                },
                adminStatus: { _transform: (val: boolean) => (val ? InputAdminStatus.on : InputAdminStatus.off) },
                ports: {
                  reducedBitrateThreshold: {
                    _transform: (bitrate: number | '') => (bitrate === '' ? undefined : bitrate * 1000),
                  },
                  _transform: (port: Partial<InputPort> & { _port: PhysicalPort }) => {
                    if (!port?.physicalPort) {
                      return undefined
                    }
                    const result = isIpPort(port as InputPort)
                      ? pick(port, getIpPortFormFields(port as InputPort))
                      : pick(port, [CommonFields.physicalPort, CommonFields.mode, CommonFields.copies, 'id'])
                    result.copies = !isCoreNode(values) && values._redundant ? 2 : 1
                    return { ...omit(result, ['region.allocatedPort']) }
                  },
                },
                encoderSettings: {
                  _transform: (es: Partial<EncoderSettings>) => (!hasEncoderSettings ? undefined : es),
                  totalBitrate: {
                    _transform: (bitrate: number | '') => (bitrate === '' ? undefined : bitrate * 1000000),
                  },
                },
                _transform: (input: Partial<Input>) => omitBy(input, (_value: any, key: string) => key.startsWith('_')),
              })
              if (values._derived) {
                transformed.ports = undefined
              }

              if ((values.broadcastStandard || 'none') == 'none') {
                transformed.tr101290Enabled = false
                transformed.broadcastStandard = undefined
              } else {
                transformed.tr101290Enabled = true
              }
              delete transformed.maxBitrate
              transformed.maxBitrate = transformed.maxBitrateMbps ? transformed.maxBitrateMbps * 10 ** 6 : null
              onSubmit((transformed as unknown) as InputInit)
            }}
          />
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
