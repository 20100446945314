import { Component, ErrorInfo } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { styles, Paper } from './Form'

interface Props extends RouteComponentProps {}

interface State {
  error?: {
    error: Error
    errorInfo: ErrorInfo
  }
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: undefined }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ error: undefined })
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: {
        error: error,
        errorInfo: errorInfo,
      },
    })
    // TODO: Log error messages to an error reporting service here...
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorDetails
          error={this.state.error.error}
          errorInfo={this.state.error.errorInfo}
          onOKButtonClicked={() => this.props.history.goBack()}
        />
      )
    }
    return this.props.children
  }
}

const ErrorDetails = ({
  error,
  errorInfo,
  onOKButtonClicked,
}: {
  error: Error
  errorInfo: ErrorInfo
  onOKButtonClicked: () => void
}) => {
  return (
    <Container component="main" maxWidth="xs">
      <Paper>
        <Typography component="h2" variant="h2">
          Something went wrong
        </Typography>
        <br />
        <Typography component="h2" variant="body2">
          {error.toString()}
        </Typography>
        <br />
        <details open={false} style={{ whiteSpace: 'pre-wrap' }}>
          {errorInfo.componentStack}
        </details>
        <br />
        <Button onClick={onOKButtonClicked} variant="contained" color="primary" sx={styles.button}>
          OK
        </Button>
      </Paper>
    </Container>
  )
}

export default withRouter(ErrorBoundary)
