import { useState, FormEvent } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import FormHelperText from '@mui/material/FormHelperText'
import { styled, Theme } from '@mui/material/styles'

import { GlobalState, AppDispatch } from '../../store'
import { Loading } from '../common/Loading'
import { loginUser } from '../../redux/actions/userActions'
import { LoginLogo } from '../common/LogoFetcher'

const Form = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}))

const styles = {
  paper: {
    marginTop: (theme: Theme) => theme.spacing(8),
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
  submit: {
    margin: (theme: Theme) => theme.spacing(3, 0, 2),
  },
}

const commonInputProps = {
  variant: 'outlined' as const,
  margin: 'normal' as const,
  required: true,
  fullWidth: true,
}

const ErrorHelper = ({ shown }: { shown?: boolean }) =>
  shown ? (
    <FormHelperText error variant="filled">
      Username or password is incorrect!
    </FormHelperText>
  ) : null

export const Login = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { loading, loginFail } = useSelector((state: GlobalState) => state.userReducer, shallowEqual)
  const loginUserFunc = (username: string, password: string) => dispatch(loginUser({ username, password }))
  const [user, setUser] = useState({ name: '', password: '' })

  const handleLogin = (e: FormEvent) => {
    e.preventDefault()
    //TODO VALIDATE
    if (user && user.name && user.password) {
      loginUserFunc(user.name, user.password)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Loading loading={loading} />
      <Box sx={styles.paper}>
        <LoginLogo />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Form noValidate onSubmit={handleLogin}>
          <TextField
            {...commonInputProps}
            value={user.name}
            onChange={e => setUser({ ...user, name: e.target.value })}
            id="email"
            label="Email Address"
            autoComplete="email"
            autoFocus
          />
          <TextField
            {...commonInputProps}
            value={user.password}
            onChange={e => setUser({ ...user, password: e.target.value })}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <ErrorHelper shown={loginFail} />
          <Button
            id="login-button"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={styles.submit}
            disabled={!user.name || !user.password}
          >
            Sign In
          </Button>
        </Form>
      </Box>
    </Container>
  )
}
