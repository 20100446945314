import { useEffect } from 'react'
import { FormikProps } from 'formik'
import Button from '@mui/material/Button'

import {
  Address,
  ApplianceSettings,
  FecLimits,
  Input,
  InputAdminStatus,
  PhysicalPort,
  Output,
  OutputAdminStatus,
  PortMode,
  PortType,
  LimitedPhysicalPort,
  ComprimatoPortMode,
  RawVideo,
} from 'common/api/v1/types'
import { GridItem, TextInput } from '../../../common/Form'
import IpPortForm from './IpPortForm'
import CoaxPortForm from './CoaxPortForm/CoaxPortForm'
import { Select } from '../../../common/Form'
import { getPortsInUseBy } from '../../../../utils'
import _ from 'lodash'
import { getApplianceDefaultCoaxMode } from '../../../../utils/applianceDefaults'

interface IPortFormProps {
  outputId?: string
  form: FormikProps<Output>
  index: number
  outputPort: LimitedPhysicalPort & { _inputs?: Array<Input>; _outputs?: Array<Output> } & Pick<PhysicalPort, 'index'>
  applianceFecLimits?: FecLimits
  allocatedPort?: { addresses: Address[]; portNumber: number }
  isModeDisabled: boolean
  onModeChanged?: () => void
  namePrefix: string
  onRemove?: (index: number) => void
  enforcedPortMode: PortMode | undefined
  settings: ApplianceSettings | undefined
}

export const PortForm = ({
  outputId,
  outputPort,
  allocatedPort,
  isModeDisabled,
  onModeChanged,
  applianceFecLimits,
  namePrefix,
  enforcedPortMode,
  form,
  index,
  onRemove,
  settings,
}: IPortFormProps) => {
  const portsInUseOnSelectedInterface = getPortsInUseBy({
    outputs:
      '_outputs' in outputPort && outputPort._outputs
        ? outputPort._outputs.filter(output => output.adminStatus == OutputAdminStatus.on && output.id != outputId)
        : [],
    inputs:
      '_inputs' in outputPort && outputPort._inputs
        ? outputPort._inputs.filter(input => input.adminStatus == InputAdminStatus.on)
        : [],
  })

  const mode = _.get(form.values, `${namePrefix}.mode`)
  const physicalPortType = _.get(form.values, `${namePrefix}._port.portType`) as PortType | undefined
  const applianceType = outputPort.appliance.type
  useEffect(() => {
    if (physicalPortType == PortType.ndi) {
      if (mode != ComprimatoPortMode.comprimatoNdi) {
        form.setFieldValue(`${namePrefix}.mode`, ComprimatoPortMode.comprimatoNdi)
      }
    } else if (physicalPortType == PortType.coax) {
      const defaultMode = getApplianceDefaultCoaxMode(applianceType)
      if (defaultMode && !mode) {
        form.setFieldValue(`${namePrefix}.mode`, defaultMode)
      }
    }
  }, [physicalPortType, applianceType, mode])

  useEffect(() => {
    if (mode == ComprimatoPortMode.comprimatoNdi || mode == ComprimatoPortMode.comprimatoSdi) {
      form.setFieldValue(`encoderSettings.videoCodec`, RawVideo)
    } else if (mode != '') {
      form.setFieldValue(`encoderSettings.videoCodec`, '')
    }
  }, [mode])

  return (
    <>
      {outputPort.portType === PortType.ip && (
        <IpPortForm
          index={index}
          namePrefix={namePrefix}
          form={form}
          addresses={outputPort.addresses}
          applianceType={outputPort.appliance.type}
          applianceFecLimits={applianceFecLimits}
          occupiedPorts={portsInUseOnSelectedInterface}
          allocatedPort={allocatedPort}
          isModeDisabled={isModeDisabled}
          onModeChanged={onModeChanged}
          enforcedPortMode={enforcedPortMode}
        />
      )}

      {outputPort.portType === PortType.coax && (
        <CoaxPortForm
          physicalPortIndex={outputPort.index ?? 'Missing port index'}
          namePrefix={namePrefix}
          applianceType={outputPort.appliance.type}
          settings={settings}
          form={form}
        />
      )}

      {outputPort.portType === PortType.ndi && (
        <>
          <Select
            label="Mode"
            name={`${namePrefix}.mode`}
            required
            newLine
            options={[{ name: 'NDI', value: 'comprimatoNdi' }]}
          />
          <TextInput name={`${namePrefix}.name`} label="Name" required />
        </>
      )}

      {onRemove && (
        <GridItem newLine>
          <Button style={{ marginTop: 12 }} variant="outlined" color="primary" onClick={() => onRemove(index)}>
            Remove interface
          </Button>
        </GridItem>
      )}
    </>
  )
}
