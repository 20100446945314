import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import { Settings } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { useTheme, Theme } from '@mui/material/styles'

import { useKubernetesNodesSelector } from '../../../utils'
import { usePageParamsFilteredSelector } from '../../../utils'
import Table, { TableConfig } from '../../common/Table'
import { KubernetesNode } from 'common/api/v1/types'
import { MissingContent } from '../../common/MissingContent'
import Wrapper from '../../common/Wrapper'
import { Link } from '../../common/Link'
import routes from '../../../utils/routes'
import { enqueueSnackbar } from '../../../redux/actions/notificationActions'
import { AppDispatch, GlobalState } from 'src/store'
import { getResourceUsage } from '../../../redux/actions/k8sActions'

function CircularProgressWithLabel(props: { value: number }) {
  const theme: Theme = useTheme()

  let styles = {}
  if (props.value > 90) {
    styles = { color: theme.palette.error.main }
  } else if (props.value > 75) {
    styles = { color: theme.palette.warning.main }
  }

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={props.value} style={styles} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export const List = () => {
  const { kubernetesNodes, total, loading: loadingNodes, errors } = usePageParamsFilteredSelector(
    useKubernetesNodesSelector,
  )

  const dispatch = useDispatch<AppDispatch>()
  const { resourceUsage, loadingResourceUsage } = useSelector(({ k8sReducer }: GlobalState) => k8sReducer, shallowEqual)

  useEffect(() => {
    if (errors) {
      const details = errors.map(e => e.message).join('\n')
      const text = 'Failed to fetch nodes'

      dispatch(
        enqueueSnackbar({
          type: 'fatalError',
          options: { variant: 'error', persist: true },
          data: {
            text,
            details,
            httpStatusCode: undefined,
          },
        }),
      )
    }
  }, [errors])

  useEffect(() => {
    function doRefresh() {
      dispatch(getResourceUsage())
    }
    doRefresh()
    const timerId = setInterval(doRefresh, 30_000)
    return function stopRefresh() {
      clearInterval(timerId)
    }
  }, [])

  const getRolesString = (node: KubernetesNode) => {
    return node.roles.join(', ')
  }

  const tableConfig: TableConfig<KubernetesNode, string> = [
    {
      title: 'name',
      getValue: node => node.name,
      sorting: { byParameter: 'name' },
    },
    {
      title: 'region',
      getValue: ({ region }) => region.name,
      sorting: { byParameter: 'region' },
    },
    {
      title: 'CPU',
      getValue: ({ name, region }) => {
        if (loadingResourceUsage) {
          return <CircularProgress />
        }
        const nodeResourceUsage = resourceUsage.find(node => node.name === name && node.region === region.id)
        return nodeResourceUsage ? (
          <CircularProgressWithLabel value={(nodeResourceUsage.cpu.usage / nodeResourceUsage.cpu.capacity) * 100} />
        ) : (
          'N/A'
        )
      },
    },
    {
      title: 'Memory',
      getValue: ({ name, region }) => {
        if (loadingResourceUsage) {
          return <CircularProgress />
        }
        const nodeResourceUsage = resourceUsage.find(node => node.name === name && node.region === region.id)
        return nodeResourceUsage ? (
          <CircularProgressWithLabel
            value={(nodeResourceUsage.memory.usage / nodeResourceUsage.memory.capacity) * 100}
          />
        ) : (
          'N/A'
        )
      },
    },
    {
      title: 'status',
      getValue: node => node.status,
      sorting: { byParameter: 'status' },
    },
    {
      title: 'internal IP',
      getValue: node => node.internalIP,
    },
    {
      title: 'external IP',
      getValue: node => node.externalIP,
    },
    {
      title: 'kubelet version',
      getValue: node => node.kubeletVersion,
    },
    {
      title: 'roles',
      getValue: node => getRolesString(node),
    },
    {
      title: 'actions',
      getValue: node => {
        return (
          <div>
            {[
              {
                name: 'Edit',
                button: undefined,
                onClick: undefined,
                link: (
                  <Link to={routes.kubernetesNodesUpdate({ regionId: node.region.id, name: node.name })} tabIndex={-1}>
                    <IconButton edge="end" data-test-id="edit-node">
                      <Settings />
                    </IconButton>
                  </Link>
                ),
              },
            ].map(({ name, button, link, onClick = () => false }, ind) => (
              <Tooltip title={name} placement="top" key={ind}>
                <span>
                  {button ? (
                    <IconButton
                      data-test-id={'edit-node'}
                      edge="end"
                      aria-label={name}
                      onClick={e => {
                        e.stopPropagation()
                        onClick()
                      }}
                    >
                      {button}
                    </IconButton>
                  ) : (
                    link
                  )}
                </span>
              </Tooltip>
            ))}
          </div>
        )
      },
    },
  ]

  return (
    <Wrapper name="Kubernetes nodes">
      <Table<KubernetesNode>
        id="nodes-table"
        emptyMessageComponent={<MissingContent message="No kubernetes nodes available" />}
        config={tableConfig}
        data={kubernetesNodes}
        pending={loadingNodes}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
