import {
  ApplianceSettings,
  ApplianceType,
  GeoLocation,
  LogLevel,
  Region,
  RistserverLogLevel,
  UpdateAppliancePayload,
} from 'common/api/v1/types'
import { Autocomplete, Paper, Select } from '../../../../common/Form'
import { Api, GlobalState } from '../../../../../store'
import { numericEnum } from '../../../../../utils'
import { shallowEqual, useSelector } from 'react-redux'
import { FormikProps } from 'formik'
import ApplianceSpecificSettings from './ApplianceSpecificSettings'

type RegionWithId = Pick<Region, 'id' | 'name'>

interface IApplianceFormSettingsProps {
  region: RegionWithId | undefined
  secondaryRegion: RegionWithId | undefined
  geoLocation: GeoLocation | undefined
  type: ApplianceType
  formik: FormikProps<UpdateAppliancePayload & { id: string }>
  settings: ApplianceSettings | undefined
}

const { locationApi, regionApi } = Api

const Settings = ({ region, geoLocation, type, settings, secondaryRegion, formik }: IApplianceFormSettingsProps) => {
  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  return (
    <Paper title="Settings" collapsible>
      <Autocomplete<RegionWithId>
        name="region"
        formik={formik}
        api={regionApi.getRegions.bind(regionApi)}
        defaultOption={region}
        disabled={type === ApplianceType.core || type === ApplianceType.thumb}
        getOptionLabel={option => option.name}
        getOptionValue={option => option}
        optionComparator={(o1, o2) => o1.id == o2.id}
      />
      {type !== ApplianceType.core && type !== ApplianceType.thumb && (
        <Autocomplete<RegionWithId>
          name="secondaryRegion"
          formik={formik}
          api={regionApi.getRegions.bind(regionApi)}
          defaultOption={secondaryRegion}
          getOptionLabel={option => option.name}
          getOptionValue={option => option}
          optionComparator={(o1, o2) => o1.id == o2.id}
          label="Secondary region"
        />
      )}
      <Autocomplete<GeoLocation>
        name="geoLocation"
        label="City"
        api={locationApi.searchLocation.bind(locationApi)}
        formik={formik}
        defaultOption={geoLocation}
        getOptionLabel={option => option.location}
        getOptionValue={option => option}
        optionComparator={({ coordinates: c1 }, { coordinates: c2 }) => c1[0] == c2[0] && c1[1] == c2[1]}
        comment={
          type === ApplianceType.core
            ? 'When changing City on a Core node, all other Core nodes in the region are updated with the same city.'
            : ''
        }
      />
      <Select
        label="Log level"
        name="logLevel"
        options={Object.entries(numericEnum(LogLevel)).map(([name, value]) => ({ name, value }))}
      />
      {devMode && (
        <Select
          label="Log level for Ristserver (only available in Expert mode)"
          name="ristserverLogLevel"
          options={Object.entries(RistserverLogLevel).map(([name, value]) => ({ name, value }))}
        />
      )}

      <ApplianceSpecificSettings devMode={devMode} type={type} settings={settings} />
    </Paper>
  )
}

export default Settings
