import { Paper, Select } from '../../../../common/Form'
import { MatroxD4ApplianceSettings, MatroxD4FramerateFamily, MatroxD4GenlockSource } from 'common/api/v1/types'

interface Props {
  settings: MatroxD4ApplianceSettings
}

const sourceOptions = Object.values(MatroxD4GenlockSource)
const framerateFamilyOptions = Object.values(MatroxD4FramerateFamily)

const MatroxD4Settings = ({ settings }: Props) => {
  return (
    <Paper title="MatroxD4 Settings">
      <Paper title="Genlock">
        <Select label="Source" name={`settings.genlock.source`} required options={sourceOptions} />
        <Select
          label="Framerate Family"
          name={`settings.genlock.framerateFamily`}
          required
          disabled={settings.genlock.source !== MatroxD4GenlockSource.internal}
          options={framerateFamilyOptions}
        />
      </Paper>
    </Paper>
  )
}

export default MatroxD4Settings
