import { useState, useEffect, useRef } from 'react'

import HlsPlayer from '../../hlsplayer'
import { getLogger } from '../../logger'
const log = getLogger('video')

interface VideoProps {
  url: string
  poster?: string
  autoplay?: boolean
  initiallyMuted?: boolean
}

export const Video: React.FC<VideoProps> = ({ url, poster, autoplay, initiallyMuted }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [player, setPlayer] = useState<HlsPlayer>()

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return

    if (HlsPlayer.isSupported() && !player) {
      setPlayer(new HlsPlayer(videoEl))
    }

    player?.load(url).then(() => {
      log(`Video url ${url} loaded`)
      if (autoplay) {
        videoEl.play()
      }
    })

    return () => {
      player?.destroy()
    }
  }, [url, player, videoRef?.current])

  return <video width="100%" ref={videoRef} poster={poster} controls autoPlay={autoplay} muted={initiallyMuted} />
}
