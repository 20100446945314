import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import { Form, Formik, FormikProps } from 'formik'
import { Grid } from '@mui/material'

import { ButtonsPane, Checkbox, Paper, SafeRouting, TextInput } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import { AppDispatch, GlobalState } from '../../store'
import { Network, Role } from 'common/api/v1/types'
import { formTransform, useConfirmationDialog, useUser } from '../../utils'
import routes from '../../utils/routes'
import {
  clearNetwork,
  createNetwork,
  getNetwork,
  removeNetwork,
  updateNetwork,
} from '../../redux/actions/networkActions'

const NetworkForm = ({
  values,
  setStatus,
  dirty,
  isSubmitting,
  setSubmitting,
}: FormikProps<Network | Omit<Network, 'id'>>) => {
  const history = useHistory()
  const user = useUser()
  const setConfirmation = useConfirmationDialog()
  const dispatch = useDispatch<AppDispatch>()
  const { formErrors, saving } = useSelector(({ networksReducer }: GlobalState) => networksReducer, shallowEqual)
  useEffect(() => {
    setStatus(
      Array.isArray(formErrors) ? formErrors.reduce((acc, item) => ({ ...acc, [item.name]: item.reason }), {}) : {},
    )
  }, [formErrors])
  useEffect(() => {
    if (saving === false) setSubmitting(false)
  }, [saving])

  const deleteNetwork = () =>
    'id' in values &&
    setConfirmation(
      () => void dispatch(removeNetwork(values.id)),
      `Are you sure you want to delete network ${values.name}?`,
    )

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting} />
        <Form id="network-form" translate="no" noValidate>
          <Paper
            title="Network"
            tooltip={
              <>
                <p>Interfaces within the same network will be connected using their private IP addresses.</p>
                <p>To assign networks to interfaces navigate to an appliance or a particular interface.</p>
                <p>Networks can be temporarily disabled to switch over to the behavior defined by address mappings.</p>
              </>
            }
          >
            <TextInput name="name" label="Name" required autoFocus />
            <Checkbox name="enabled" label="Enable" />
          </Paper>
          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.networks())
                },
              },
              Save: { savingState: !!saving, primary: true, type: 'submit' },
            }}
            secondary={
              'id' in values && user.role === Role.super
                ? {
                    'Remove network': { onClick: deleteNetwork },
                  }
                : undefined
            }
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export default function EditNetwork({ match }: RouteComponentProps<{ id?: string }>) {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    match.params.id && dispatch(getNetwork(match.params.id))
    return () => {
      dispatch(clearNetwork())
    }
  }, [])

  const networkToEdit = useSelector(({ networksReducer }: GlobalState) => networksReducer.network, shallowEqual)
  const showConfirmation = useConfirmationDialog()
  const onSubmit = (network: Network | Omit<Network, 'id'>) => {
    if (networkToEdit) {
      if (network.enabled === networkToEdit.enabled) return dispatch(updateNetwork(network as Network))
      showConfirmation(
        () => void dispatch(updateNetwork(network as Network)),
        `${network.enabled ? 'Enabling' : 'Disabling'} the network may cause disruptions in active routing services.
          Are you sure you want to proceed?`,
      )
    } else dispatch(createNetwork(network))
  }
  const user = useUser()
  if (!user || user.role !== Role.super) return null

  return (
    <Wrapper name="Networks" entityName={networkToEdit ? networkToEdit.name : 'New'}>
      <Grid container spacing={0}>
        <Pendable pending={!!match.params.id && !networkToEdit}>
          <Formik
            onSubmit={values => {
              onSubmit(formTransform(values))
            }}
            initialValues={(networkToEdit as Network) || ({ name: '', enabled: true } as Omit<Network, 'id'>)}
            component={NetworkForm}
          />
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
