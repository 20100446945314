import {
  Appliance,
  ApplianceConfiguration,
  ApplianceFilter,
  GeoAppliance,
  ListResult,
  Group,
  UpdateAppliancePayload,
  GeoApplianceFilter,
} from 'common/api/v1/types'
import { getApplianceOwnerId } from '../../utils'
import {
  AppliancesRequestParams,
  EnrichedAppliance,
  EnrichedApplianceWithOwner,
  PaginatedRequestParams,
  singleSortQueryFromPaginatedRequestParams,
} from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IApplianceApi {
  getAppliance(id: Appliance['id']): Promise<EnrichedAppliance>
  getAppliances(params: AppliancesRequestParams): Promise<ListResult<EnrichedApplianceWithOwner>>
  getBareAppliances(filter: ApplianceFilter): Promise<ListResult<Appliance>>
  getConfig(id: Appliance['id']): Promise<ApplianceConfiguration>
  getGeoAppliances(params: AppliancesRequestParams): Promise<ListResult<GeoAppliance>>
  removeAppliance(id: string): Promise<Pick<Appliance, 'id'>>
  restart(id: Appliance['id']): Promise<Appliance>
  recreateTunnels(id: Appliance['id']): Promise<Appliance>
  updateAppliance(id: string, values: UpdateAppliancePayload): Promise<Appliance>
}

export class AppliancesApi implements IApplianceApi {
  constructor(private readonly edgeClient: EdgeClient) {}
  recreateTunnels(id: string): Promise<Appliance> {
    return this.edgeClient.recreateApplianceTunnels(id)
  }

  removeAppliance(id: string): Promise<Pick<Appliance, 'id'>> {
    return this.edgeClient.deleteAppliance(id)
  }
  updateAppliance(id: Appliance['id'], values: UpdateAppliancePayload): Promise<Appliance> {
    return this.edgeClient.updateAppliance(id, values)
  }

  /**
   * Getting appliance populating it with full owner object and physical ports' owners as well
   * @param id - appliance Id
   */
  async getAppliance(id: string): Promise<EnrichedAppliance> {
    const appliance = await this.edgeClient.getAppliance(id)
    const applianceOwnerId = getApplianceOwnerId(appliance)
    const groupIds = Array.from(new Set([applianceOwnerId, ...appliance.physicalPorts.map(p => p.owner)]))
    const groups = (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items
    const enrichedAppliance = {
      ...appliance,
      owner: groups.find(({ id }) => id === applianceOwnerId) as Group,
      _physicalPorts: appliance.physicalPorts.map(port => ({
        ...port,
        _owner: groups.find(({ id }) => id === port.owner) as Group,
      })),
    }
    return enrichedAppliance
  }

  /**
   * Getting ListResult of appliances populating each item with owner group object
   * @param paginatedRequestParams
   */
  async getAppliances({
    owner,
    filter: searchName,
    types,
    ...params
  }: AppliancesRequestParams): Promise<ListResult<EnrichedApplianceWithOwner>> {
    const filter: ApplianceFilter = { group: owner, searchName, types }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const applianceList = await this.edgeClient.listAppliances(query)
    if (applianceList.items.length === 0) {
      return { ...applianceList, items: [] }
    }

    const groupIds = applianceList.items.reduce<Array<string>>((acc, a) => {
      const id = typeof a.owner === 'string' ? a.owner : a.owner.id
      if (acc.includes(id)) return acc
      return acc.concat(id)
    }, [])
    const groups = (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items
    const groupsMap = groups.reduce<{ [key: string]: Group }>((acc, item) => ({ ...acc, [item.id]: item }), {})

    return {
      ...applianceList,
      items: applianceList.items.map(a => ({
        ...a,
        _owner: groupsMap[typeof a.owner === 'string' ? a.owner : a.owner.id],
      })),
    }
  }

  /**
   * Returns appliances with coordination to show on map
   * @param searchName - term to search for
   * @param paginatedRequestParams
   */
  async getGeoAppliances({
    filter: searchName,
    ...paginatedRequestParams
  }: PaginatedRequestParams): Promise<ListResult<GeoAppliance>> {
    const filter: GeoApplianceFilter = { searchName }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams })
    return this.edgeClient.listGeoAppliances(query)
  }

  /**
   * Command to restart appliance
   * @param id
   */
  restart(id: string): Promise<Appliance> {
    return this.edgeClient.restartAppliance(id, { timeoutMs: 180_000 })
  }

  /**
   * Returns appliance configuration for super user
   * @param id
   */
  getConfig(id: string): Promise<ApplianceConfiguration> {
    return this.edgeClient.getApplianceConfig(id, '')
  }

  getBareAppliances(filter: ApplianceFilter): Promise<ListResult<Appliance>> {
    return this.edgeClient.listAppliances({ filter })
  }
}
