import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, FormikProps, useFormikContext } from 'formik'
import { format } from 'date-fns'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { ExpFeatures, IpPortMode, PhysicalPort, RawVideo, Region, VideoPreviewMode } from 'common/api/v1/types'

import { isEditableGroup, pluralizeWord, useInputsSelector, useUser } from '../../../utils'
import { AppDispatch, GlobalState } from '../../../store'
import {
  Autocomplete,
  ButtonsPane,
  Checkbox,
  FormikErrorFocus,
  GridItem,
  Paper,
  SafeRouting,
  Select,
  TextInput,
} from '../../common/Form'
import DataSet from '../../common/DataSet'
import { InputHealthIndicator } from '../../common/Indicator'
import { Link } from '../../common/Link'

import routes from '../../../utils/routes'
import EncoderSettings from './EncoderSettings/EncoderSettings'
import {
  CommonFields,
  generatorDefaults,
  ristDefaults,
  rtpDefaults,
  srtDefaults,
  udpDefaults,
  zixiDefaults,
} from './PortForm/IpPortForm'
import { EnrichedInputWithEnrichedPorts } from './index'
import { rtmpDefaults } from './PortForm/IpPortForm/RtmpForm'
import {
  collectInterfaceSectionEntries,
  collectPortsFromInterfaceSections,
  InterfaceSection,
  isAppliance,
  isApplianceOrRegionSelectable,
  isCoreNode,
  makeInterfaceSection,
} from '../../common/Interface/Base'
import { coreNodesList, getCoreNodesInput } from '../../common/Metadata'
import {
  DATE_FORMAT_LONG,
  getFormattedTransportStreamContent,
  notUndefined,
  tsInfoServiceName,
} from 'common/api/v1/helpers'
import { getSettings } from '../../../redux/actions/settingsActions'
import { isFeatureOn } from '../../../utils/features'
import { EnrichedApplianceWithOwner, EnrichedInputPort } from '../../../api/nm-types'
import { rerouteInput } from '../../../redux/actions/inputsActions'
import Pendable from '../../common/Pendable'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { portModesWithEncoderSettings } from 'common/encoderSettingsUtil'

const MAX_MAX_BITRATE_MBPS = 2147

export const initialPort = ({
  physicalPortId,
  port,
  enforcedMode,
  allocatedPortId,
}: {
  physicalPortId: string
  port?: PhysicalPort
  enforcedMode?: IpPortMode
  allocatedPortId?: string
}) => ({
  _port: port,
  [CommonFields.mode]: enforcedMode ?? '',
  [CommonFields.physicalPort]: physicalPortId,
  [CommonFields.allocatedPortId]: allocatedPortId,
  ...udpDefaults,
  ...rtpDefaults,
  ...srtDefaults,
  ...ristDefaults,
  ...zixiDefaults,
  ...rtmpDefaults,
  ...generatorDefaults,
})

const TsParsingMode = () => {
  return (
    <Select
      label="Broadcast standard for transport stream analysis"
      name="broadcastStandard"
      options={[
        { name: 'DVB', value: 'dvb' },
        { name: 'ATSC', value: 'atsc' },
        { name: 'No analysis', value: 'none' },
      ]}
      tooltip="Choose broadcast standard for mpeg-ts parsing or disable analysis by choosing 'No analysis'."
    />
  )
}

const VideoPreviewModeSelect = () => {
  return (
    <Select
      label="Preview"
      name="videoPreviewMode"
      options={Object.values(VideoPreviewMode)}
      tooltip="'on demand' begins generating input preview when requested and is a more efficient alternative to 'always on', which constantly generates input preview and has a faster startup-time. No preview will be available if the input contains multiple video streams."
    />
  )
}

const MPTSInputsSelect = ({ form }: { form: FormikProps<InputFormProps> }) => {
  const { inputs: mptsInputs, loading } = useInputsSelector({
    rowsPerPage: '100',
    pageNumber: '0',
    derived: false,
  })

  const mptsInputOptions = mptsInputs
    .filter(i => i.id != form.values.id)
    .filter(i => i.tsInfo?.some(tsInfo => (tsInfo.services?.length || 1) > 1))
    .map(input => ({
      name: input.name,
      value: input.id,
    }))

  const selectedParentInput = mptsInputs.find(input => input.id === form.values.deriveFrom?.parentInput)
  const savedServiceIds = form.values?.deriveFrom?.ingestTransform?.services
  const inputTsInfoServices = selectedParentInput?.tsInfo?.[0].services ?? []
  const inputTsInfoServicesIds = inputTsInfoServices.map(s => s.id).filter(notUndefined)
  const disappearedServiceIds = (savedServiceIds ?? []).filter(id => !inputTsInfoServicesIds.includes(id))
  const items = inputTsInfoServicesIds.concat(disappearedServiceIds)

  const savedParentInputId = form.values?.deriveFrom?.parentInput
  const parentNotMPTSAnymore = !mptsInputOptions.some(o => o.value === savedParentInputId)
  if (savedParentInputId && parentNotMPTSAnymore) {
    mptsInputOptions.push({
      name: selectedParentInput?.name || savedParentInputId,
      value: savedParentInputId,
    })
  }

  const disabled = mptsInputOptions.length === 0

  useEffect(() => {
    if (selectedParentInput) {
      form.setFieldValue('broadcastStandard', selectedParentInput.broadcastStandard)
    }
  }, [selectedParentInput?.id])

  return (
    <Pendable pending={loading}>
      <Select
        disabled={disabled}
        required
        label={'MPTS Input'}
        name="deriveFrom.parentInput"
        options={mptsInputOptions}
        tooltip="Select MPTS input"
      />
      <Autocomplete<number>
        key={selectedParentInput?.id}
        required={true}
        name="deriveFrom.ingestTransform.services"
        label="Services"
        formik={form}
        api={() =>
          Promise.resolve({
            items: items,
            total: items.length,
            filter: undefined,
          })
        }
        defaultOption={savedServiceIds ?? []}
        getOptionValue={option => option}
        getOptionLabel={option =>
          inputTsInfoServices.find(s => s.id === option)?.name ||
          (disappearedServiceIds.includes(option) ? `${option} (removed)` : `${option}`)
        }
        optionComparator={(o1, o2) => o1 == o2}
        getOptionDisabled={option => disappearedServiceIds.includes(option)}
        multiple
        xs={4}
      />
    </Pendable>
  )
}

const DerivedMPTSInput = ({ form }: { form: FormikProps<InputFormProps> }) => {
  const { setFieldValue } = useFormikContext()
  useEffect(() => {
    if (!form.values._derived && form.values.deriveFrom?.parentInput) {
      setFieldValue('deriveFrom', {
        parentInput: '',
        ingestTransform: { type: 'mpts-demux' },
      })
    }
  }, [form.values._derived, setFieldValue])
  return (
    <>
      <Checkbox name="_derived" label="Derive from MPTS input" />
      {!!form.values._derived && <MPTSInputsSelect form={form} />}
    </>
  )
}

export interface InputFormProps extends EnrichedInputWithEnrichedPorts {
  _derived: boolean
  _selectedApplianceOrRegionId?: string
  _selectedApplianceOrRegion?: EnrichedApplianceWithOwner | Region
}

const InputForm = (form: FormikProps<InputFormProps>) => {
  // 'setFieldValue' only triggers a re-render if the field's reference/pointer is changed/replaced (i.e. not on modifications to the existing reference)
  const { values, setStatus, setFieldValue, dirty, isSubmitting, setSubmitting, initialValues } = form
  const user = useUser()
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch<AppDispatch>()
  const { devMode, settings } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const isCopyingExistingInput = match.path === routes.inputsCopy.route
  const isEditingExistingInput = !!values.id && !isCopyingExistingInput

  const { formErrors } = useSelector(({ inputsReducer }: GlobalState) => inputsReducer, shallowEqual)
  useEffect(() => {
    setStatus(
      Array.isArray(formErrors) ? formErrors.reduce((acc, item) => ({ ...acc, [item.name]: item.reason }), {}) : {},
    )
  }, [formErrors])

  const isSaving = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.saving, shallowEqual)
  const isRerouting = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.rerouting, shallowEqual)
  useEffect(() => {
    if (isSaving === false) setSubmitting(false)
  }, [isSaving])

  useEffect(() => {
    dispatch(getSettings())
  }, [])
  const selectedInterfaces = collectPortsFromInterfaceSections(values)
  const coreNodes = getCoreNodesInput(values)

  const onRemoveInputAppliance = (key: string) => setFieldValue(key, undefined)
  const initialInterfaceSections = collectInterfaceSectionEntries(initialValues).map(([_k, value]) => value)
  const interfaceSectionEntries = collectInterfaceSectionEntries(values)
  const encoderFeatures = selectedInterfaces.find(port => portModesWithEncoderSettings.includes(port.mode))?._port
    ._appliance?.features.encoder
  const limitCodecs = encoderFeatures?.video.codecs.map(c => c.name).filter(name => name != RawVideo)

  const interfaceSections = interfaceSectionEntries.map(([key, data], index) => {
    const isModeSelectionDisabled = data.ports.length > 1
    return (
      <InterfaceSection<EnrichedInputWithEnrichedPorts>
        key={key}
        namePrefix={key}
        index={index}
        initialApplianceOrRegionId={
          initialInterfaceSections[index]?.region?.id ?? initialInterfaceSections[index]?.appliance?.id
        }
        isModeSelectionDisabled={isModeSelectionDisabled}
        title={`Input appliance #${index + 1}`}
        onRemove={interfaceSectionEntries.length > 1 ? onRemoveInputAppliance : undefined}
        inputId={isEditingExistingInput ? values.id : undefined}
        outputId={undefined}
        isInputForm={true}
        enforcedPortMode={undefined}
        isEditingExistingEntity={isEditingExistingInput}
        isCopyingExistingEntity={isCopyingExistingInput}
        isApplianceOrRegionSelectable={applianceOrRegion => isApplianceOrRegionSelectable(applianceOrRegion, values)}
        onApplianceOrRegionSelected={selected => {
          if (!selected) {
            const [_, emptySection] = makeInterfaceSection({ region: undefined, appliance: undefined })
            setFieldValue(key, emptySection)
          } else if (isAppliance(selected)) {
            const [_, applianceSection] = makeInterfaceSection({ region: undefined, appliance: selected })
            setFieldValue(key, applianceSection)
          } else {
            const [_, regionalSection] = makeInterfaceSection({ region: selected, appliance: undefined })
            setFieldValue(key, regionalSection)
          }
        }}
        {...form}
      />
    )
  })

  const transportStreamContent = getFormattedTransportStreamContent((values.tsInfo || [])[0])
  const contentFormat =
    transportStreamContent == 'MPTS'
      ? 'MPTS'
      : `${transportStreamContent} (${tsInfoServiceName((values.tsInfo || [])[0])})`

  const selectedInterfaceWithEncoderSettings = selectedInterfaces.find(({ mode }) =>
    portModesWithEncoderSettings.includes(mode),
  )
  const shouldShowEncoderSettings = !values._derived && selectedInterfaceWithEncoderSettings

  return (
    <Grid container data-test-input-id={`${values.id || ''}`}>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting} />
        <Form id="input-form" translate="no" noValidate>
          <Paper className="outlined" title="Meta data" collapsible>
            <Paper>
              <TextInput name="name" label="Input name" required autoFocus />

              <Checkbox name="adminStatus" label="Enabled" />
              {!isCoreNode(values) && !values._derived && (
                <Checkbox
                  name="_redundant"
                  label="Redundant"
                  tooltip="Redundancy enabled will route the input stream through an additional path in the input appliance's region(s)."
                  tooltipStyle={{ marginTop: 14, marginLeft: 0 }}
                />
              )}

              <Checkbox name="thumbnailMode" label="Generate thumbnails" />
              {!!values.thumbnailMode && (
                // <Grid item xs={12}>
                <VideoPreviewModeSelect />
                // </Grid>
              )}
              <TextInput
                noNegative
                name="maxBitrateMbps"
                label="Max bitrate (Mbps)"
                validators={{
                  number: {
                    lessThanOrEqualTo: MAX_MAX_BITRATE_MBPS,
                    greaterThan: 0,
                    message: `Must be greater than zero and no more than ${MAX_MAX_BITRATE_MBPS}`,
                  },
                }}
                type="number"
                tooltip="Maximum bitrate allowed including retransmission. Packets exceeding the maximum bitrate will be dropped."
              />
              {devMode && <TextInput name="bufferSize" label="Buffer duration (ms)" required type="number" />}
              <TsParsingMode />
              {settings && isFeatureOn(ExpFeatures.ExtMptsDerivedInput, settings) && <DerivedMPTSInput form={form} />}
            </Paper>
            {isEditingExistingInput && (
              <Paper>
                <GridItem lg={12} xl={12}>
                  <DataSet
                    values={{
                      Id: values.id,
                      Created: format(new Date(values.createdAt), DATE_FORMAT_LONG),
                      Updated: format(new Date(values.updatedAt), DATE_FORMAT_LONG),
                      Access: values.canSubscribe ? 'Full Access' : 'Preview',
                      Owner: !!values._owner?.id && (
                        <Link
                          to={routes.groupsUpdate({ id: values._owner.id })}
                          underline="hover"
                          available={isEditableGroup(values._owner.id, user)}
                        >
                          {values._owner?.name}
                        </Link>
                      ),
                      Status: <InputHealthIndicator inputId={values.id} inline />,
                      Format: contentFormat,
                      [`Core ${pluralizeWord(coreNodes.length, 'node')}`]: coreNodesList(coreNodes, user),
                    }}
                  />
                </GridItem>
              </Paper>
            )}
          </Paper>
          {values._derived ? null : interfaceSections}
          {/* Only allow adding a second input appliance if the first one is a core node */}
          {interfaceSectionEntries.length < 2 && isCoreNode(values) && (
            <Button
              sx={{ marginBottom: 2 }}
              variant="contained"
              color="secondary"
              onClick={() => {
                const [key, value] = makeInterfaceSection({ region: undefined, appliance: undefined })
                setFieldValue(key, value)
              }}
            >
              Add input appliance
            </Button>
          )}

          {shouldShowEncoderSettings && (
            <EncoderSettings
              namePrefix="encoderSettings"
              mode={selectedInterfaceWithEncoderSettings.mode}
              selectedInterfaces={selectedInterfaces as EnrichedInputPort[]}
              encoderSettings={values.encoderSettings}
              encoderFeatures={encoderFeatures}
              limitCodecs={limitCodecs}
              setFieldValue={setFieldValue}
            />
          )}
          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.inputs())
                },
              },
              Save: { savingState: isSaving, primary: true, type: 'submit' },
            }}
            secondary={
              isEditingExistingInput
                ? {
                    'Use as template': {
                      onClick: () => history.push(routes.inputsCopy({ id: values.id })),
                    },
                    ...(devMode
                      ? {
                          Reroute: {
                            onClick: () => void dispatch(rerouteInput(values.id)),
                            savingState: isRerouting,
                          },
                        }
                      : {}),
                  }
                : undefined
            }
          />
          <FormikErrorFocus />
        </Form>
      </Grid>
    </Grid>
  )
}

export default InputForm
