import { VideonPortMode } from 'common/api/v1/types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { MenuItem, FormControl, InputLabel, Select as MUISelect } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useFormikContext } from 'formik'

import { GridItem, Paper } from '../../../common/Form'
import { interfaceSectionPaper } from '../../../common/Interface/Base'

interface VideonPortFormProps {
  namePrefix: string
  modes?: VideonPortMode[]
  limitToMode?: VideonPortMode
}

const options: { [key in VideonPortMode]: string } = {
  videonSdi: 'sdi',
  videonHdmi: 'hdmi',
  videonAuto: 'auto',
}

const richOptions = Object.keys(options).map(value => ({
  value,
  name: options[value as keyof typeof options],
}))

// const VideonPortForm = ({ namePrefix, modes }: VideonPortFormProps) => (
//   <>
//     <Select
//       label="Mode"
//       name={`${namePrefix}.mode`}
//       required
//       newLine
//       options={richOptions}
//       validators={{
//         oneOf: { validValues: new Set(Object.values(VideonPortMode)) },
//       }}
//     />
//   </>
// )

const styles = {
  warningText: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    color: (theme: Theme) => theme.palette.warning.light,
  },
}

const VideonPortForm = ({ namePrefix, limitToMode }: VideonPortFormProps) => {
  const { setFieldValue, getFieldProps } = useFormikContext()
  const options = richOptions.map(o => ({
    ...o,
    disabled: Boolean(limitToMode && limitToMode != o.value),
  }))
  const name = `${namePrefix}.mode`
  const value = (getFieldProps(name) && getFieldProps(name).value) || ''
  return (
    <Paper sx={interfaceSectionPaper}>
      <GridItem newLine>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel>Mode</InputLabel>
          <MUISelect
            name={name}
            label={'Mode'}
            disabled={false}
            fullWidth
            value={value}
            onChange={event => {
              setFieldValue(name, event.target.value)
            }}
          >
            {options.map(mode => (
              <MenuItem key={mode.value} value={mode.value} disabled={mode.disabled}>
                <div>
                  <Typography>
                    {mode.name}
                    {mode.disabled && (
                      <Box sx={styles.warningText}>
                        (All active inputs on a videon appliance must have the same mode)
                      </Box>
                    )}
                  </Typography>
                </div>
              </MenuItem>
            ))}
          </MUISelect>
        </FormControl>
      </GridItem>
    </Paper>
  )
}

export default VideonPortForm
